import React from "react";
import { Form, Input, DatePicker, Checkbox, InputNumber, Select } from 'antd';

const { TextArea } = Input;

const widthStyle = (value) => {
  return {
    width: '100%',
    maxWidth: value + 'px'
  }
}

const renderFormLabel = (name) => {
  return <div className="form-label">{name}</div>;
}

const renderInputField = (label, name, placeholder = "", required = false) => {
  return (
    <>
      {renderFormLabel(label)}
      <Form.Item
        name={name}
        rules={[{ required: required, message: `${label} is required!` }]}
      >
        <Input placeholder={placeholder} size="large"/>
      </Form.Item>
    </>
  )
}

const renderTextAreaField = (label, name, rows = 3, required = false) => {
  return (
    <>
      {renderFormLabel(label)}
      <Form.Item
        name={name}
        rules={[{ required: required, message: `${label} is required!` }]}
      >
        <TextArea rows={rows} size="large"/>
      </Form.Item>
    </>
  )
}

const renderDateField = (label, name) => {
  return (
    <>
      {renderFormLabel(label)}
      <Form.Item
        name={name}
        rules={[{ required: true, message: `${label} is required!` }]}
      >
        <DatePicker size="large" format="MM/DD/YYYY"/>
      </Form.Item>
    </>
  )
}

const renderCheckboxField = (label, name) => {
  return (
    <>
      <Form.Item
        name={name}
        valuePropName="checked"
      >
        <Checkbox value={true}>{label}</Checkbox>
      </Form.Item>
    </>
  )
}

const renderCurrencyField = (label, name) => {
  return (
    <>
      {renderFormLabel(label)}
      <Form.Item
        name={name}
        rules={[{ required: true, message: `${label} is required!` }]}
      >
        <InputNumber 
          size="large"
          style={widthStyle(200)}
          precision={2}
          formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={value => value.replace(/\$\s?|(,*)/g, '')}
          />
      </Form.Item>
    </>
  )
}

const renderEmailField = (label, name) => {
  return (
    <>
      {renderFormLabel(label)}
      <Form.Item
        name={name}
        rules={[
          { required: true, message: `${label} is required!` },
          { type: "email", message: `Please enter a valid email address` }
        ]}
      >
        <Input size="large"/>
      </Form.Item>
    </>
  )
}

const renderPercentField = (label, name) => {
  return (
    <>
      {renderFormLabel(label)}
      <Form.Item
        name={name}
        rules={[{ required: true, message: `${label} is required!` }]}
      >
        <InputNumber 
          size="large"
          style={widthStyle(200)}
          precision={3}
          />
      </Form.Item>
    </>
  )
}

const renderSelectOptions = (options) => {
  if (options) {
    return options.map((option) => (
      <Select.Option value={option.value} key={option.value}>{option.text}</Select.Option>
    ))
  }
  return null;
}

const renderSelectField = (label, name, placeholder, options, required = false) => {
  return (
    <>
      {renderFormLabel(label)}
      <Form.Item 
        name={name}
        rules={[{ required: true, message: `${label} is required!` }]}
      >
        <Select
          placeholder={placeholder}
          allowClear
        >
          {renderSelectOptions(options)}
        </Select>
      </Form.Item>
    </>
  )
}

const renderSearchSelectField = (label, name, placeholder, options, required = false) => {
  return (
    <>
      {renderFormLabel(label)}
      <Form.Item 
        name={name}
        rules={[{ required: true, message: `${label} is required!` }]}
      >
        <Select
          showSearch
          placeholder={placeholder}
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {renderSelectOptions(options)}
        </Select>
      </Form.Item>
    </>
  )
}

const renderTagSelectField = (label, name, placeholder, options, required = false) => {
  return (
    <>
      {renderFormLabel(label)}
      <Form.Item 
        name={name}
        rules={[{ required: required, message: `${label} is required!` }]}
      >
        <Select
          mode="tags"
          placeholder={placeholder}
          allowClear
        >
          {renderSelectOptions(options)}
        </Select>
      </Form.Item>
    </>
  )
}

export { 
  renderInputField,
  renderTextAreaField,
  renderDateField,
  renderCheckboxField,
  renderCurrencyField,
  renderEmailField,
  renderFormLabel,
  renderPercentField,
  renderSelectField,
  renderSearchSelectField,
  renderTagSelectField
};