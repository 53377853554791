import React, {useState, useEffect} from "react";
import { useNavigate, Link } from "react-router-dom";
import moment from "moment";
import { Row, Col, Table, Tag, Progress, Checkbox, Input, InputNumber, DatePicker} from 'antd';
import NumberFormat from 'react-number-format';
import SectionContainer from '../../../components/sectionContainer'
import FloatingContainer from '../../../components/floatingContainer'
import { ArrowLeftOutlined } from '@ant-design/icons';

const BudgetPage = () => {

  const [grossIncome, setGrossIncome] = useState(0);
  const [retirementMax, setRetirementMax] = useState(19000);
  const [currentInvestments, setCurrentInvestments] = useState(0);
  const [investmentPercentage, setInvestmentPercentage] = useState(15);
  const [payPeriods, setPayPeriods] = useState(24);
  const [birthDate, setBirthDate] = useState(moment('1987-06-20'))

  useEffect(() => {
    window.scrollTo(0, 0)
    document.title = "Craig Smith | Investment Breakdown";
  }, [])

  const renderCurrency = (number) => {
    return <NumberFormat value={number} displayType={'text'} thousandSeparator={true} prefix={'$ '} decimalScale={2} fixedDecimalScale={true}/>;
  }

  const renderCurrencyTextbox = (number, onChange) => {
    return (
      <InputNumber 
          style={{ width: '200px'}}
          size="large"
          value={number}
          onChange={(value) => onChange(value)}
          precision={2}
          formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={value => value.replace(/\$\s?|(,*)/g, '')}
          />
    )
  }

  const getInvestmentAmount = () => {
    return grossIncome * (investmentPercentage / 100);
  }

  const getContributionPercentage = () => {
    if (grossIncome == 0 || grossIncome == null) {
      return 0;
    }
    const invAmount = grossIncome * (investmentPercentage / 100);
    const retirementAmount = invAmount < retirementMax ? invAmount : retirementMax;

    return ((retirementAmount / grossIncome) * 100).toFixed(1);
  }

  const getExtraInvementsPercentage = () => {
    if (grossIncome == 0 || grossIncome == null) {
      return 0;
    }
    return (investmentPercentage - getContributionPercentage()).toFixed(1);
  }

  const getContributionAmount = () => {
    return grossIncome * (getContributionPercentage() / 100);
  }

  const getExtraInvestmentAmount = () => {
    return getInvestmentAmount() - getContributionAmount();
  }

  const getInvestmentByAge = (age, percentage) => {
    const ageDate = moment(birthDate).add(age, 'years');
    const monthsFromNow = moment(ageDate).diff(moment(), 'months');

    var balance = currentInvestments;
    for (var i = 1; i < monthsFromNow; i++) {
      const balanceWithContribution = balance + (getInvestmentAmount() / 12)
      balance = balanceWithContribution + (balanceWithContribution * (percentage/12))
    }

    return balance
  }

  return (
    <div className="mortgage-calculator-page">
      <SectionContainer className="bg-blue-gradient" verticalPadding={40}>
        <div className="page-header--title">Investment Breakdown</div>
        <div className="page-header--breadcrumb"><ArrowLeftOutlined/> <Link to="/admin/dashboard">Dashboad</Link></div>
      </SectionContainer>
      {/* <SectionContainer className="bg-blue-gradient" verticalPadding={40}>
        <div className="page-header">INVESTMENT BREAKDOWN</div>
        <div className="page-breadcrumb"><Link to="/admin/dashboard">Dashboard</Link> {'> Invesment Breakdown'}</div>
      </SectionContainer> */}

      <SectionContainer className="" verticalPadding={60}>
        <Row gutter={[30,30]}>
          <Col xs={24} md={12}>
            <div className="shadow-box p-30">
              <Row gutter={[20,20]}>
                <Col xs={24}>
                  <div className="form-label">Birthday</div>
                  <DatePicker size="large" format="MM/DD/YYYY" onChange={date => setBirthDate(date)} defaultValue={birthDate}/>
                </Col>
                <Col xs={24} sm={12}>
                  <div className="form-label">Gross Income</div>
                  {renderCurrencyTextbox(grossIncome, setGrossIncome)}
                </Col>
                <Col xs={24} sm={12}>
                  <div className="form-label">Pay Periods</div>
                  <InputNumber 
                    size="large"
                    style={{ width: '200px'}}
                    precision={0}
                    value={payPeriods}
                    onChange={(value) => setPayPeriods(value)}
                    />
                </Col>
                <Col xs={24} sm={12}>
                  <div className="form-label">Investment Percentage</div>
                  <InputNumber 
                    size="large"
                    style={{ width: '200px'}}
                    precision={1}
                    value={investmentPercentage}
                    onChange={(value) => setInvestmentPercentage(value)}
                    />
                </Col>
                <Col xs={24} sm={12}>
                  <div className="form-label">401k Max</div>
                  {renderCurrencyTextbox(retirementMax, setRetirementMax)}
                </Col>
                <Col xs={24} sm={12}>
                  <div className="form-label">Current Investments</div>
                  {renderCurrencyTextbox(currentInvestments, setCurrentInvestments)}
                </Col>
              </Row>
            </div>
          </Col>
          <Col xs={24} md={12}>
            <div className="p-30 text-center shadow-box">
              <div className="fs-16 c-text-gray">TOTAL INVESTMENT</div>
              <div className="result-amount">
                {renderCurrency(getInvestmentAmount()) }
              </div>
              <div className="fs-16 c-text-gray mt-30">401K INVESTMENT</div>
              <div className="result-amount">
                { renderCurrency(getContributionAmount()) }
              </div>
              <div className="fs-16 c-text-gray mt-30">EXTRA INVESTMENT</div>
              <div className="result-amount">
                { renderCurrency(getExtraInvestmentAmount()) }
              </div>
            </div>
          </Col>
        </Row>

        <Row gutter={[30,30]}>
          <Col xs={24} md={12}>
            <div className="calculator-section-header mb-20 mt-60 text-center">401k Contribution</div>
            <div className="shadow-box p-30 text-center">
              <Row gutter={[30,30]}>
                <Col xs={24} md={12}>
                  <div className="c-text-gray uppercase">Percentage</div>
                  <div className="fs-32 fw-700">{ getContributionPercentage() } %</div>
                </Col>
                <Col xs={24} md={12}>
                  <div className="c-text-gray uppercase">Year</div>
                  <div className="fs-32 fw-700">{ renderCurrency(getContributionAmount()) }</div>
                </Col>
                <Col xs={24} md={12}>
                  <div className="c-text-gray uppercase">Month</div>
                  <div className="fs-32 fw-700">{ renderCurrency(getContributionAmount() / 12) }</div>
                </Col>
                <Col xs={24} md={12}>
                  <div className="c-text-gray uppercase">Paycheck</div>
                  <div className="fs-32 fw-700">{ renderCurrency(getContributionAmount() / payPeriods) }</div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col xs={24} md={12}>
            <div className="calculator-section-header mb-20 mt-60 text-center">Extra Investment</div>
            <div className="shadow-box p-30 text-center">
              <Row gutter={[30,30]}>
                <Col xs={24} md={12}>
                  <div className="c-text-gray uppercase">Percentage</div>
                  <div className="fs-32 fw-700">{ getExtraInvementsPercentage() } %</div>
                </Col>
                <Col xs={24} md={12}>
                  <div className="c-text-gray uppercase">Year</div>
                  <div className="fs-32 fw-700">{ renderCurrency(getExtraInvestmentAmount()) }</div>
                </Col>
                <Col xs={24} md={12}>
                  <div className="c-text-gray uppercase">Month</div>
                  <div className="fs-32 fw-700">{ renderCurrency(getExtraInvestmentAmount() / 12) }</div>
                </Col>
                <Col xs={24} md={12}>
                  <div className="c-text-gray uppercase">Paycheck</div>
                  <div className="fs-32 fw-700">{ renderCurrency(getExtraInvestmentAmount() / payPeriods) }</div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>

        <Row gutter={[30,30]}>
          <Col xs={24} md={12}>
            <div className="calculator-section-header mb-20 mt-60 text-center">Investments at 59</div>
            <div className="shadow-box p-30 text-center">
              <Row gutter={[30,30]}>
                <Col xs={24} md={24}>
                  <div className="c-text-gray uppercase">8% Return</div>
                  <div className="fs-32 fw-700">{ renderCurrency(getInvestmentByAge(59,0.08)) }</div>
                </Col>
                <Col xs={24} md={24}>
                  <div className="c-text-gray uppercase">10% Return</div>
                  <div className="fs-32 fw-700">{ renderCurrency(getInvestmentByAge(59,0.10)) }</div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col xs={24} md={12}>
            <div className="calculator-section-header mb-20 mt-60 text-center">Investments at 65</div>
            <div className="shadow-box p-30 text-center">
              <Row gutter={[30,30]}>
                <Col xs={24} md={24}>
                  <div className="c-text-gray uppercase">8% Return</div>
                  <div className="fs-32 fw-700">{ renderCurrency(getInvestmentByAge(65,0.08)) }</div>
                </Col>
                <Col xs={24} md={24}>
                  <div className="c-text-gray uppercase">10% Return</div>
                  <div className="fs-32 fw-700">{ renderCurrency(getInvestmentByAge(65,0.10)) }</div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </SectionContainer>
    </div>
  );
}

export default BudgetPage;
