import { isUndefined } from 'lodash';
import { get, post, put, del, postImage } from "./api";

// Auth
export const login = (body) => post('/auth/login', body);
export const logout = (body) => post('/auth/logout', body);
export const signup = (body) => post('/auth/signup', body);
export const verifyUser = (email) => get(`/auth/verify/user?email=${email}`);
export const verifyEmail = (body) => post('/auth/verify/email', body);
export const verifyEmailConfirm = (body) => post('/auth/verify/email/confirm', body);
export const resentVerificationEmail = (body) => post('/auth/verify/email/resend', body);
export const setPassword = (body) => post('/auth/set/password', body);

// Faith
export const getBibleBooks = () => get('/faith/bible-books');
export const getVerses = () => get('/faith/verses');
export const getVerse = (verse_id) => get(`/faith/verses/${verse_id}`);
export const saveVerse = (body) => post(`/faith/verses`, body);
export const updateVerse = (verse_id, body) => put(`/faith/verses/${verse_id}`, body);
export const deleteVerse = (verse_id) => del(`/faith/verses/${verse_id}`);

// Travel
export const createTrip = (body) => post('/travel/trips', body);
export const updateTrip = (trip_id, body) => put(`/travel/trips/${trip_id}`, body);
export const deleteTrip = (trip_id) => del(`/travel/trips/${trip_id}`);
export const getTrip = (trip_key) => get(`/travel/trips/${trip_key}`);
export const getTrips = () => get(`/travel/trips`);
export const uploadTripPhoto = (body) => post('/travel/trips/photo', body);

// Blog
export const createPost = (body) => post('/blog/posts', body);
export const updatePost = (post_id, body) => put(`/blog/posts/${post_id}`, body);
export const deletePost = (post_id) => del(`/blog/posts/${post_id}`);
export const getPost = (post_key) => get(`/blog/posts/${post_key}`);
export const getPosts = (category) => get(`/blog/posts${isUndefined(category) ? "" : `?category=${category}`}`);
export const uploadPostPhoto = (post_id, body) => post(`/blog/posts/photo?post_id=${post_id}`, body);
export const getPostCategories = () => get(`/blog/categories`);