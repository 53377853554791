import React, { useEffect, useState } from "react";
import moment from 'moment';
import NumberFormat from 'react-number-format';
import { Row, Col,InputNumber, DatePicker, Table } from 'antd';
import SectionContainer from '../../../components/sectionContainer'
import FloatingContainer from '../../../components/floatingContainer'
import PageHeader from '../../../components/pageHeader'
import FadeDownWhileInView from '../../../components/fadeDownWhileInView'

const MortgageCalculatorPage = () => {

  const [purchasePrice, setPurchasePrice] = useState(0)
  const [downPayment, setDownPayment] = useState(0)
  const [extraPayment, setExtraPayment] = useState(0)
  const [monthlyPayment, setMonthlyPayment] = useState(0)
  const [loanAmount, setLoanAmount] = useState(0)
  const [payoffYears, setPayoffYears] = useState(0)
  const [interestRate, setInterestRate] = useState(3.00)
  const [startDate, setStartDate] = useState(moment())
  const [term, setTerm] = useState(30)
  const [tableData, setTableData] = useState([])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const widthStyle = (value) => {
    return {
      width: '100%',
      maxWidth: value + 'px'
    }
  }

  const calculate = () => {

    const loan = purchasePrice - downPayment;
    const numberOfPayments = term * 12;
    const monthlyInterest = (interestRate / 100) / 12;
    const payment = (loan * monthlyInterest) / (1 - Math.pow(1 + monthlyInterest, -numberOfPayments))

    var data = [];
    var runningBalance = loan;
    var yearsToPayoff = 0;

    for (var i = 1; i < numberOfPayments + 1; i++) {

      const givenMonth = moment().add(i, 'months');

      const interestForMonth = runningBalance * monthlyInterest;
      const principalForMonth = payment - interestForMonth;
      // const principlePlusExtraPayment = principalForMonth + extraPayment
      const endingBalance = runningBalance - principalForMonth - extraPayment;
      runningBalance = endingBalance;

      const dataObject = {
        key: i,
        date: givenMonth.format("MMM YYYY"),
        paymentNumber: i,
        interest: interestForMonth,
        principal: principalForMonth,
        extraPayment: extraPayment,
        loanBalance: endingBalance > 0 ? endingBalance : 0
      }
      data.push(dataObject)

      if (runningBalance < 0 || i === numberOfPayments) {
        yearsToPayoff = i / 12;
        break;
      }
    }

    setMonthlyPayment(payment)
    setLoanAmount(loan)
    setPayoffYears(yearsToPayoff)
    setTableData(data)

    // window.scrollTo(0, 0)
  }

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      fixed: 'left'
    },
    {
      title: 'Payment #',
      dataIndex: 'paymentNumber',
      key: 'paymentNumber',
    },
    {
      title: 'Interest',
      dataIndex: 'interest',
      key: 'interest',
      render: (number) => <NumberFormat value={number} displayType={'text'} thousandSeparator={true} prefix={'$ '} decimalScale={2} fixedDecimalScale={true}/>
    },
    {
      title: 'Principal',
      dataIndex: 'principal',
      key: 'principal',
      render: (number) => <NumberFormat value={number} displayType={'text'} thousandSeparator={true} prefix={'$ '} decimalScale={2} fixedDecimalScale={true}/>
    },
    {
      title: 'Extra Payment',
      dataIndex: 'extraPayment',
      key: 'extraPayment',
      render: (number) => <NumberFormat value={number} displayType={'text'} thousandSeparator={true} prefix={'$ '} decimalScale={2} fixedDecimalScale={true}/>
    },
    {
      title: 'Balance',
      dataIndex: 'loanBalance',
      key: 'loanBalance',
      render: (number) => <NumberFormat value={number} displayType={'text'} thousandSeparator={true} prefix={'$ '} decimalScale={2} fixedDecimalScale={true}/>
    }
  ];

  return (
    <div className="mortgage-calculator-page">
      <PageHeader title="Mortgage Calculator" bottomOffset={90} backTitle="Finances" backPath="/finances"/>

      <SectionContainer className="bg-white" verticalPadding={0}>
        <div className="page-header--box">
          <FloatingContainer maxWidth={850}>
            <FadeDownWhileInView delay={0.5} className="page-header--box-title">Instructions</FadeDownWhileInView>
            <FadeDownWhileInView delay={1} className="page-header--box-text">Enter your mortgage information below to find out what your loan amount and monthly payment will be. If you include an extra principal payment, you can also find out how many years it will take you to pay off your house.</FadeDownWhileInView>
          </FloatingContainer>
        </div>
      </SectionContainer>

      <SectionContainer className="bg-white" verticalPadding={60}>
        <Row gutter={[30,30]}>
          <Col xs={24} md={12}>
            <div className="calculator-section-header mb-20">Mortgage Information</div>
            <div className="shadow-box p-30">
             
              <Row gutter={[20,20]}>
                <Col xs={24}>
                  <div className="form-label">Starting Date</div>
                  <DatePicker size="large" format="MM/DD/YYYY" onChange={date => setStartDate(date)} defaultValue={startDate}/>
                </Col>
                <Col xs={24} sm={12}>
                  <div className="form-label">Purchase Price</div>
                  <InputNumber 
                    size="large"
                    defaultValue={purchasePrice}
                    style={widthStyle(200)}
                    precision={2}
                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                    onChange={number => setPurchasePrice(number)}
                  />
                </Col>
                <Col xs={24} sm={12}>
                  <div className="form-label">Down Payment</div>
                  <InputNumber 
                    size="large"
                    defaultValue={downPayment}
                    style={widthStyle(200)}
                    precision={2}
                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                    onChange={number => setDownPayment(number)}
                  />
                </Col>
                <Col xs={24} sm={12}>
                  <div className="form-label">Iterest Rate</div>
                  <InputNumber
                    size="large"
                    defaultValue={interestRate}
                    style={widthStyle(150)}
                    precision={2}
                    min={0}
                    max={100}
                    formatter={value => `${value}%`}
                    parser={value => value.replace('%', '')}
                    onChange={number => setInterestRate(number)}
                  />
                </Col>
                <Col xs={24} sm={12}>
                  <div className="form-label">Term (years)</div>
                  <InputNumber
                    size="large"
                    defaultValue={term}
                    style={widthStyle(150)}
                    min={0}
                    max={40}
                    onChange={number => setTerm(number)}
                  />
                </Col>
                <Col xs={24} sm={12}>
                  <div className="form-label">Extra Payment</div>
                  <InputNumber 
                    size="large"
                    defaultValue={extraPayment}
                    style={widthStyle(200)}
                    precision={2}
                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                    onChange={number => setExtraPayment(number)}
                  />
                </Col>
                <Col xs={24}>
                  <button className="page-button mt-20" onClick={calculate}>Calculate</button>
                </Col>
              </Row>
            </div>
          </Col>
          <Col xs={24} md={12}>
            <div className="calculator-section-header mb-20">Results</div>
            <div className="p-30 text-center bg-gray">
              <div className="fs-16 c-text-gray">LOAN AMOUNT</div>
              <div className="result-amount">
                <NumberFormat value={loanAmount} displayType={'text'} thousandSeparator={true} prefix={'$ '} decimalScale={2} fixedDecimalScale={true}/>
              </div>
              <div className="fs-16 c-text-gray mt-30">MONTHLY PAYMENT</div>
              <div className="result-amount">
                <NumberFormat value={monthlyPayment + extraPayment} displayType={'text'} thousandSeparator={true} prefix={'$ '} decimalScale={2} fixedDecimalScale={true}/>
              </div>
              <div className="fs-16 c-text-gray mt-30">PAYOFF YEARS</div>
              <div className="result-amount">
                <NumberFormat value={payoffYears} displayType={'text'} thousandSeparator={true} decimalScale={1} fixedDecimalScale={true}/>
              </div>
            </div>
          </Col>
        </Row>
        <div className="calculator-section-header mb-20 mt-60">Amortization Schedule</div>
        <div className="shadow-box mb-30">
          <Table dataSource={tableData} columns={columns} pagination={false} scroll={{ x: 'max-content' }}/>
        </div>
      </SectionContainer>
    </div>
  );
}

export default MortgageCalculatorPage;
