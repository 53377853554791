import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';

const LoadingSpinner = () => {
  return (
    <div className="loading-spinner">
      <LoadingOutlined />
    </div>
  )
}

export default LoadingSpinner;
