import React, {useState, useEffect} from "react";
import { useNavigate, Link } from "react-router-dom";
import { Row, Col} from 'antd';
import { CarOutlined, ReadOutlined, AuditOutlined, LineChartOutlined, DollarOutlined } from '@ant-design/icons';
import SectionContainer from '../../components/sectionContainer'
import FloatingContainer from '../../components/floatingContainer'
import ButtonShadowBox from '../../components/buttonShadowBox'

const DashboardPage = () => {

  let navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const renderDashboardButton = (icon, title, text, path) => {
    return (
      <Col xs={24} sm={12} md={12} lg={8}>
        <ButtonShadowBox icon={icon} title={title} text={text} onClick={() => navigate(path)} minHeight={250} />
      </Col>
    )
  }

  return (
    <div>
      <SectionContainer className="bg-blue-gradient" verticalPadding={60}>
        <div className="page-header--title">Dashboard</div>
      </SectionContainer>
      <SectionContainer className="bg-gray" verticalPadding={60}>
        <Row gutter={[30,30]}>
          { renderDashboardButton(<ReadOutlined/>, "Verses", "Manage items on the Memory Verse page.", "/admin/faith/verses") }
          { renderDashboardButton(<CarOutlined/>, "Trips", "Manage trips on the Travel page.", "/admin/travel/trips") }
          { renderDashboardButton(<AuditOutlined/>, "Bookkeeping", "Manage books.", "/admin/finances/bookkeeping") }
          { renderDashboardButton(<DollarOutlined/>, "Budget", "Manage budget.", "/admin/finances/budget") }
          { renderDashboardButton(<LineChartOutlined/>, "Investments", "Manage investments.", "/admin/finances/investment-breakdown") }
          { renderDashboardButton(<LineChartOutlined/>, "Blog Posts", "Manage the blog.", "/admin/blog/posts") }
        </Row>
      </SectionContainer>
    </div>
  );
}

export default DashboardPage;
