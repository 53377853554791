import React from 'react';
import { Row, Col} from 'antd';

const ButtonShadowBox = props => {
  const { icon, title, text } = props;
  const minHeight = props.minHeight ?? 0;

  if (props.horizontal ?? false) {
    return (
      <div className="button-shadow-box p-30" onClick={props.onClick} style={{ minHeight: minHeight }}>
        <Row wrap={false}>
          <Col flex="80px">
            <div className="button-shadow-box--icon" style={{ fontSize: 50 }}>
              { icon }
            </div>
          </Col>
          <Col flex="auto">
            <div className="fs-24 fw-700">
              { title}
            </div>
            <div className="button-shadow-box--subtitle">
              { text }
            </div>
          </Col>
        </Row>
      </div>
    )
  } else {
    return (
      <div className="button-shadow-box p-30 text-center" onClick={props.onClick} style={{ minHeight: minHeight }}>
        <div className="button-shadow-box--icon">
          { icon }
        </div>
        <div className="fs-24 fw-700">
          { title}
        </div>
        <div className="button-shadow-box--subtitle">
          { text }
        </div>
      </div>
    );
  }
};

export default ButtonShadowBox;
