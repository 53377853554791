import React, {useEffect, useState} from "react";
import { Row } from 'antd';
import SectionContainer from '../../components/sectionContainer'
import FloatingContainer from '../../components/floatingContainer'
import PageHeader from '../../components/pageHeader'
import SectionHeader from '../../components/sectionHeader'
import { getPosts } from '../../api'
import BlogPostCard from '../../components/blogPostCard'
import FadeDownWhileInView from '../../components/fadeDownWhileInView'

const TravelPage = () => {

  const [posts, setPosts] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0)
    document.title = "Craig Smith | Travel";
    fetchPosts()
  }, [])

  const fetchPosts = () => {
    getPosts("travel").then(response => {
      const allPosts = response.data ?? [];
      const filteredPosts = allPosts.filter(x => x.published);
      setPosts(filteredPosts);
      setLoading(false)
    }).catch((e) => setLoading(false))
  }

  const renderPost = (post) => {
    return (
      <BlogPostCard post={post} key={post.post_key}/>
    )
  }

  const renderPosts = () => {
    return posts.map((post) => renderPost(post))
  }

  return (
    <div>
      <PageHeader title="Travel" bottomOffset={90} backTitle="Home" backPath="/"/>

      <SectionContainer className="bg-gray" verticalPadding={0}>
        <div className="page-header--box">
          <FloatingContainer maxWidth={850}>
            <FadeDownWhileInView delay={0.5} className="page-header--box-title">How it started</FadeDownWhileInView>
            <FadeDownWhileInView delay={1} className="page-header--box-text">My wife and I started traveling after we got married in 2019. We quickly realized how beneficial it was for our marriage to get away and spend quality time together, so it has become one of our biggest passions. We have been blessed to travel all over the country, and we plan on continuing to prioritize it as long as the Lord allows us. We love sharing our experiences for those who are looking for tips for their next trip.</FadeDownWhileInView>
          </FloatingContainer>
        </div>
      </SectionContainer>

      <SectionContainer className="bg-gray" verticalPadding={60}>
        <FloatingContainer maxWidth={1200}>
          <SectionHeader title="Featured Trips"/>
          <Row gutter={[40,40]}>
            { renderPosts() }
          </Row>
        </FloatingContainer>
      </SectionContainer>
    </div>
  );
}

export default TravelPage;
