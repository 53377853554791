import React, {useState, useEffect} from "react";
import { Tag, Table } from 'antd';
import { useNavigate, Link } from "react-router-dom";
import moment from 'moment';
import SectionContainer from '../../../components/sectionContainer'
import FloatingContainer from '../../../components/floatingContainer'
import { ArrowLeftOutlined } from '@ant-design/icons';

import { getPosts, createPost } from '../../../api'

const BlogPostsPage = () => {

  let navigate = useNavigate();

  const [posts, setPosts] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0)
    fetchPosts()
  }, [])

  const fetchPosts = () => {
    getPosts().then(response => {
      setPosts(response.data);
      setLoading(false)
    }).catch((e) => setLoading(false))
  }

  const createNewPost = () => {
    createPost().then(response => {
      const post = response.data;
      navigate(`/admin/blog/post/${post.post_id}`)
    })
  }

  const columns = [
    {
      title: 'Title',
      dataIndex: 'post_name',
      key: 'post_name',
    },
    {
      title: 'Created',
      dataIndex: 'create_datetime',
      key: 'create_datetime',
      render: (date) => moment.utc(date).format("MMMM DD, YYYY")
    },
    {
      title: 'Category',
      dataIndex: 'post_category_name',
      key: 'post_category_name',
      render: (value) => value ? <Tag color="blue">{value}</Tag> : ""
    },
    {
      title: 'Status',
      dataIndex: 'published',
      key: 'published',
      render: (value) => value ? <Tag color="green">Published</Tag> : <Tag color="magenta">Unpublished</Tag>
    },
  ];

  return (
    <div>
      <SectionContainer className="bg-blue-gradient" verticalPadding={40}>
        <div className="page-header--title">Blog Posts</div>
        <div className="page-header--breadcrumb"><ArrowLeftOutlined/> <Link to="/admin/dashboard">Dashboad</Link></div>
      </SectionContainer>
      <SectionContainer className="bg-gray" verticalPadding={60}>
        <FloatingContainer maxWidth={1000}>
          <div className="shadow-box">
            <Table 
              dataSource={posts} 
              columns={columns} 
              pagination={false} 
              scroll={{ x: 'max-content' }}
              onRow={(record, rowIndex) => {
                return {
                  onClick: event => navigate(`/admin/blog/post/${record.post_id}`)
                };
              }}/>
          </div>
          <div className="text-center mt-30">
            <button className="page-button" onClick={createNewPost}>New Post</button>
          </div>
        </FloatingContainer>
      </SectionContainer>
    </div>
  );
}

export default BlogPostsPage;
