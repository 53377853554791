import React, {useState, useEffect} from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import moment from 'moment';
import { isEmpty } from 'lodash';
import SectionContainer from '../../../components/sectionContainer'
import FloatingContainer from '../../../components/floatingContainer'
import LoadingSpinner from '../../../components/loadingSpinner'
import { ArrowLeftOutlined } from '@ant-design/icons';
import { getPost } from '../../../api'

const PostPage = () => {

  let params = useParams();
  let navigate = useNavigate();
  const [post, setPost] = useState({});
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0)
    document.title = "Craig Smith | Blog";
    fetchPost()
  }, [])

  const fetchPost = () => {
    getPost(params.post_key).then(response => {
      if (isEmpty(response.data)) {
        navigate("/")
      }
      setPost(response.data);
      setLoading(false)
      document.title = `Craig Smith | ${response.data.post_name}`;
    }).catch((e) => setLoading(false))
  }

  if (isLoading) {
    return <LoadingSpinner/>
  }

  return (
    <div className="blog-post-page">
      <SectionContainer className="bg-white" verticalPadding={30}>
        <FloatingContainer maxWidth={1000} className="">
          <div className="blog-post-back mb-30"><ArrowLeftOutlined/> <Link to={`/blog/${post.post_category_key}`}>{post.post_category_name}</Link></div>
          <div className="text-center mb-30">
            <div className="post-tag large" onClick={() => navigate(`/blog/${post.post_category_key}`)}>{post.post_category_name}</div>
            <div className="blog-post-name mt-20">{ post.post_name }</div>
            <div className="fs-16 mt-10 c-text-gray">{ moment.utc(post.post_date).format("MMMM DD, YYYY") }</div>
          </div>
        </FloatingContainer>
      </SectionContainer>
      <SectionContainer className="bg-white" verticalPadding={0}>
        <FloatingContainer maxWidth={1000}>
          <img src={post.post_image} style={{ width: '100%'}}/>
          <div className="text-center mt-60"><span style={{ border: '2px solid #4871f7', width: 60, display: 'inline-block'}}></span></div>
        </FloatingContainer>
      </SectionContainer>
      <SectionContainer verticalPadding={60} className="bg-white blog-post-content">
        <FloatingContainer maxWidth={800}>
          <div dangerouslySetInnerHTML={{__html: post.content}} />
        </FloatingContainer>
      </SectionContainer>
    </div>
  );
}

export default PostPage;
