import React, { useContext } from 'react';
import { Link } from "react-router-dom";
import { Layout, Row, Col } from 'antd';
import SectionContainer from './sectionContainer'
import AppContext from '../app/context';

const { Footer } = Layout;

const SiteFooter = props => {
  const { auth, setAuth } = useContext(AppContext);
  let date = new Date();
  let year = date.getFullYear();

  const renderFooter = () => {
    if (!auth.isAuthenticated) {
      return (
        <SectionContainer verticalPadding={40} className="bg-blue-gradient">
          <Row gutter={[30,30]}>
            <Col xs={24} md={8}>
              <div className="fs-20 fw-600 mb-10">Finances</div>
              <div><Link to="/finances/net-worth-projector">Net Worth Projector</Link></div>
              <div><Link to="/finances/mortgage-calculator">Mortgage Calculator</Link></div>
              <div><Link to="/finances/investment-calculator">Investment Calculator</Link></div>
            </Col>
            <Col xs={24} md={8}>
              <div className="fs-20 fw-600 mb-10">Faith</div>
              <div><Link to="/faith/my-testimony">My Testimony</Link></div>
              <div><Link to="/faith/memory-verses">Memory Verses</Link></div>
            </Col>
            <Col xs={24} md={8}>
              <div className="fs-20 fw-600 mb-10">Learn More</div>
              <div><Link to="/about">About Me</Link></div>
              {/* <div><Link to="/contact">Contact Me</Link></div> */}
            </Col>
          </Row>
        </SectionContainer>
      )
    }
  }

  return (
    <Footer className="site-footer">
      { renderFooter() }
      <SectionContainer verticalPadding={20} className="bg-black5">
        <div className="text-center">Copyright © {year} All rights reserved</div>
      </SectionContainer>
    </Footer>
  );
};

export default SiteFooter;
