import React, {useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { Row, Col, Form } from 'antd';
import { isUndefined, isNull, isEmpty } from 'lodash';
import SectionContainer from '../../../components/sectionContainer'
import FloatingContainer from '../../../components/floatingContainer'
import { renderCheckboxField, renderInputField, renderTextAreaField, renderSearchSelectField, renderTagSelectField } from "../../../components/formFields";
import { getVerse, getBibleBooks, saveVerse, updateVerse } from '../../../api'
import LoadingSpinner from '../../../components/loadingSpinner'
import { ArrowLeftOutlined } from '@ant-design/icons';

const VersePage = () => {

  let params = useParams();
  let navigate = useNavigate();
  const [form] = Form.useForm();

  const PAGE_TYPES = {
    new: "new",
    existing: "existing"
  }

  const tags = ["CG","ED1","ED2","ED3"]
  const tagData = tags.map(x => {
    return {
      text: x,
      value: x
    }
  })

  const [verse, setVerse] = useState([]);
  const [books, setBooks] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [pageType, setPageType] = useState(PAGE_TYPES.new);

  useEffect(() => {
    window.scrollTo(0, 0)
    fetchBibleBooks()
    setPageType(params.verse_id === PAGE_TYPES.new ? PAGE_TYPES.new : PAGE_TYPES.existing)
    if (params.verse_id !== PAGE_TYPES.new) {
      fetchVerse(params.verse_id)
    } else {
      setLoading(false)
    }
  }, [])

  const fetchVerse = (verse_id) => {
    getVerse(verse_id).then(response => {
      setVerse(response.data);
      setLoading(false)
      const verseTags = response.data.tags ?? "";
      const verseTagsValue = isEmpty(verseTags) ? [] : verseTags.split(",")
      const verseData = {
        ...response.data,
        tags: verseTagsValue
      }
      form.setFieldsValue(verseData)
    }).catch((e) => setLoading(false))
  }

  const fetchBibleBooks = () => {
    getBibleBooks().then(response => {
      const bookData = response.data.map((book) => {
        return {
          value: book.bible_book_id,
          text: book.book_name
        }
      })
      setBooks(bookData);
    })
  }

  const onSubmit = values => {
    const newValues = {
      ...values,
      tags: !isUndefined(values.tags) ? values.tags.toString() : "",
      ending_verse: !isEmpty(values.ending_verse) ? values.ending_verse : null,
      featured: !isUndefined(values.featured) && !isNull(values.featured) ? values.featured : false
    }
    if (verse.memory_verse_id) {
      updateVerse(verse.memory_verse_id, newValues).then(response => {
        navigate("/admin/faith/verses")
      }).catch((e) => setLoading(false))
    } else {
      saveVerse(newValues).then(response => {
        navigate("/admin/faith/verses")
      }).catch((e) => setLoading(false))
    }
  }

  const getPageName = () => {
    if (pageType == PAGE_TYPES.new) {
      return "New Verse";
    } else {
      const endingVerse = verse.ending_verse == null || verse.starting_verse == verse.ending_verse ? "" : `-${verse.ending_verse}`
      return `${verse.book_name} ${verse.chapter}:${verse.starting_verse }${endingVerse }`
    }
  }

  if (isLoading) {
    return (
      <div className="page-content bg-gray">
        <LoadingSpinner/>
      </div>
    )
  }

  return (
    <div>
      <SectionContainer className="bg-blue-gradient" verticalPadding={40}>
        <div className="page-header--title">{ getPageName() }</div>
        <div className="page-header--breadcrumb"><ArrowLeftOutlined/> <Link to="/admin/faith/verses">Verses</Link></div>
      </SectionContainer>
      <SectionContainer className="bg-gray" verticalPadding={60}>
        <FloatingContainer maxWidth={800}>
          <div className="shadow-box p-20">
            <Form form={form} layout="vertical" name="travel" onFinish={onSubmit}>
              <Row gutter={[20,0]}>
                <Col xs={24}>
                  { renderSearchSelectField("Book", "bible_book_id", "", books, true)}
                </Col>
                <Col xs={24} md={8}>
                  { renderInputField("Chapter", "chapter", "", true)}
                </Col>
                <Col xs={24} md={8}>
                  { renderInputField("Starting Verse", "starting_verse", "", true)}
                </Col>
                <Col xs={24} md={8}>
                  { renderInputField("Ending Verse", "ending_verse", "", false)}
                </Col>
              </Row>
              { renderTextAreaField("Verse Text", "verse_text", 5, true)}
              { renderTextAreaField("Application", "application", 5, false)}
              { renderTagSelectField("Tags", "tags", "", tagData)}
              { renderCheckboxField("Featured", "featured")}
              <button className="page-button" type="submit">Save</button>
            </Form>
          </div>
        </FloatingContainer>
      </SectionContainer>
    </div>
  );
}

export default VersePage;
