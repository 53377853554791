import React, {useState, useEffect} from "react";
import { useNavigate, Link } from "react-router-dom";
import { Row, Col} from 'antd';
import SectionContainer from '../../components/sectionContainer'
import PageHeader from '../../components/pageHeader'
import FloatingContainer from '../../components/floatingContainer'

const ContactPage = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
    document.title = "Craig Smith | Contant";
  }, [])

  return (
    <div>
      <PageHeader title="Contact Me" backTitle="Home" backPath="/"/>

      <SectionContainer verticalPadding={60}>
        <FloatingContainer maxWidth={800}>
          <div className="text-center fs-18 fw-700 c-text-gray">Coming soon!</div>
        </FloatingContainer>
      </SectionContainer>
    </div>
  );
}

export default ContactPage;
