import React, {useState, useEffect} from "react";
import { useNavigate, Link } from "react-router-dom";
import { Collapse} from 'antd';
import { isEmpty } from 'lodash';
import SectionContainer from '../../components/sectionContainer'
import FloatingContainer from '../../components/floatingContainer'
import { getVerses } from '../../api'
import PageHeader from '../../components/pageHeader'
import SectionHeader from '../../components/sectionHeader'
import FadeUpWhileInView from '../../components/fadeUpWhileInView'
import FadeDownWhileInView from '../../components/fadeDownWhileInView'

const { Panel } = Collapse;

const FaithPage = () => {

  const [verses, setVerses] = useState([]);
  const [isLoading, setLoading] = useState(true);
  let navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0)
    document.title = "Craig Smith | Faith";
    fetchVerses()
  }, [])

  const fetchVerses = () => {
    getVerses().then(response => {
      const filteredVerses = response.data.filter(x => x.featured);
      setVerses(filteredVerses);
      setLoading(false)
    }).catch((e) => setLoading(false))
  }

  const renderVerse = (verse) => {
    const endingVerse = verse.ending_verse === null || verse.starting_verse === verse.ending_verse ? "" : `-${verse.ending_verse}`
    return (
      <div className="verse-box mb-20" style={{ minHeight: 0 }} key={verse.memory_verse_id}>
        <div className="verse-box--reference">{verse.book_name} {verse.chapter}:{verse.starting_verse}{endingVerse}</div>
        <div className="verse-box--text">{verse.verse_text}</div>
        { renderApplication(verse) }
      </div>
    )
  }

  const renderApplication = (verse) => {
    if (!isEmpty(verse.application)) {
      return (
        <div className="verse-box--collapse">
          <Collapse ghost>
            <Panel header="Application" key="1">
              { verse.application }
            </Panel>
          </Collapse>
        </div>
      )
    }
    return null;
  }

  const renderVerses = () => {
    return verses.map((verse) => renderVerse(verse))
  }

  return (
    <div>
      <PageHeader title="Faith" bottomOffset={90} backTitle="Home" backPath="/"/>
      
      <SectionContainer className="bg-gray">
        <div className="page-header--box">
          <FloatingContainer maxWidth={850}>
            <FadeDownWhileInView delay={0.5} className="page-header--box-title">My Testimony</FadeDownWhileInView>
            <FadeDownWhileInView delay={1} className="page-header--box-text">There has been a common theme throughout my life which involves me trying to seek comfort from the things of this world and then being left anxious and depressed because it doesn’t work. The things that I think will bring happiness never truly satisfy and always leaves me searching for the next high.  As a kid, I came to understand that having a relationship with God brings peace, joy, and contentment in a way that nothing else on earth can provide.</FadeDownWhileInView>
            <FadeUpWhileInView delay={1}>
              <button className="page-button mt-20" onClick={() => navigate("/faith/my-testimony")}>Read More</button>
            </FadeUpWhileInView>
          </FloatingContainer>
        </div>
      </SectionContainer>

      <SectionContainer verticalPadding={70}>
        <FloatingContainer maxWidth={800}>
          <SectionHeader title="Memory Verses"/>
          <FadeUpWhileInView className="fs-18 c-text-gray text-center mb-30">
            Memorizing scripture is something I have grown to love. It allows me to think about scripture without having a bible in front of me. I have also found that when I encounter a difficult situation, the Lord reminds me of a certain verse I memorized in the past that directly applies to the circumstance. Here are some verses I have memorized and a few ways I am trying to apply them to my life.
          </FadeUpWhileInView>
          {renderVerses()}
          <FadeUpWhileInView className="text-center">
            <button className="page-button mt-20" onClick={() => navigate("/faith/memory-verses")}>View More</button>
          </FadeUpWhileInView>
        </FloatingContainer>
      </SectionContainer>
    </div>
  );
}

export default FaithPage;
