import React from 'react';
import { useNavigate, Link } from "react-router-dom";
import SectionContainer from './sectionContainer'
import FloatingContainer from './floatingContainer'
import { ArrowLeftOutlined } from '@ant-design/icons';
import { motion } from 'framer-motion';

const PageHeader = props => {
  const paddingBottom = props.bottomOffset ? 60 + props.bottomOffset : 60
  return (
    <SectionContainer className="bg-blue-gradient">
      <motion.div 
        className="page-header--breadcrumb"
        initial="hidden"
        animate="visible"
        transition={ { duration: 1 } }
        variants={{
          visible: { opacity: 1, x: 0 },
          hidden: { opacity: 0, x: -20 }
        }}
      ><ArrowLeftOutlined/> <Link to={props.backPath ?? ""}>{props.backTitle ?? ""}</Link></motion.div>
      <motion.div 
        className="page-header--title" 
        style={{ paddingTop: 40, paddingBottom: paddingBottom }}
        initial="hidden"
        animate="visible"
        transition={ { duration: 1 } }
        variants={{
          visible: { opacity: 1, y: 0 },
          hidden: { opacity: 0, y: -20 }
        }}
      >{props.title ?? ""}</motion.div>
    </SectionContainer>
  );
};

export default PageHeader;
