import React from 'react';
import { useNavigate } from "react-router-dom";
import { Row, Col} from 'antd';
import SectionContainer from './sectionContainer'
import FloatingContainer from './floatingContainer'
import { ArrowLeftOutlined } from '@ant-design/icons';
import { motion } from 'framer-motion';

const HoverCard = props => {
  var lgCol = 8;
  if (props.colNum === 2) {
    lgCol = 12;
  } else if (props.colNum === 4) {
    lgCol = 6;
  }

  let navigate = useNavigate();

  return (
    <Col xs={24} md={12} lg={lgCol}>
      <motion.div 
        className="shadow-box cursor-default text-center p-30" 
        style={{ height: "100%"}} 
        onClick={() => props.path ? navigate(props.path) : {}}
        initial="hidden"
        whileInView="visible"
        whileHover="hover"
        viewport={{ once: true }}
        variants={{
          visible: { opacity: 1, y: 0, transition: { duration: 1 } },
          hidden: { opacity: 0, y: 50, transition: { duration: 0.2 } },
          hover: { scale: 1.05, transition: { duration: 0.2 } }
        }}
      >
        <div className="circle-icon">
          { props.icon }
        </div>
        <div className="c-blue fs-24 fw-700 mt-30 line-1-1">{ props.title }</div>
        <div className="fs-16 fw-400 mt-20">{ props.text }</div>
      </motion.div>
    </Col>
  );
};

export default HoverCard;
