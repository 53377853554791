import React, {useState, useEffect, useRef } from "react";
import { Editor } from '@tinymce/tinymce-react';
import { useParams, useNavigate, Link } from "react-router-dom";
import moment from 'moment';
import { isUndefined, isNull } from 'lodash';
import { Row, Col, Form, Upload } from 'antd';
import { LoadingOutlined, PlusOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { renderDateField, renderInputField, renderFormLabel, renderCheckboxField } from "../../../components/formFields";
import SectionContainer from '../../../components/sectionContainer'
import { createTrip, updateTrip, getTrip, uploadTripPhoto } from '../../../api'

const API_URL = process.env.REACT_APP_API_URL;

const TripEditorPage = () => {

  const editorRef = useRef(null);
  const [form] = Form.useForm();
  let navigate = useNavigate();
  let params = useParams();

  const PAGE_TYPES = {
    new: "new",
    existing: "existing"
  }

  const [trip, setTrip] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [isLoadingImage, setLoadingImage] = useState(false);
  const [tripImageUrl, setTripImageUrl] = useState();
  const [pageType, setPageType] = useState(PAGE_TYPES.new);

  useEffect(() => {
    window.scrollTo(0, 0)
    setPageType(params.trip_id === PAGE_TYPES.new ? PAGE_TYPES.new : PAGE_TYPES.existing)
  }, [])

  const fetchTrip = () => {
    if (params.trip_id !== "new") {
      getTrip(params.trip_id).then(response => {
        const data = response.data
        setTrip(data);
        setLoading(false)
        const values = {
          trip_key: data.trip_key,
          trip_name: data.trip_name,
          start_date: moment.utc(data.start_date),
          end_date: moment.utc(data.end_date),
          published: data.published ?? false
        }
        form.setFieldsValue(values)
        editorRef.current.setContent(data.content)
        setTripImageUrl(data.trip_image)
      }).catch((e) => setLoading(false))
    }
  }

  const onSubmit = values => {

    var newValues = {
      ...values,
      start_date: moment(values.start_date).format("YYYY-MM-DD"),
      end_date: moment(values.end_date).format("YYYY-MM-DD"),
      content: editorRef.current ? editorRef.current.getContent() : null,
      trip_image: tripImageUrl,
      published: !isUndefined(values.published) && !isNull(values.published) ? values.published : false
    }

    if (trip.trip_id) {
      updateTrip(trip.trip_id, newValues).then(response => {
      }).catch((e) => setLoading(false))
    } else {
      createTrip(newValues).then(response => {
        navigate("/admin/travel/trips")
      }).catch((e) => setLoading(false))
    }
  }

  const getPageName = () => {
    if (pageType == PAGE_TYPES.new) {
      return "New Trip";
    } else {
      return trip.trip_name ?? ""
    }
  }

  function example_image_upload_handler(blobInfo, success, failure, progress) {
    var xhr, formData;
  
    xhr = new XMLHttpRequest();
    xhr.withCredentials = false;
    xhr.open('POST', `${API_URL}/travel/trips/photo`);
  
    xhr.upload.onprogress = function (e) {
      progress(e.loaded / e.total * 100);
    };
  
    xhr.onload = function() {
      var json;
  
      if (xhr.status === 403) {
        failure('HTTP Error: ' + xhr.status, { remove: true });
        return;
      }
  
      if (xhr.status < 200 || xhr.status >= 300) {
        failure('HTTP Error: ' + xhr.status);
        return;
      }
  
      json = JSON.parse(xhr.responseText);
  
      if (!json || typeof json.location != 'string') {
        failure('Invalid JSON: ' + xhr.responseText);
        return;
      }
  
      success(json.location);
    };
  
    xhr.onerror = function () {
      failure('Image upload failed due to a XHR Transport error. Code: ' + xhr.status);
    };
  
    formData = new FormData();
    formData.append('file', blobInfo.blob(), blobInfo.filename());
  
    xhr.send(formData);
  };

  function uploadHandler(data) {
    setLoadingImage(true)

    var formData = new FormData();
    formData.append('file', data.file);

    uploadTripPhoto(formData).then(response => {
      setTripImageUrl(response.data.location)
      setLoadingImage(false)
    }).catch(e => {
      setLoadingImage(false)
    })
  };

  const uploadButton = (
    <div>
      {isLoadingImage ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const renderUploadMainPhoto = () => {
    return (
      <Upload
        name="trip-image"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        customRequest={uploadHandler}
      >
        {tripImageUrl ? <img src={tripImageUrl} alt="Trip Image" style={{ width: '100%' }} /> : uploadButton}
      </Upload>
    )
  }

  return (
    <div>
      <SectionContainer className="bg-blue-gradient" verticalPadding={40}>
        <div className="page-header--title">{ getPageName() }</div>
        <div className="page-header--breadcrumb"><ArrowLeftOutlined/> <Link to="/admin/travel/trips">Trips</Link></div>
      </SectionContainer>
      <SectionContainer className="bg-gray" verticalPadding={60}>
        <div className="shadow-box p-30">
          <Form form={form} layout="vertical" name="travel" onFinish={onSubmit}>
            <Row gutter={[30,0]}>
              <Col xs={24} md={12}>
                { renderInputField("Trip Name", "trip_name", "", true)}
              </Col>
              <Col xs={24} md={12}>
                { renderInputField("Trip Key", "trip_key", "", true)}
              </Col>
              <Col xs={12}>
                { renderDateField("Start Date", "start_date", true)}
              </Col>
              <Col xs={12}>
                { renderDateField("End Date", "end_date", true)}
              </Col>
              <Col xs={12}>
                { renderFormLabel("Trip Photo (600px)") }
                { renderUploadMainPhoto() }
              </Col>
              <Col xs={12}>
                { renderCheckboxField("Published", "published")}
              </Col>
            </Row>
            <Editor
              onInit={(evt, editor) => { 
                editorRef.current = editor
                editorRef.current.focus()
                if (params.trip_id !== "new") {
                  fetchTrip(params.verse_id)
                }
              }}
              initialValue=""
              init={{
                height: 800,
                menubar: 'file edit view insert format tools table',
                fontsize_formats: '10px 12px 14px 16px 18px 20px 24px 30px 32px 36px 40px 50px 60px',
                file_picker_types: 'image',
                file_picker_callback: function (cb, value, meta) {
                  var input = document.createElement('input');
                  input.setAttribute('type', 'file');
                  input.setAttribute('accept', 'image/*');
                  input.onchange = function () {
                    var file = this.files[0];
              
                    var reader = new FileReader();
                    reader.onload = function () {
                      var id = 'blobid' + (new Date()).getTime();
                      var blobCache =  editorRef.current.editorUpload.blobCache;
                      var base64 = reader.result.split(',')[1];
                      var blobInfo = blobCache.create(id, file, base64);
                      blobCache.add(blobInfo);
              
                      /* call the callback and populate the Title field with the file name */
                      cb(blobInfo.blobUri(), { title: file.name });
                    };
                    reader.readAsDataURL(file);
                  };
              
                  input.click();
                },
                automatic_uploads: true,
                images_upload_handler: example_image_upload_handler,
                plugins: [
                  'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons'
                  // 'advlist autolink lists link image charmap print preview anchor',
                  // 'searchreplace visualblocks code fullscreen',
                  // 'insertdatetime media table paste code help wordcount'
                ],
                toolbar: 'formatselect fontselect fontsizeselect | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen preview save print | insertfile image link anchor codesample',
                // 'undo redo | formatselect | ' +
                // 'bold italic backcolor | alignleft aligncenter ' +
                // 'alignright alignjustify | bullist numlist outdent indent | ' +
                // 'removeformat | help',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }'
              }}
            />
            
            <div className="mt-30 text-right">
              <button className="page-button" type="submit">Save</button>
            </div>
          </Form>
        </div>
        <div className="text-right mt-20">
          <button className="small-page-button-outline" onClick={() => navigate("/admin/travel/trips")}>Close</button>
          <button className="small-page-button ml-10" onClick={() => navigate(`/travel/${trip.trip_key}`)}>Preview</button>
        </div>
      </SectionContainer>
    </div>
  );
}

export default TripEditorPage;
