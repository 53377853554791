import React, {useState, useEffect} from "react";
import { Row, Col} from 'antd';
import SectionContainer from '../../components/sectionContainer'
import FloatingContainer from '../../components/floatingContainer'
import ModalHeader from '../../components/modalHeader'

const TimelinePage = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
    document.title = "Craig Smith | Software";
  }, [])

  const renderItemRow = (time, event, music) => {
    return (
      <Row className="bb-gray p-10 pagebreak" gutter={[15,15]}>
        <Col flex="100px">
          <div className="fs-14 fw-600">4:00 PM</div>
        </Col>
        <Col flex="auto">
          <div className="fs-14 fw-600">Grand Entrance</div>
          <div className="fs-14 c-text-gray ml-10">Wedding party intros</div>
        </Col>
        <Col flex="300px">
          <div className="fs-14">"Can't Stop the Feeling" - Justin Timberlake</div>
        </Col>
      </Row>
    )
  }

  const renderItemRowHeader = () => {
    return (
      <Row className="bb-gray bg-gray bt-gray p-10" gutter={[15,15]}>
        <Col flex="100px">
          <div className="fs-16 fw-600">Time</div>
        </Col>
        <Col flex="auto">
          <div className="fs-16 fw-600">Event</div>
        </Col>
        <Col flex="300px">
          <div className="fs-16 fw-600">Music</div>
        </Col>
      </Row>
    )
  }

  return (
    <div>
      <ModalHeader title="Preview" onClose={() => console.log("HEY")}/>
      <SectionContainer verticalPadding={30}>
        <FloatingContainer maxWidth={800}>
          <div className="fs-24 fw-600 text-center">Simmons + Smith Wedding</div>
          <div className="fs-14 text-center c-text-gray">Friday, May 3, 2019</div>
          <Row className="mt-30" gutter={[15,15]}>
            <Col sm={12}>
              <div className="fs-20 fw-600 mb-10">Venue</div>
              <div className="fs-16">The Milestone Mansion</div>
              <div className="fs-16">123 Main St.</div>
              <div className="fs-16">Aubrey, TX</div>
            </Col>
            <Col sm={12}>
              <div className="fs-20 fw-600 mb-10">Services Provided</div>
              <div className="fs-16">DJ / MC Services</div>
              <div className="fs-16">Dance Floor Lighting</div>
            </Col>
          </Row>
          <div className="fs-20 fw-600 mt-30 mb-10">Ceremony Timeline</div>
          { renderItemRowHeader() }
          { renderItemRow("4:00 PM", "Grand Entrance", "Can't Stop the Feeling - Justin Timberlake") }
          { renderItemRow("4:00 PM", "Grand Entrance", "Can't Stop the Feeling - Justin Timberlake") }
          { renderItemRow("4:00 PM", "Grand Entrance", "Can't Stop the Feeling - Justin Timberlake") }
          <div className="fs-20 fw-600 mt-60 mb-10">Reception Timeline</div>
          { renderItemRowHeader() }
          { renderItemRow("4:00 PM", "Grand Entrance", "Can't Stop the Feeling - Justin Timberlake") }
          { renderItemRow("4:00 PM", "Grand Entrance", "Can't Stop the Feeling - Justin Timberlake") }
          { renderItemRow("4:00 PM", "Grand Entrance", "Can't Stop the Feeling - Justin Timberlake") }
          { renderItemRow("4:00 PM", "Grand Entrance", "Can't Stop the Feeling - Justin Timberlake") }
          { renderItemRow("4:00 PM", "Grand Entrance", "Can't Stop the Feeling - Justin Timberlake") }
          { renderItemRow("4:00 PM", "Grand Entrance", "Can't Stop the Feeling - Justin Timberlake") }
          { renderItemRow("4:00 PM", "Grand Entrance", "Can't Stop the Feeling - Justin Timberlake") }
          { renderItemRow("4:00 PM", "Grand Entrance", "Can't Stop the Feeling - Justin Timberlake") }
          { renderItemRow("4:00 PM", "Grand Entrance", "Can't Stop the Feeling - Justin Timberlake") }
        </FloatingContainer>
      </SectionContainer>
    </div>
  );
}

export default TimelinePage;
