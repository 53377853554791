import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col} from 'antd';
import { HomeOutlined, LineChartOutlined, CodeOutlined, DollarCircleOutlined, FundOutlined, BankOutlined, SafetyOutlined } from '@ant-design/icons';
import homePhoto from '../../images/home.jpg';

const ResourcesPage = () => {

  let navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0)
    document.title = "Craig Smith | Resources";
  }, [])

  const renderTile = (text, icon, route) => {
    return (
      <Col xs={8} md={8} lg={6} xl={4}>
        <div className="tile" onClick={() => navigate(route)}>
          <div className="tile-icon">
            {icon}
          </div>
          <div className="tile-text">
            {text}
          </div>
        </div>
      </Col>
    )
  }

  return (
    <div>
      <Row justify="center" className="home-hero" gutter={[30,30]}>
        <Col xs={22} md={18} lg={8} xl={8} className="home-hero-box">
          <div>
            <div className="home-title">Hi, I'm Craig.</div>
            <div className="home-subtitle">I'm a husband, father, software engineer, wedding DJ, woodworker, and entreprenuer.</div>
            <button className="page-button mt-30">About Me</button>
          </div>
        </Col>
        <Col xs={22} md={18} lg={8} xl={8}>
          <img src={homePhoto} alt="Craig Smith" style={{width: '100%'}}/>
        </Col>
      </Row>
      <Row justify="center" className="gray-row mb-30">
        <Col xs={22} md={18} lg={8} xl={16}>
          <div className="page-subtitle">Resources</div>
          <Row gutter={[30,30]}>
            <Col xs={8}>
              <div className="home-box">
                <div className="home-title">
                  Finances
                </div>
                <div className="home-subtitle">
                  Information about managing your money, including helpful calculators
                </div>
                
                <button className="home-button mt-30" onClick={() => navigate("travel")}>Read More</button>
              </div>
            </Col>
            <Col xs={8}>
              <div className="home-box">
                <div className="home-title">
                  Travel
                </div>
                <div className="home-subtitle">
                  Get helpful tips from places we traveled
                </div>
                <button className="home-button mt-30" onClick={() => navigate("travel")}>Read More</button>
              </div>
            </Col>
            <Col xs={8}>
              <div className="home-box">
                <div className="home-title">
                  Software
                </div>
                <div className="home-subtitle">
                  Get helpful tips from places we traveled
                </div>
                <button className="home-button mt-30" onClick={() => navigate("travel")}>Read More</button>
              </div>
            </Col>
            <Col xs={8}>
              <div className="home-box">
                <div className="home-title">
                  Woodworking
                </div>
                <div className="home-subtitle">
                  Get helpful tips from places we traveled
                </div>
                <button className="home-button mt-30" onClick={() => navigate("travel")}>Read More</button>
              </div>
            </Col>
            <Col xs={8}>
              <div className="home-box">
                <div className="home-title">
                  Health
                </div>
                <div className="home-subtitle">
                  Get helpful tips from places we traveled
                </div>
                <button className="home-button mt-30" onClick={() => navigate("travel")}>Read More</button>
              </div>
            </Col>
          </Row>
          {/* <div className="page-title">Let's talk about money</div>
          <div className="page-text">Find information about managing your money, including helpful calculators.</div>
          <button className="page-button mt-30">Explore</button> */}
        </Col>
      </Row>

      <Row justify="center" className="gray-row">
        <Col xs={22} md={18} lg={8} xl={8}>
          <div className="page-subtitle">Finances</div>
          <div className="page-title">Let's talk about money</div>
          <div className="page-text">Find information about managing your money, including helpful calculators.</div>
          <button className="page-button mt-30">Explore</button>
        </Col>
        <Col xs={22} md={18} lg={8} xl={8}>
          
        </Col>
      </Row>
      <Row justify="center" className="white-row">
        <Col xs={22} md={18} lg={8} xl={8}>
          
        </Col>
        <Col xs={22} md={18} lg={16} xl={6}>
          <div className="page-subtitle">Travel</div>
          <div className="page-title">Explore the world</div>
          <div className="page-text">Get helpful tips from places we have traveled.</div>
          <button className="page-button mt-30" onClick={() => navigate("travel")}>Read More</button>
        </Col>
      </Row>
      <Row justify="center" className="gray-row">
        <Col xs={22} md={18} lg={16} xl={6}>
          <div className="page-subtitle">Woodworking</div>
          <div className="page-title">Let's build stuff</div>
          <div className="page-text">Join me in my journey of learning how to build things.</div>
          <button className="page-button mt-30">Read More</button>
        </Col>
        <Col xs={22} md={18} lg={16} xl={6}>
          
        </Col>
      </Row>
      <Row justify="center" className="white-row">
        <Col xs={22} md={18} lg={16} xl={6}>

        </Col>
        <Col xs={22} md={18} lg={16} xl={6}>
          <div className="page-subtitle">Health</div>
          <div className="page-title">Your body is a temple</div>
          <div className="page-text">Get helpful tips from places we have traveled.</div>
          <button className="page-button mt-30">Read More</button>
        </Col>
      </Row>
      <Row justify="center" className="gray-row">
        <Col xs={22} md={18} lg={16} xl={6}>
          <div className="page-subtitle">Software Engineering</div>
          <div className="page-title">Let's get technical</div>
          <div className="page-text">The place I put all my documentation. Maybe you will find it useful as well!</div>
          <button className="page-button mt-30">Read More</button>
        </Col>
        <Col xs={22} md={18} lg={16} xl={6}>

        </Col>
      </Row>
    </div>
  );
}

export default ResourcesPage;
