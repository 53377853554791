import React, { useEffect, useState, useContext, useRef } from "react";
import { Navigate, Link, useNavigate } from "react-router-dom";
import { get, isEmpty } from 'lodash';
import { Form, Input } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone, UserOutlined } from '@ant-design/icons';
import AppContext from '../../app/context';
import UserConstants from '../../constants/users';
import SectionContainer from '../../components/sectionContainer'
import FloatingContainer from '../../components/floatingContainer'

import { verifyUser, login, verifyEmail } from '../../api';

const LoginPage = () => {

  const PAGE_TYPE_VERIFY = 'verify';
  const PAGE_TYPE_SIGNIN = 'signin';

  const navigate = useNavigate();
  const { auth, setAuth } = useContext(AppContext);
  const emailInputRef = useRef(null);
  const inputRef = useRef(null);

  const [errorMessage, setErrorMessage] = useState("");
  const [user, setUser] = useState({});
  const [pageType, setPageType] = useState(PAGE_TYPE_VERIFY);

  useEffect(() => {
    window.scrollTo(0, 0)
    document.title = "Craig Smith | Login";
  }, [])

  const onSubmit = values => {
    setErrorMessage("");
    // if (pageType == PAGE_TYPE_VERIFY) {
    //   verifyUser(values.email).then(response => {
    //     // Get verify status from response or default to "not-found"
    //     const status = get(response, "data.status", UserConstants.status.NOT_FOUND);
    //     // Check status
    //     if (status == UserConstants.status.INACTIVE) {
    //       // If user is inactive, that means they haven't created a password yet
    //       navigate({ pathname: "/create/password", state: values })
    //     } else if (status == UserConstants.status.NOT_VERIFIED) {
    //       // If user is not verified, they need to verify their email before proceeding
    //       navigate({ pathname: "/verify/email", state: { email: values.email, from: "login" } })
    //     } else if (status == UserConstants.status.ACTIVE) {
    //       // If user is active, they can proceed to entering their password
    //       const userResponse = get(response, "data.user", {});
    //       setPageType(PAGE_TYPE_SIGNIN)
    //       setUser(userResponse)
    //       inputRef.current.focus({
    //         cursor: 'start'
    //       })
    //     } else if (status == UserConstants.status.NOT_FOUND) {
    //       setErrorMessage("An account with this email address cannot be found.")
    //     }
    //   }).catch(error => {
    //     setErrorMessage("Something went wrong. Please try again.");
    //   })
    // } else {
      login({ email: values.email, password: values.password }).then(response => {
        const authObject = {
          accessToken: response.data.access_token,
          refreshToken: response.data.refresh_token,
          isAuthenticated: true,
          userTypeId: response.data.user.user_type_id,
          accountStatus: response.data.user.account_status
        }
        setAuth(authObject)
      }).catch(error => {
        setErrorMessage("Your credentials are incorrect. Please try again.");
      })
    // }
    
    // const authObject = {
    //   accessToken: "12345",
    //   refreshToken: "123456",
    //   isAuthenticated: true,
    //   userTypeId: 1,
    //   accountStatus: "active"
    // }
    // setAuth(authObject)
  }

  const renderContent = () => {
    if (auth.isAuthenticated && auth.userTypeId === UserConstants.type.ADMIN) {
      // Send Admin to admin console after successful login
      return <Navigate to="/admin/dashboard"/>;
    }
    return renderSignInPage();
  }

  const renderError = () => {
    if (!isEmpty(errorMessage)) {
      return <div className="c-red text-center mb-20">{errorMessage}</div>;
    }
    return null;
  }

  const goBack = () => {
    setErrorMessage("");
    setPageType(PAGE_TYPE_VERIFY)
  }

  const renderVerifyPage = () => {
    return (
      <div className="center-content-box">
        <div className="login-form-title">Sign In</div>
        <div className="login-form-email">Enter an email address to continue</div>
        <div className="center-content-box--form">
          <Form layout="vertical" name="verify" onFinish={onSubmit}>
            <Form.Item
              name={"email"}
              rules={[
                { required: true, message: `Email is required!` },
                { type: "email", message: `Please enter a valid email address` }
              ]}
            >
              <Input size="large" ref={emailInputRef} placeholder="Email"/>
            </Form.Item>
            { renderError() }
            <button className="primary-button" type="submit">Next</button>
          </Form>
        </div>
      </div>
    )
  }

  const renderSignInPage = () => {
    return (
      <div className="shadow-box p-30">
        <div className="fs-24 fw-800 text-center mb-20">Sign In</div>
        <Form layout="vertical" name="login" onFinish={onSubmit}>
          <Form.Item
            name={"email"}
            rules={[
              { required: true, message: `Email is required!` },
              { type: "email", message: `Please enter a valid email address` }
            ]}
          >
            <Input size="large" ref={emailInputRef} placeholder="Email"/>
          </Form.Item>
          <Form.Item
            name={"password"}
            rules={[
              { required: true, message: `Password is required!` }
            ]}
          >
            <Input.Password
              ref={inputRef}
              size="large"
              placeholder="Password"
              iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            />
          </Form.Item>
          { renderError() }
          <button className="primary-button" type="submit">Sign In</button>
          {/* <div className="center-content-box--link-container">
            <Link to="/forgot/password" className="center-content-box--link">Forgot password?</Link>
          </div> */}
        </Form>
      </div>
    )
  }

  return (
    <div className="center-content-page">
      <SectionContainer verticalPadding={70}>
        <FloatingContainer maxWidth={500}>
          { renderContent() }
        </FloatingContainer>
      </SectionContainer>
    </div>
  );
}

LoginPage.propTypes = {};

export default LoginPage;
