import React, {useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col } from 'antd';
import { motion } from 'framer-motion';
import SectionContainer from '../../components/sectionContainer'
import FloatingContainer from '../../components/floatingContainer'
import FadeUpWhileInView from '../../components/fadeUpWhileInView'
import FadeDownWhileInView from '../../components/fadeDownWhileInView'
import PageHeader from '../../components/pageHeader'
import { TrophyOutlined, LaptopOutlined, CustomerServiceOutlined, TeamOutlined, BankOutlined, FlagOutlined, NodeIndexOutlined, HomeOutlined } from '@ant-design/icons';
import husbandPhoto from '../../images/husband.jpg';
import baylorPhoto from '../../images/baylor.png';

const AboutPage = () => {

  const skills = ["React","React Native","Swift","Kotlin","Javascript","CSS","Java","SQL","AWS","Docker","Jenkins","Bitrise","Sketch","JIRA"];

  let navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0)
    document.title = "Craig Smith | About Me";
  }, [])

  const renderWorkExperienceCard = (icon, date, title, position, description) => {
    return (
      <Col xs={24} md={24}>
        <motion.div 
          className="shadow-box p-30"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 1, delay: 0.1 }}
          variants={{
            visible: { opacity: 1, y: 0 },
            hidden: { opacity: 0, y: 50 }
          }}
        >
          <div className="experience-row">
            <div className="experience-row--col-1">
              <div className="circle-icon">
                { icon }
              </div>
            </div>
            <div className="experience-row--col-2">
             <div className="fs-16 fw-600 c-blue">{ date }</div>
              <div className="fs-30 fw-700 line-1-2 experience-row--title">{ title }</div>
              <div className="fs-18 fw-500 c-text-gray">{ position }</div>
            </div>
          </div>
          <div className="fs-16 fw-400 mt-20 bg-gray p-20 corner-8">{ description }</div>
        </motion.div>
      </Col>
    )
  } 

  const renderEducationCard = (icon, date, title, position, description) => {
    return (
      <Col xs={24} md={24}>
        <motion.div 
          className="shadow-box p-30"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 1, delay: 0.1 }}
          variants={{
            visible: { opacity: 1, y: 0 },
            hidden: { opacity: 0, y: 50 }
          }}
        >
          <div className="experience-row">
            <div className="experience-row--col-1">
              <div className="circle-icon">
                { icon }
              </div>
            </div>
            <div className="experience-row--col-2">
             <div className="fs-16 fw-600 c-blue">{ date }</div>
              <div className="fs-30 fw-700 line-1-2 experience-row--title">{ title }</div>
              <div className="fs-18 fw-500 c-text-gray mt-5">{ position }</div>
              <div className="fs-18 fw-500 c-text-gray">{ description }</div>
            </div>
          </div>
        </motion.div>
      </Col>
    )
  } 

  const renderSkills = () => {
    return skills.map((x,i) => {
      return <span key={i} className="skills-tag">{x}</span>;
    })
  }

  return (
    <div className="about-me-page">
      <PageHeader title="About Me" bottomOffset={90} backTitle="Home" backPath="/"/>

      <SectionContainer className="bg-gray" verticalPadding={0}>
        <div className="page-header--box">
          <FloatingContainer maxWidth={850}>
            <FadeDownWhileInView delay={0.5} className="page-header--box-title">My Testimony</FadeDownWhileInView>
            <FadeDownWhileInView delay={1} className="page-header--box-text">There has been a common theme throughout my life which involves me trying to seek comfort from the things of this world and then being left anxious and depressed because it doesn’t work. The things that I think will bring happiness never truly satisfy and always leaves me searching for the next high.  As a kid, I came to understand that having a relationship with God brings peace, joy, and contentment in a way that nothing else on earth can provide.</FadeDownWhileInView>
            <FadeUpWhileInView delay={1}>
              <button className="page-button mt-20" onClick={() => navigate("/faith/my-testimony")}>Read More</button>
            </FadeUpWhileInView>
          </FloatingContainer>
        </div>
      </SectionContainer>

      <SectionContainer className="bg-gray" verticalPadding={90}>
        <Row gutter={[60,30]}>
          <Col xs={24} md={24} lg={12}>
            <img src={husbandPhoto} alt="Craig Smith" style={{width: '100%', borderRadius: 8}} className="drop-shadow"/>
          </Col>
          <Col xs={24} md={24} lg={12}>
            <div className="fs-18 fw-300">
              I am and always will be an entrepreneur at heart. At age 14, I started my first business renting out bounce houses for parties, festivals, and other special events. A year later, I helped my parents start a family business selling snow cones. This led me on a journey to start several other small businesses, one of which is a DJ company that I still own and operate today.
              <br/><br/>
              I graduated from Baylor University in 2010 with a degree in Management Information Systems. This launched my career as a software engineer, and my passion for technology continued to grow. One of the many things I love about my job is helping people solve problems. As a consultant, I've had the opportunity to help build applications for several Fortune 500 companies. Although I have lots of experience in web development, I have been specializing in mobile app development for the past few years.
              <br/><br/>
              I met my beautiful wife in 2016 while country dancing with some mutual friends. We started dating shortly after, and got married 3 years later. One of the ways we love creating memories is through traveling together as much as we can. We are also actively involved in our church and a part of a community group with other married couples.
            </div>
          </Col>
        </Row>
      </SectionContainer>

      <SectionContainer className="bg-white" verticalPadding={60}>
        <FloatingContainer maxWidth={800}>
          <FadeUpWhileInView className="fs-24 fw-700 c-text-gray uppercase text-center mb-30">Education</FadeUpWhileInView>
          {renderEducationCard(<TrophyOutlined/>,"2006-2010","Baylor University","Bachelor of Business Administration","Management Information Systems")}
        </FloatingContainer>
      </SectionContainer>

      <SectionContainer className="bg-gray" verticalPadding={60}>
        <FadeUpWhileInView className="fs-24 fw-700 c-text-gray uppercase text-center mb-30">Work Experience</FadeUpWhileInView>
        <FloatingContainer maxWidth={800}>
          <Row gutter={[0,30]}>
            {renderWorkExperienceCard(<LaptopOutlined/>,"2015 - Present","Credera","Architect","Credera is a consulting firm focused on strategy, transformation, data, and technology. I manage a team of software engineers who build enterprise applications for clients in all types of industries. I specialize in web and mobile app development, and I’m on the leadership team for our firm’s mobile capability.")}
            {renderWorkExperienceCard(<CustomerServiceOutlined/>,"2010 - Present","TriStar DJ","Owner / Wedding DJ","I started this company in college after volunteering to DJ some events for my fraternity. After I graduated, I transitioned to weddings which is my primary focus today. I offer DJ/MC services, dance floor lighting, and uplighting for my clients. I specialize in reading the crowd and creating events that people can't stop talking about.")}
            {renderWorkExperienceCard(<BankOutlined/>,"2009 - 2015","ARGO Data","Programmer Analyst","ARGO's primary focus is developing and integrating software for companies in the financial services and healthcare industry. I led an itegration team for several projects and worked closely with my clients to customize the software to their needs.")}
            {renderWorkExperienceCard(<FlagOutlined/>,"Summer 2008","Pine Cove Christian Camps","High School Counselor","I led groups of high school students throughout the summer as a camp counselor. I taught activities and had intentional conversations with students, sharing the hope of Jesus Christ with them.")}
            {renderWorkExperienceCard(<TeamOutlined/>,"Summer 2007","Baylor University - New Student Programs","Baylorbound Leader","Between my freshman and sophmore year of college, I worked for Baylor in the New Student Programs department. I welcomed freshman students by serving as a counselor at Baylor Line Camps and overseeing a group of leaders during welcome week.")}
            {renderWorkExperienceCard(<NodeIndexOutlined/>,"2002 - 2010","Balloons by Craig","Owner / Balloon Artist","I learned how to make balloon sculptures at an early age and created a business out of it to make extra money. People would hire me to make balloons for birthday parties, carnivals, church events, and city-wide festivals.")}
            {renderWorkExperienceCard(<HomeOutlined/>,"2002 - 2010","Jump Time","Owner / Bounce House Specialist","I bought a bounce house when I was 15 and rented it out for birthday parties and other special events.")}
          </Row>
        </FloatingContainer>
      </SectionContainer>

      <SectionContainer className="bg-white" verticalPadding={60}>
        <FloatingContainer maxWidth={800}>
          <div className="text-center">
            <FadeUpWhileInView className="fs-24 fw-700 c-text-gray uppercase text-center mb-30">Skills</FadeUpWhileInView>
            <FadeUpWhileInView>
              {renderSkills()}
            </FadeUpWhileInView>
          </div>
        </FloatingContainer>
      </SectionContainer>
    </div>
  );
}

export default AboutPage;
