import React, {useState, useEffect} from "react";
import { useNavigate, Link } from "react-router-dom";
import moment from "moment";
import { Row, Col, Table, Tag, Progress, Checkbox, Input, InputNumber} from 'antd';
import NumberFormat from 'react-number-format';
import SectionContainer from '../../../components/sectionContainer'
import FloatingContainer from '../../../components/floatingContainer'
import { ArrowLeftOutlined } from '@ant-design/icons';

const BudgetPage = () => {

  const dayOfMonth = moment().format("D");
  const daysInMonth = moment().daysInMonth();
  const percentageThroughMonth = (dayOfMonth / daysInMonth * 100).toFixed()

  var expensesData = [
    { name: "Mortgage", amount: 1731.44, day: 1, occured: false, essential: false },
    { name: "Additional Principal", amount: 600.00, day: 1, occured: false, essential: false },
    { name: "HOA", amount: 237.50, day: 2, occured: false, essential: false },
    { name: "Gas (House)", amount: 30.00, day: 7, occured: false, essential: false },
    { name: "Water", amount: 75.00, day: 14, occured: false, essential: false },
    { name: "Electricity", amount: 130.00, day: 28, occured: false, essential: false },
    { name: "Gas (Cars)", amount: 150.00, day: 0, occured: false, essential: false },
    { name: "Tolls", amount: 40.00, day: 0, occured: false, essential: false },
    { name: "Auto Insurance", amount: 153.19, day: 10, occured: false, essential: false },
    { name: "Car Maintenance", amount: 60.00, day: 0, occured: false, essential: false },
    { name: "Groceries", amount: 800.00, day: 0, occured: false, essential: false },
    { name: "Dining Out", amount: 300.00, day: 0, occured: false, essential: false },
    { name: "Tithe #1", amount: 500.00, day: 2, occured: false, essential: false },
    { name: "Tithe #2", amount: 500.00, day: 16, occured: false, essential: false },
    { name: "Missions", amount: 100.00, day: 27, occured: false, essential: false },
    { name: "Compassion International #1", amount: 38.00, day: 2, occured: false, essential: false },
    { name: "Compassion International #2", amount: 38.00, day: 6, occured: false, essential: false },
    { name: "Gifts", amount: 50.00, day: 0, occured: false, essential: false },
    { name: "Cell Phones", amount: 90.00, day: 27, occured: false, essential: false },
    { name: "Internet", amount: 70.12, day: 27, occured: false, essential: false },
    { name: "Spotify", amount: 10.81, day: 14, occured: false, essential: false },
    { name: "Peacock TV", amount: 5.40, day: 4, occured: false, essential: false },
    { name: "HBO Max", amount: 10.81, day: 4, occured: false, essential: false },
    { name: "Craig's Fun Money", amount: 150.00, day: 0, occured: false, essential: false },
    { name: "Morgan's Fun Money", amount: 150.00, day: 0, occured: false, essential: false },
    { name: "Date Night", amount: 100.00, day: 0, occured: false, essential: false },
    { name: "Gym", amount: 95.00, day: 0, occured: false, essential: false },
    { name: "Life Insurance", amount: 69.64, day: 1, occured: false, essential: false },
    { name: "Craig's Haircut", amount: 28.00, day: 0, occured: false, essential: false },
    { name: "Baby Health", amount: 25.00, day: 0, occured: false, essential: false },
    { name: "Baby Supplies", amount: 150.00, day: 0, occured: false, essential: false },
    { name: "Jeweler's Mutual", amount: 8.00, day: 0, occured: false, essential: false },
    { name: "Dallas Arboretum", amount: 8.00, day: 0, occured: false, essential: false },
    { name: "Phone", amount: 60.00, day: 28, occured: false, essential: false },
    { name: "Car Fund", amount: 500.00, day: 28, occured: false, essential: false },
    { name: "College Fund", amount: 150.00, day: 28, occured: false, essential: false },
  ];
  expensesData.forEach((e,i) => {
    e.key = i + 1;
  })

  const incomeData = [
    { name: "Craig's Paycheck #1", amount: 3962.20, day: 15, occured: false },
    { name: "Craig's Paychec #2", amount: 3962.20, day: 30, occured: false  },
    { name: "Morgan's Paycheck #1", amount: 1587.26, day: 11, occured: false },
    { name: "Morgan's Paycheck #2", amount: 1587.26, day: 25, occured: false }
  ]
  incomeData.forEach((e,i) => {
    e.key = i + 1;
  })

  const [expenses, setExpenses] = useState(expensesData);
  const [income, setIncome] = useState(incomeData);
  const [checkingAccountAmount, setCheckingAccountAmount] = useState(14892.59);
  const [creditCardLimit, setCreditCardLimit] = useState(15000);
  const [creditCardAvailableBalance, setCreditCardAvailableBalance] = useState(9297.96);
  const [carryOverAmount, setCarryOverAmount] = useState(6000);

  useEffect(() => {
    window.scrollTo(0, 0)
    document.title = "Craig Smith | Budget";
  }, [])

  // Expenses
  const getTotalExpenses = () => {
    var total = 0;
    expenses.forEach(expense => {
      total += expense.amount;
    })
    return total;
  }

  const getTotalOccuredExpenses = () => {
    var total = 0;
    expenses.forEach(expense => {
      total += getOccurredExpenses(expense.key) ? expense.amount : 0;
    })
    return total;
  }

  const getRemainingExpenses = () => {
    var total = 0;
    expenses.forEach(expense => {
      total += !getOccurredExpenses(expense.key) ? expense.amount : 0;
    })
    return total;
  }

  const getEssentialExpenses = () => {
    var total = 0;
    expenses.forEach(expense => {
      total += expense.essential ? expense.amount : 0;
    })
    return total;
  }
  
  // Income
  const getTotalIncome = () => {
    var total = 0;
    income.forEach(i => {
      total += i.amount;
    })
    return total;
  }

  const getTotalOccuredIncome = () => {
    var total = 0;
    income.forEach(i => {
      total += getOccurredIncome(i.key) ? i.amount : 0;
    })
    return total;
  }

  const getRemainingIncome = () => {
    var total = 0;
    income.forEach(i => {
      total += !getOccurredIncome(i.key) ? i.amount : 0;
    })
    return total;
  }

  const getLeftoverAmount = () => {
    var total = 0;
    total = checkingAccountAmount - (creditCardLimit - creditCardAvailableBalance) - carryOverAmount - getRemainingExpenses() + getRemainingIncome();
    return total;
  }

  const getOccurredExpenses = (key) => {
    const expense = expenses.find(x => x.key === key);
    return expense.occured;
  }

  const setOccuredExpenses = (key) => {
    var newArray = [...expenses];
    newArray.forEach(x => {
      if (x.key === key) {
        x.occured = !x.occured
      }
    });
    setExpenses(newArray);
  }

  const getEssentialExpense = (key) => {
    const expense = expenses.find(x => x.key === key);
    return expense.essential;
  }

  const setEssentialExpense = (key) => {
    var newArray = [...expenses];
    newArray.forEach(x => {
      if (x.key === key) {
        x.essential = !x.essential
      }
    });
    setExpenses(newArray);
  }

  const getOccurredIncome = (key) => {
    const item = income.find(i => i.key === key);
    return item.occured;
  }

  const setOccuredIncome = (key) => {
    var newArray = [...income];
    newArray.forEach(x => {
      if (x.key === key) {
        x.occured = !x.occured
      }
    });
    setIncome(newArray);
  }

  const renderAmount = (number) => {
    return (
      <div className="text-right">
        <NumberFormat value={number} displayType={'text'} thousandSeparator={true} prefix={'$ '} decimalScale={2} fixedDecimalScale={true}/>
      </div>
    )
  }

  const renderOccuredExpenses = (x) => {
    return (
      <div className="text-center">
        <Checkbox checked={getOccurredExpenses(x.key)} onChange={() => setOccuredExpenses(x.key)}></Checkbox>
      </div>
    )
  }

  const renderOccuredIncome = (x) => {
    return (
      <div className="text-center">
        <Checkbox checked={getOccurredIncome(x.key)} onChange={() => setOccuredIncome(x.key)}></Checkbox>
      </div>
    )
  }

  const renderEssential = (x) => {
    return (
      <div className="text-center">
        <Checkbox checked={getEssentialExpense(x.key)} onChange={() => setEssentialExpense(x.key)}></Checkbox>
      </div>
    )
  }

  const renderDay = (day) => {
    const optionalClass = day < dayOfMonth ? "c-blue fw-500" : "";
    return (
      <div className={`text-center ${optionalClass}`}>
        { day }
      </div>
    )
  }

  const renderCurrency = (number) => {
    return <NumberFormat value={number} displayType={'text'} thousandSeparator={true} prefix={'$ '} decimalScale={2} fixedDecimalScale={true}/>;
  }

  const renderCurrencyTextbox = (number, onChange) => {
    return (
      <InputNumber 
          style={{ width: '200px'}}
          size="large"
          value={number}
          onChange={(value) => onChange(value)}
          precision={2}
          formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={value => value.replace(/\$\s?|(,*)/g, '')}
          />
    )
  }

  const renderLeftoverAmount = () => {
    const number = checkingAccountAmount - carryOverAmount - (creditCardLimit - creditCardAvailableBalance)
    return (
      <div className="fw-700 fs-16">
        <NumberFormat value={number} displayType={'text'} thousandSeparator={true} prefix={'$ '} decimalScale={2} fixedDecimalScale={true}/>
      </div>
    )
  }

  const incomeColumns = [
    { title: 'Item', dataIndex: 'name', key: 'name' },
    { title: 'Day of Month', dataIndex: 'day', key: 'day', width: "150px", render: (x) => renderDay(x), defaultSortOrder: 'ascend', sorter: (a, b) => a.day - b.day},
    { title: 'Occured?', dataIndex: 'occured', key: 'occured', width: "100px", render: (x,i) => renderOccuredIncome(i) },
    { title: 'Amount', dataIndex: 'amount', key: 'amount', width: "130px", render: (x) => renderAmount(x) }
  ];

  const expenseColumns = [
    { title: 'Item', dataIndex: 'name', key: 'name' },
    { title: 'Day of Month', dataIndex: 'day', key: 'day', width: "150px", render: (x) => renderDay(x), defaultSortOrder: 'ascend', sorter: (a, b) => a.day - b.day},
    { title: 'Essential?', dataIndex: 'essential', key: 'essential', width: "100px", render: (x,i) => renderEssential(i) },
    { title: 'Occured?', dataIndex: 'occured', key: 'occured', width: "100px", render: (x,i) => renderOccuredExpenses(i) },
    { title: 'Amount', dataIndex: 'amount', key: 'amount', width: "130px", render: (x) => renderAmount(x) }
  ];

  return (
    <div>
      <SectionContainer className="bg-blue-gradient" verticalPadding={40}>
        <div className="page-header--title">Budget</div>
        <div className="page-header--breadcrumb"><ArrowLeftOutlined/> <Link to="/admin/dashboard">Dashboad</Link></div>
      </SectionContainer>

      <SectionContainer verticalPadding={60}>
        <FloatingContainer maxWidth={1000}>
          <div className="fs-24 fw-700 mb-10 ml-10">Amounts</div>
          <div className="shadow-box p-20">
            <Row gutter={[30,30]}>
              <Col xs={12}>
                <div>Checking Account</div>
                {renderCurrencyTextbox(checkingAccountAmount, setCheckingAccountAmount)}
              </Col>
              <Col xs={12}>
                <div>Credit Card Limit</div>
                {renderCurrencyTextbox(creditCardLimit, setCreditCardLimit)}
              </Col>
              <Col xs={12}>
                <div>Carry Over Amount</div>
                {renderCurrencyTextbox(carryOverAmount, setCarryOverAmount)}
              </Col>
              <Col xs={12}>
                <div>Credit Card Available Balance</div>
                {renderCurrencyTextbox(creditCardAvailableBalance, setCreditCardAvailableBalance)}
              </Col>
              <Col xs={24}>
                <div>Remaining</div>
                {renderLeftoverAmount()}
              </Col>
            </Row>
          </div>
        </FloatingContainer>
      </SectionContainer>

      <SectionContainer verticalPadding={0}>
        <FloatingContainer maxWidth={1000}>
          <div className="fs-24 fw-700 mb-10 ml-10">Monthly Progress <span className="fw-300 c-text-gray">(Day {dayOfMonth})</span></div>
          <div className="shadow-box p-10">
            <Progress percent={percentageThroughMonth} />
          </div>
          <div className="fs-24 fw-700 mb-10 ml-10 mt-30">Income</div>
          <Table
            bordered
            className=""
            columns={incomeColumns}
            dataSource={income}
            pagination={false}
          />
          <div className="text-right p-10 fs-16">
            <span className="fw-700 mr-10">Total: </span>
            <span className="c-text-gray">{ renderCurrency(getTotalOccuredIncome()) } / </span>
            <span className="">{ renderCurrency(getTotalIncome()) }</span>
          </div>
          <div className="fs-24 fw-700 mb-10 ml-10 mt-30">Expenses</div>
          <Table
            bordered
            className=""
            columns={expenseColumns}
            dataSource={expenses}
            pagination={false}
          />
          <div className="text-right p-10 fs-16">
            <span className="fw-700 mr-10">Total: </span>
            <span className="c-text-gray">{ renderCurrency(getTotalOccuredExpenses()) } / </span>
            <span className="">{ renderCurrency(getTotalExpenses()) }</span>
          </div>
        </FloatingContainer>
      </SectionContainer>

      <SectionContainer verticalPadding={60}>
        <FloatingContainer maxWidth={1000}>
          <div className="shadow-box p-30">
            <Row gutter={[30,30]}>
              <Col xs={12} className="text-center">
                <div className="c-text-gray uppercase">Essential Expenses</div>
                <div className="fs-32">{ renderCurrency(getEssentialExpenses()) }</div>
              </Col>
              <Col xs={12} className="text-center">
                <div className="c-text-gray uppercase">Leftover Amount</div>
                <div className="fs-32">{ renderCurrency(getLeftoverAmount()) }</div>
              </Col>
            </Row>
          </div>
        </FloatingContainer>
      </SectionContainer>
    </div>
  );
}

export default BudgetPage;
