import React from 'react';

const SectionContainer = props => {
  const verticalPadding = props.verticalPadding ?? 0;
  return (
    <div className={`section-container ${props.className ?? ""}`} style={{ paddingTop: verticalPadding, paddingBottom: verticalPadding }}>
      <div className="section-container--inner">
        { props.children }
      </div>
    </div>
  );
};

export default SectionContainer;
