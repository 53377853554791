import React from "react";
import { Route, Navigate, Routes } from "react-router-dom";
import SiteLayout from './../components/siteLayout'
import AdminLayout from './../components/adminLayout'
import ProtectedRoute from './../components/protectedRoute'

// Main
import HomePage from "../pages/public/home";
import ResourcesPage from "../pages/public/resources";
import FinancesPage from "../pages/public/finances";
import FaithPage from "../pages/public/faith";
import SoftwarePage from "../pages/public/software";
import WoodworkingPage from "../pages/public/woodworking";
import MusicPage from "../pages/public/music";
import AboutPage from "../pages/public/about";
import ContactPage from "../pages/public/contact";
import LoginPage from "../pages/public/login";
import LogoutPage from "../pages/admin/logout";

// Admin
import DashboardPage from "../pages/admin/dashboard";
import TripEditorPage from "../pages/admin/travel/trip";
import TripsPage from "../pages/admin/travel/trips";
import VersePage from "../pages/admin/faith/verse";
import VersesPage from "../pages/admin/faith/verses";
import BookKeepingPage from "../pages/admin/finances/bookkeeping";
import BudgetPage from "../pages/admin/finances/budget";
import InvestmentBreakdownPage from "../pages/admin/finances/investment-breakdown";
import BlogPostsPage from "../pages/admin/blog/posts";
import BlogPostEditorPage from "../pages/admin/blog/post";

// Finances
import NetWorthProjectorPage from "../pages/public/finances/netWorthProjector";
import MortgageCalculatorPage from "../pages/public/finances/mortgageCalculator";
import WhatDoesTheBibleSay from "../pages/public/finances/whatDoesTheBibleSay";
import InvestmentCalculatorPage from "../pages/public/finances/investmentCalculator";
import IncomeTaxCalculator from "../pages/public/finances/incomeTaxCalculator";

// Faith
import MyTestimonyPage from "../pages/public/faith/myTestimony";
import MemoryVersesPage from "../pages/public/faith/memoryVerses";
import FlashcardPage from "../pages/public/faith/flashcard";

// Software
import GitDocumentationPage from "../pages/gitDocumentationPage";

// Travel
import TravelPage from "../pages/public/travel";
import TripPage from "../pages/public/travel/trip";

// Blog
import PostPage from "../pages/public/blog/post";
import BlogCategoryPage from "../pages/public/blog/category";

import TimelinePage from "../pages/public/timeline";
import TimelineEditorPage from "../pages/public/timeline-editor";

const PageRoutes = (props) => {

  const renderRoutes = () => {
    return (
      <Routes>
        <Route element={<SiteLayout/>}>
          <Route path="/" element={<HomePage/>} />
          <Route path="about" element={<AboutPage/>} />
          <Route path="resources" element={<ResourcesPage/>} />
          <Route path="contact" element={<ContactPage/>} />
          <Route path="login" element={<LoginPage/>} />

          <Route path="/travel/:tripKey" element={<TripPage/>} />
          <Route path="/travel" element={<TravelPage/>} />
          <Route path="/finances" element={<FinancesPage/>} />
          <Route path="/faith" element={<FaithPage/>} />
          <Route path="/software" element={<SoftwarePage/>} />
          <Route path="/woodworking" element={<WoodworkingPage/>} />
          <Route path="/music" element={<MusicPage/>} />
          <Route path="/blog/:post_category_key" element={<BlogCategoryPage/>} />
          <Route path="/blog/:post_category_key/:post_key" element={<PostPage/>} />

          <Route path="/finances/net-worth-projector" element={<NetWorthProjectorPage/>} />
          <Route path="/finances/mortgage-calculator" element={<MortgageCalculatorPage/>} />
          <Route path="/finances/what-does-the-bible-say" element={<WhatDoesTheBibleSay/>} />
          <Route path="/finances/investment-calculator" element={<InvestmentCalculatorPage/>} />
          <Route path="/finances/income-tax-calculator" element={<IncomeTaxCalculator/>} />

          <Route path="/faith/my-testimony" element={<MyTestimonyPage/>} />
          <Route path="/faith/memory-verses" element={<MemoryVersesPage/>} />

          <Route path="git" element={<GitDocumentationPage/>} />
        </Route>
        <Route element={<ProtectedRoute/>}>
          <Route element={<AdminLayout/>}>
            <Route path="logout" element={<LogoutPage/>} />
            <Route path="/admin/dashboard" element={<DashboardPage/>} />
            <Route path="/admin/travel/trips" element={<TripsPage/>} />
            <Route path="/admin/travel/trip/:trip_id" element={<TripEditorPage/>} />
            <Route path="/admin/faith/verses" element={<VersesPage/>} />
            <Route path="/admin/faith/verses/:verse_id" element={<VersePage/>} />
            <Route path="/admin/finances/bookkeeping" element={<BookKeepingPage/>} />
            <Route path="/admin/finances/budget" element={<BudgetPage/>} />
            <Route path="/admin/finances/investment-breakdown" element={<InvestmentBreakdownPage/>} />
            <Route path="/admin/blog/posts" element={<BlogPostsPage/>} />
            <Route path="/admin/blog/post/:post_id" element={<BlogPostEditorPage/>} />
          </Route>
        </Route>
        <Route>
          <Route path="/timeline" element={<TimelinePage/>} />
          <Route path="/timeline-editor" element={<TimelineEditorPage/>} />
        </Route>
        <Route>
          <Route path="/flashcard" element={<FlashcardPage/>} />
        </Route>

        <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
    )
  }

  return renderRoutes();
}

export default PageRoutes;
