import React, { useState } from 'react';
import { Layout, Row, Col } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { IoCloseOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import NavMenu from './navMenu';
import MobileNavMenu from './mobileNavMenu';

const ModalHeader = props => {
  const { title, onClose } = props;

  return (
    <Row className="modal-header hidden-print" justify="middle">
      <Col flex="auto">
        <div className="modal-header--title">{ title }</div>
      </Col>
      <Col flex="50px" className="text-right">
        <div className="modal-header--icon" onClick={onClose}><IoCloseOutline/></div>
      </Col>
    </Row>
  );
};

export default ModalHeader;
