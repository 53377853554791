import React, { useMemo } from 'react';
import ReactGA from 'react-ga';
import { BrowserRouter } from 'react-router-dom';
import Routes from './routes';
import history from './history';
import AppContext from './context';
import usePersistedStateObject from '../hooks/usePersistedStateObject';
import StorageKeys from '../constants/storageKeys';
import RouteChangeTracker from '../components/routeChangeTracker'

// Initialize Google Analytics
if (process.env.NODE_ENV !== "development") {
  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
}

const App = () => {

  const [auth, setAuth] = usePersistedStateObject(StorageKeys.AUTH);

  const value = useMemo(() => ({
    auth,
    setAuth
  }), [auth])

  return (
    <AppContext.Provider value={value}>
      <BrowserRouter history={history}>
        <Routes/>
        <RouteChangeTracker/>
      </BrowserRouter>
    </AppContext.Provider>
  );
};

export default App;
