export default {
  clone: `git clone repo`,
  commitToEmptyRepository: 
`git remote add origin <url>
git push -u origin master`,
  revertUnstaged: 
`git clean -df
git checkout -- .`,
  abortMerge: `git merge -abort`,
  ammendLastCommitMessage: `git commit --amend -m “New commit message"`
}