import React, { useState } from 'react';
import { Layout, Drawer } from 'antd';
import { motion } from 'framer-motion'
import { MenuOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import NavMenu from './navMenu';
import MobileNavMenu from './mobileNavMenu';

const Header = props => {
  const [visible, setVisible] = useState(false);

  return (
    <Layout.Header className="site-header">
      <Link to="/" className="logo">
        <motion.span 
          className="fs-20 fw-800 uppercase c-text"
          initial="hidden"
          animate="visible"
          transition={ { duration: 1 } }
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 }
          }}
        >Craig</motion.span>
        <motion.span 
          className="fs-20 fw-400 uppercase c-text-gray"
          initial="hidden"
          animate="visible"
          transition={ { duration: 1, delay: 0.5 } }
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 }
          }}
        >Smith</motion.span>
      </Link>
      <NavMenu/>
      <div className="menu-icon">
        <button onClick={() => setVisible(true)}><MenuOutlined/></button>
      </div>
      <Drawer
        placement="right"
        closable={false}
        onClose={() => setVisible(false)}
        visible={visible}
        bodyStyle={{ padding: 0 }}
        width={300}
      >
        <MobileNavMenu onClose={() => setVisible(false)}/>
      </Drawer>
    </Layout.Header>
  );
};

export default Header;
