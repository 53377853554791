import React from 'react';
import { Outlet } from "react-router-dom";
import { Layout } from 'antd';
import SiteHeader from './header';
import SiteFooter from './siteFooter';

const { Content } = Layout;

const SiteLayout = () => {
  return (
    <Layout className="site-layout">
      <SiteHeader/>
      <Content className="site-content">
        <Outlet/>
      </Content>
      <SiteFooter/>
    </Layout>
  );
};

export default SiteLayout;
