import React, { useContext } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { Menu, Dropdown } from 'antd';
import { motion } from 'framer-motion'
import { isFunction, startsWith } from 'lodash';
import AppContext from '../app/context';

const NavMenu = (props) => {
  const { onClose } = props;

  const { auth, setAuth } = useContext(AppContext);
  let location = useLocation();
  let navigate = useNavigate();

  const onMenuClick = (key) => {
    isFunction(onClose) && onClose();
    navigate(key);
  }

  const isSelected = (path) => {
    if (location.pathname != "/" && path == "/") {
      return "";
    }
    return startsWith(location.pathname, path) ? "item-selected" : "";
  }

  const isSubMenuSelected = (path) => {
    if (location.pathname != "/" && path == "/") {
      return "";
    }
    return startsWith(location.pathname, path) ? "item-selected" : "";
  }

  const resourcesMenu = (
    <Menu>
      <Menu.Item key="finances">
        <div onClick={() => navigate("/finances")} className={isSubMenuSelected("/finances")}>
          Finances
        </div>
      </Menu.Item>
      <Menu.Item key="travel">
        <div onClick={() => navigate("/travel")} className={isSubMenuSelected("/travel")}>
          Travel
        </div>
      </Menu.Item>
      <Menu.Item key="faith">
        <div onClick={() => navigate("/faith")} className={isSubMenuSelected("/faith")}>
          Faith
        </div>
      </Menu.Item>
      <Menu.Item key="woodworking">
        <div onClick={() => navigate("/woodworking")} className={isSubMenuSelected("/woodworking")}>
          Woodworking
        </div>
      </Menu.Item>
    </Menu>
  )

  const blogMenu = (
    <Menu>
      <Menu.Item key="blog-travel">
        <div onClick={() => navigate("/blog/travel")} className={isSubMenuSelected("/blog/travel")}>
          Travel
        </div>
      </Menu.Item>
      <Menu.Item key="blog-woodworking">
        <div onClick={() => navigate("/blog/woodworking")} className={isSubMenuSelected("/blog/woodworking")}>
          Woodworking
        </div>
      </Menu.Item>
    </Menu>
  )


  const siteItems = [
    { title: "Home", path: "/", type: "menu"},
    { title: "About", path: "/about", type: "menu"},
    { title: "Passions", path: "/resources", type: "submenu", submenu: resourcesMenu},
    { title: "Blog", path: "/blog", type: "submenu", submenu: blogMenu},
    // { title: "Contact", path: "/contact", type: "menu"}
  ]

  const adminItems = [
    { title: "Dashboard", path: "/admin/dashboard", type: "menu"},
    { title: "Logout", path: "/logout", type: "menu"}
  ]

  const renderNavItem = (item) => {
    if (item.type === "submenu") {
      return (
        <Dropdown overlay={item.submenu} placement="bottomLeft" key={item.path}>
          <div className={`site-nav--item ${isSelected(item.path)}`}>
            { item.title }
          </div>
        </Dropdown>
      )
    } else {
      return (
        <div className={`site-nav--item ${isSelected(item.path)}`} onClick={e => onMenuClick(item.path)} key={item.path}>
          { item.title }
        </div>
      )
    }
  }

  const renderNavItems = () => {
    if (auth.isAuthenticated) {
      return adminItems.map(item => renderNavItem(item))
    } else {
      return siteItems.map(item => renderNavItem(item))
    }
  }

  return (
    <motion.div 
      className="site-nav"
      initial="hidden"
      animate="visible"
      transition={ { duration: 1 } }
      variants={{
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: -100 }
      }}
    >
      { renderNavItems() }
    </motion.div>
  );
}

export default NavMenu;
