import React, {useState, useEffect} from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import moment from 'moment';
import { isEmpty } from 'lodash';
import SectionContainer from '../../../components/sectionContainer'
import FloatingContainer from '../../../components/floatingContainer'
import LoadingSpinner from '../../../components/loadingSpinner'
import { getTrip } from '../../../api'

const TripPage = () => {

  let params = useParams();
  let navigate = useNavigate();
  const [trip, setTrip] = useState({});
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0)
    document.title = "Craig Smith | Travel";
    fetchTrip()
  }, [])

  const fetchTrip = () => {
    getTrip(params.tripKey).then(response => {
      if (isEmpty(response.data)) {
        navigate("/travel")
      }
      setTrip(response.data);
      setLoading(false)
    }).catch((e) => setLoading(false))
  }

  if (isLoading) {
    return <LoadingSpinner/>
  }

  return (
    <div className="trip-page">
      <SectionContainer className="bg-white" verticalPadding={60}>
        <FloatingContainer maxWidth={1000} className="text-center">
          <div className="post-tag large" onClick={() => navigate('/travel')}>Travel</div>
          <div className="trip-name mt-20">{ trip.trip_name }</div>
          <div className="fs-16 mt-10 c-text-gray">{ moment.utc(trip.start_date).format("MMMM DD, YYYY") }</div>
        </FloatingContainer>
      </SectionContainer>
      <SectionContainer className="bg-white" verticalPadding={0}>
        <FloatingContainer maxWidth={1000}>
          <img src={trip.trip_image} style={{ width: '100%'}}/>
          <div className="text-center mt-60"><span style={{ border: '2px solid #4871f7', width: 60, display: 'inline-block'}}></span></div>
        </FloatingContainer>
      </SectionContainer>
      <SectionContainer verticalPadding={60} className="bg-white trip-content">
        <FloatingContainer maxWidth={800}>
          {/* <div className="shadow-box p-30 mb-30"> */}
            <div dangerouslySetInnerHTML={{__html: trip.content}} />
          {/* </div> */}
        </FloatingContainer>
      </SectionContainer>
    </div>
  );
}

export default TripPage;
