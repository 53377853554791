import React from 'react';
import { Outlet, useNavigate } from "react-router-dom";
import { Layout, Menu } from 'antd';
import { ArrowLeftOutlined, HomeOutlined, CarOutlined, ReadOutlined, AuditOutlined, LineChartOutlined } from '@ant-design/icons';

const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;

const AdminLayout = () => {

  let navigate = useNavigate();

  return (
    <Layout style={{ minHeight: '100vh'}} className="admin-layout">
        <Header className="admin-header" style={{ position: 'fixed', zIndex: 5, width: '100%' }}>
          <div className="logo" />
        </Header>
        <Layout>
          <Sider 
            width={200}
            style={{
              overflow: 'auto',
              height: '100vh',
              position: 'fixed',
              left: 0,
              top: 0,
              bottom: 0,
              paddingTop: 64
            }}>
            <Menu
              mode="inline"
              defaultSelectedKeys={[]}
              defaultOpenKeys={[]}
              style={{ height: '100%', borderRight: 0 }}
            >
              <Menu.Item key="dashboard" icon={<HomeOutlined />} onClick={() => navigate("/admin/dashboard")}>Dashboard</Menu.Item>
              <SubMenu key="sub1" icon={<ReadOutlined />} title="Verses">
                <Menu.Item key="sub1-1" onClick={() => navigate("/admin/faith/verses")}>Manage Verses</Menu.Item>
                <Menu.Item key="sub1-2" onClick={() => navigate("/admin/faith/verses/new")}>New Verse</Menu.Item>
              </SubMenu>
              <SubMenu key="sub2" icon={<CarOutlined />} title="Trips">
                <Menu.Item key="sub2-1" onClick={() => navigate("/admin/travel/trips")}>Manage Trips</Menu.Item>
                <Menu.Item key="sub2-2" onClick={() => navigate("/admin/travel/trip/new")}>New Trip</Menu.Item>
              </SubMenu>
              <SubMenu key="sub3" icon={<AuditOutlined />} title="Bookkeeping">
                <Menu.Item key="sub3-1" onClick={() => navigate("/admin/finances/bookkeeping")}>Register</Menu.Item>
              </SubMenu>
              <SubMenu key="sub4" icon={<LineChartOutlined />} title="Investments">
                <Menu.Item key="sub4-1" onClick={() => navigate("/admin/finances/investment-breakdown")}>Breakdown</Menu.Item>
              </SubMenu>
              <Menu.Item key="home" icon={<ArrowLeftOutlined />} onClick={() => navigate("/")}>Back to Site</Menu.Item>
            </Menu>
          </Sider>
          <Layout style={{ marginLeft: 200 }}>
            <Content
              className="site-layout-background"
              style={{
                backgroundColor: '#f7f7f7',
                margin: 0,
                minHeight: 280,
                paddingTop: 64
              }}
            >
              <Outlet/>
            </Content>
          </Layout>
        </Layout>
      </Layout>
  );
};

export default AdminLayout;
