import React, {useState, useEffect} from "react";
import { Row, Col} from 'antd';
import SectionContainer from '../../components/sectionContainer'
import FloatingContainer from '../../components/floatingContainer'

const SoftwarePage = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
    document.title = "Craig Smith | Software";
  }, [])

  return (
    <div>
      <SectionContainer className="page-section-header">
        <div className="page-section-title">Software</div>
        <span className="page-section-divider"></span>
        <FloatingContainer maxWidth={800}>
          <div className="page-section-subtitle">Coming soon!</div>
        </FloatingContainer>
      </SectionContainer>
      <SectionContainer className="bg-gray" verticalPadding={0}>

      </SectionContainer>
    </div>
  );
}

export default SoftwarePage;
