import React, {useState, useEffect} from "react";
import { Row } from 'antd';
import SectionContainer from '../../components/sectionContainer'
import FloatingContainer from '../../components/floatingContainer'
import { getPosts } from '../../api'
import BlogPostCard from '../../components/blogPostCard'
import PageHeader from '../../components/pageHeader'
import SectionHeader from '../../components/sectionHeader'
import FadeDownWhileInView from '../../components/fadeDownWhileInView'

const WoodworkingPage = () => {

  const [posts, setPosts] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0)
    document.title = "Craig Smith | Woodworking";
    fetchPosts()
  }, [])

  const fetchPosts = () => {
    getPosts("woodworking").then(response => {
      const allPosts = response.data ?? [];
      const filteredPosts = allPosts.filter(x => x.published);
      setPosts(filteredPosts);
      setLoading(false)
    }).catch((e) => setLoading(false))
  }

  return (
    <div>
      <PageHeader title="Woodworking" bottomOffset={90} backTitle="Home" backPath="/"/>

      <SectionContainer className="bg-gray" verticalPadding={0}>
        <div className="page-header--box">
          <FloatingContainer maxWidth={850}>
            <FadeDownWhileInView delay={0.5} className="page-header--box-title">How it started</FadeDownWhileInView>
            <FadeDownWhileInView delay={1} className="page-header--box-text">When my wife and I moved from a one bedroom condo to a townhome in 2020, I started collecting power tools and working on projects around the house. Little did I know that woodworking would quickly turned into a passion.</FadeDownWhileInView>
          </FloatingContainer>
        </div>
      </SectionContainer>

      <SectionContainer className="" verticalPadding={70}>
        <SectionHeader title="My Projects"/>
        <FloatingContainer maxWidth={1000}>
          <Row gutter={[40,40]}>
            { posts.map((post) => <BlogPostCard post={post} key={post.post_key}/> )}
          </Row>
        </FloatingContainer>
      </SectionContainer>
    </div>
  );
}

export default WoodworkingPage;
