import React, {useState, useEffect} from "react";
import { useParams, useNavigate } from "react-router-dom";
import { isUndefined } from 'lodash';
import { Row } from 'antd';
import SectionContainer from '../../../components/sectionContainer'
import FloatingContainer from '../../../components/floatingContainer'
import LoadingSpinner from '../../../components/loadingSpinner'
import { getPosts, getPostCategories } from '../../../api'
import BlogPostCard from '../../../components/blogPostCard'

const BlogCategoryPage = () => {

  let params = useParams();
  let navigate = useNavigate();
  const [posts, setPosts] = useState([]);
  const [postCategory, setPostCategory] = useState({});
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0)
    document.title = "Craig Smith | Blog";
    fetchCategories()
  }, [])

  const fetchCategories = () => {
    const categoryParam = params.post_category_key;
    getPostCategories().then(response => {
      const category = response.data.find(x => x.post_category_key == categoryParam)
      if (isUndefined(category)) {
        navigate("/")
      } else {
        setPostCategory(category)
        fetchPosts(category)
        document.title = `Craig Smith | ${category.post_category_name}`;
      }
    })
  }

  const fetchPosts = (category) => {
    getPosts(category.post_category_key).then(response => {
      setPosts(response.data);
      setLoading(false)
    }).catch((e) => setLoading(false))
  }

  if (isLoading) {
    return <LoadingSpinner/>
  }

  return (
    <div>
      <SectionContainer verticalPadding={60}>
        <FloatingContainer maxWidth={1000}>
          <div className="fs-40 fw-700 text-center">{ postCategory.post_category_name }</div>
          <div className="fs-16 c-text-gray text-center">{ posts.length } Posts</div>
          <Row gutter={[30,30]} className="mt-60">
            { posts.map((post) => <BlogPostCard post={post} key={post.post_key}/> )}
          </Row>
        </FloatingContainer>
      </SectionContainer>
    </div>
  );
}

export default BlogCategoryPage;
