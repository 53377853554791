import React, {useState, useEffect} from "react";
import { useNavigate, Link } from "react-router-dom";
import NumberFormat from 'react-number-format';
import moment from "moment";
import { orderBy, isNull, startCase } from 'lodash';
import { Table, Input, InputNumber, Popconfirm, Form, Typography, DatePicker, Dropdown, Menu, Button } from 'antd';
import { CarOutlined, ReadOutlined } from '@ant-design/icons';
import SectionContainer from '../../../components/sectionContainer'
import FloatingContainer from '../../../components/floatingContainer'
import ButtonShadowBox from '../../../components/buttonShadowBox'
import EditableCell from './editableCell'
const originData = [];

const transactionType = {
  EXPENSE: 'expense',
  INCOME: 'income'
}

for (let i = 0; i < 5; i++) {
  originData.push({
    key: i,
    date: moment().add(i, 'day'),
    type: transactionType.EXPENSE,
    payee: '',
    account: '',
    payment: i,
    deposit: null,
    balance: null
  });
}

const BookKeepingPage = () => {

  const [form] = Form.useForm();
  const [data, setData] = useState(originData);
  const [editingKey, setEditingKey] = useState('');
  const [runningBalance, setRunningBalance] = useState(6000);

  const isEditing = (record) => record.key === editingKey;

  useEffect(() => {
    window.scrollTo(0, 0)
    sortTransactions(data)
  }, [])

  const sortTransactions = (transactionData) => {
    const sortedData = orderBy([...transactionData], 'date', 'asc');
    var balanceTotal = runningBalance + 0;
    const newData = sortedData.map((item, index) => {
      balanceTotal = calculateBalance(item, balanceTotal)
      return {
        ...item,
        key: index,
        balance: balanceTotal
      }
    })
    setData(newData.reverse())
  }

  const edit = (record) => {
    if (editingKey == record.key) {
      return
    }
    form.setFieldsValue({
      date: '',
      type: '',
      payee: '',
      account: '',
      payment: null,
      deposit: null,
      ...record
    });
    setEditingKey(record.key);
  };

  const add = (type) => {
    var newData = [...data];
    const newRecord = {
      key: (data.length),
      date: newData[0].date,
      type: type,
      payee: ``,
      account: '',
      payment: null,
      deposit: null
    };
    newData.push(newRecord)
    sortTransactions(newData)
    edit(newRecord)
  }

  const cancel = () => {
    setEditingKey('');
  };

  const remove = (key) => {
    var newData = [...data];
    sortTransactions(newData.filter(x => x.key != key))
    setEditingKey('');
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        sortTransactions(newData)
        setEditingKey('');
      } else {
        newData.push(row);
        sortTransactions(newData)
        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const addMenu = (
    <Menu>
      <Menu.Item>
        <div onClick={() => add(transactionType.EXPENSE)}>
          Expense
        </div>
      </Menu.Item>
      <Menu.Item>
        <div onClick={() => add(transactionType.INCOME)}>
          Income
        </div>
      </Menu.Item>
    </Menu>
  );

  const renderTableCell = (top, bottom) => {
    return (
      <>
        <div className="top-cell">{top}</div>
        <div className="bottom-cell">{bottom}</div>
      </>
    )
  }

  const renderTableHeaderCell= (top, bottom) => {
    return (
      <>
        <div>{top}</div>
        <div>{bottom}</div>
      </>
    )
  }

  const columns = [
    {
      title: 'DATE',
      dataIndex: 'date',
      width: '180px',
      editable: true,
      type: 'date',
      render: (text) => renderTableCell(moment(text).format("MM/DD/YYYY"),"")
    },
    {
      title: 'TYPE',
      dataIndex: 'type',
      width: '120px',
      editable: true,
      type: 'input',
      render: (text) => renderTableCell("",startCase(text))
    },
    {
      title: () => renderTableHeaderCell("PAYEE","ACCOUNT"),
      dataIndex: 'payee',
      // width: '15%',
      editable: true,
      type: 'input',
      render: (text, record) => renderTableCell(text,record.account)
    },
    {
      title: 'PAYMENT',
      dataIndex: 'payment',
      width: '160px',
      editable: true,
      type: 'number',
      render: (number) => (
        <>
          <div className="top-cell flex-right">
            <NumberFormat value={number} displayType={'text'} thousandSeparator={true} prefix={'$ '} decimalScale={2} fixedDecimalScale={true}/>
          </div>
          <div className="bottom-cell"></div>
        </>
      )
    },
    {
      title: 'DEPOSIT',
      dataIndex: 'deposit',
      width: '160px',
      editable: true,
      type: 'number',
      render: (number) => (
        <>
          <div className="top-cell flex-right">
            <NumberFormat value={number} displayType={'text'} thousandSeparator={true} prefix={'$ '} decimalScale={2} fixedDecimalScale={true}/>
          </div>
          <div className="bottom-cell"></div>
        </>
      )
    },
    {
      title: 'BALANCE',
      dataIndex: 'balance',
      width: '220px',
      editable: true,
      type: 'number',
      render: (number) => (
        <>
          <div className="top-cell flex-right">
            <NumberFormat value={number} displayType={'text'} thousandSeparator={true} prefix={'$ '} decimalScale={2} fixedDecimalScale={true}/>
          </div>
          <div className="bottom-cell"></div>
        </>
      )
    },
    // {
    //   title: 'ACTION',
    //   dataIndex: 'action',
    //   render: (_, record) => {
    //     const editable = isEditing(record);
    //     return editable ? (
    //       <div className="top-cell text-center">
    //           <button onClick={() => save(record.key)} className="small-page-button mb-5">
    //               Save
    //           </button>
    //           <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
    //             <a>Cancel</a>
    //           </Popconfirm>
    //       </div>
    //     ) : (
    //       <>
    //         <div className="top-cell text-center">
    //           <button onClick={() => edit(record)} className="small-page-button">
    //               Edit
    //           </button>
    //         </div>
    //       </>
    //     );
    //   },
    // },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.type,
        dataIndex: col.dataIndex,
        title: col.title,
        save: save,
        cancel: cancel,
        remove: remove,
        editing: isEditing(record),
      }),
    };
  });

  const calculateBalance = (item, balanceTotal) => {
    if (item.type === transactionType.EXPENSE) {
      return balanceTotal - item.payment;
    } else if (item.type === transactionType.INCOME) {
      return balanceTotal + item.deposit;
    }
    return balanceTotal;
  }

  return (
    <SectionContainer className="bookkeeping-section">
      <Dropdown overlay={addMenu} placement="bottomLeft">
        <button className="page-button mb-20">Add Transaction</button>
      </Dropdown>
      {/* <button onClick={sortRecords}>Refresh</button> */}
      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={data}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={{
            onChange: cancel,
          }}
          onRow={(record, rowIndex) => {
            return {
              onClick: event => edit(record), // click row
              onDoubleClick: event => {}, // double click row
              onContextMenu: event => {}, // right button click row
              onMouseEnter: event => {}, // mouse enter row
              onMouseLeave: event => {}, // mouse leave row
            };
          }}
        />
      </Form>
    </SectionContainer>
  );
}

export default BookKeepingPage;
