export default {
  status: {
    ACTIVE: 'active',
    INACTIVE: 'inactive',
    NOT_FOUND: 'not-found',
    NOT_VERIFIED: 'not-verified'
  },
  type: {
    ADMIN: 1,
    USER: 2
  }
}