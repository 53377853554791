import React from 'react';
import { Col } from 'antd';
import { useNavigate } from "react-router-dom";

const BlogPostCard = (props) => {
  const { post_key, post_image, post_name, description, post_category_name, post_category_key } = props.post;

  let navigate = useNavigate();

  const navigateToPost = () => {
    navigate(`/blog/${post_category_key}/${post_key}`)
  }

  return (
    <Col xs={24} sm={12} key={post_key}>
      <div className="blog-post-card">
        <img className="blog-post-card--image" src={post_image} onClick={navigateToPost}/>
        <div className="post-tag mt-20">{post_category_name}</div>
        <div className="blog-post-card--title" onClick={navigateToPost}>{post_name}</div>
        <div className="blog-post-card--description" onClick={navigateToPost}>{description}</div>
      </div>
    </Col>
  )
}

export default BlogPostCard;
