import React, {useState, useEffect} from "react";
import { Row, Col, Collapse, Tag, Popconfirm } from 'antd';
import { useNavigate, Link } from "react-router-dom";
import { isEmpty, isNull } from 'lodash';
import SectionContainer from '../../../components/sectionContainer'
import FloatingContainer from '../../../components/floatingContainer'
import LoadingSpinner from '../../../components/loadingSpinner'
import { getVerses, deleteVerse } from '../../../api'
import { ArrowLeftOutlined } from '@ant-design/icons';

const { Panel } = Collapse;

const VersesPage = () => {

  let navigate = useNavigate();

  const [verses, setVerses] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0)
    fetchVerses()
  }, [])

  const fetchVerses = () => {
    getVerses().then(response => {
      setVerses(response.data);
      setLoading(false)
    }).catch((e) => setLoading(false))
  }

  const removeVerse = (verse_id) => {
    deleteVerse(verse_id).then(response => {
      fetchVerses()
    }).catch((e) => console.log(e))
  }

  const renderFeatureTag = (verse) => {
    if (verse.featured) {
      return (
        <div style={{ paddingTop: 30, paddingRight: 30 }}>
          <Tag color="green">Featured</Tag>
        </div>
      );
    }
    return null;
  }

  const renderApplication = (verse) => {
    if (!isEmpty(verse.application)) {
      return (
        <div className="verse-box--collapse">
          <Collapse ghost>
            <Panel header="Application" key="1">
              { verse.application }
            </Panel>
          </Collapse>
        </div>
      )
    }
    return null;
  }

  const renderTags = (verse) => {
    const verseTags = verse.tags ?? "";
    if (isEmpty(verseTags)) {
      return null;
    }
    const tagData = verseTags.split(",");
    return tagData.map((tag, i) => <Tag key={i} color="geekblue">{tag}</Tag>)
  }

  const renderVerse = (verse) => {
    const endingVerse = verse.ending_verse == null || verse.starting_verse == verse.ending_verse ? "" : `-${verse.ending_verse}`
    return (
      <div className="verse-box mb-20" style={{ minHeight: 0 }} key={verse.memory_verse_id}>
        <Row>
          <Col flex="auto">
            <div className="verse-box--reference">{verse.book_name} {verse.chapter}:{verse.starting_verse}{endingVerse}</div>
          </Col>
          <Col flex="0">
            { renderFeatureTag(verse) }
          </Col>
        </Row>
        <div className="verse-box--text">{verse.verse_text}</div>
        { renderApplication(verse) }
        <Row justify="middle" className="mb-30 ph-30 mt-20">
          <Col fex="auto">
            {renderTags(verse)}
          </Col>
          <Col flex="1">
            <div className="text-right">
              <Popconfirm title="Are you sure?" onConfirm={() => removeVerse(verse.memory_verse_id)}>
                <button className="small-page-button-outline">Remove</button>
              </Popconfirm>
              <button className="small-page-button ml-10" onClick={() => navigate(`/admin/faith/verses/${verse.memory_verse_id}`)}>Edit</button>
            </div>
          </Col>
        </Row>
      </div>
    )
  }

  const renderVerses = () => {
    return verses.map((verse) => renderVerse(verse))
  }

  if (isLoading) {
    return (
      <div className="page-content bg-gray">
        <LoadingSpinner/>
      </div>
    )
  }

  return (
    <div>
      <SectionContainer className="bg-blue-gradient" verticalPadding={40}>
        <div className="page-header--title">Verses</div>
        <div className="page-header--breadcrumb"><ArrowLeftOutlined/> <Link to="/admin/dashboard">Dashboad</Link></div>
      </SectionContainer>
      <SectionContainer className="bg-gray" verticalPadding={60}>
        <FloatingContainer maxWidth={800}>
          <div className="text-right mb-30">
            <button className="page-button" onClick={() => navigate(`/admin/faith/verses/new`)}>Add Verse</button>
          </div>
          { renderVerses() }
          
        </FloatingContainer>
      </SectionContainer>
    </div>
  );
}

export default VersesPage;
