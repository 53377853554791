import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col } from 'antd';
import { HomeOutlined, LineChartOutlined, CreditCardOutlined, DollarCircleOutlined, CarOutlined, HeartOutlined, ArrowLeftOutlined, FundOutlined, CalendarOutlined, FireOutlined } from '@ant-design/icons';
import SectionContainer from '../../components/sectionContainer'
import FloatingContainer from '../../components/floatingContainer'
import PageHeader from '../../components/pageHeader'
import SectionHeader from '../../components/sectionHeader'
import HoverCard from '../../components/hoverCard'
import FadeUpWhileInView from '../../components/fadeUpWhileInView'
import FadeDownWhileInView from '../../components/fadeDownWhileInView'

const ResourcesPage = () => {

  let navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0)
    document.title = "Craig Smith | Finances";
  }, [])

  const renderPhilosphySection = (icon, title, text) => {
    return (
      <Col xs={24} md={12}>
        <Row gutter={[0,10]}>
          <Col xs={24} md={4} lg={3}>
            <FadeUpWhileInView className="finance-section-icon">
              { icon}
            </FadeUpWhileInView>
          </Col>
          <Col xs={24} md={20} lg={21}>
            <FadeUpWhileInView className="finance-section-title">
              { title}
            </FadeUpWhileInView>
            <FadeUpWhileInView className="finance-section-text">
              { text}
            </FadeUpWhileInView>
          </Col>
        </Row>
      </Col>
    )
  }

  return (
    <div>
      <PageHeader title="Finances" bottomOffset={90} backTitle="Home" backPath="/"/>
      
      <SectionContainer className="bg-gray" verticalPadding={0}>
        <div className="page-header--box">
          <FloatingContainer maxWidth={850}>
            <FadeDownWhileInView delay={0.5} className="page-header--box-title">How it started</FadeDownWhileInView>
            <FadeDownWhileInView delay={1} className="page-header--box-text">I wasn't interested in finances until I got motivated to pay off my student loans. After experiencing the freedom of being debt free, it made me realize how much of a blessing money can be if you steward it well. With influences from people like Dave Ramsey and The Money Guys, I've developed a financial philosophy that has worked really well for our family.</FadeDownWhileInView>
          </FloatingContainer>
        </div>
      </SectionContainer>

      <SectionContainer className="bg-gray" verticalPadding={70}>
        <SectionHeader title="Resources"/>
        <Row gutter={[30,30]} align="center">
          <HoverCard icon={<LineChartOutlined/>} title="Net Worth Projector" text="Find out what your potential net worth could be in the future based on your current assets and investments." path="/finances/net-worth-projector"/>
          <HoverCard icon={<HomeOutlined/>} title="Mortgage Calculator" text="Buying a new house? Calculate your monthly payment and how long it will take to pay off your mortgage if you apply extra principle." path="/finances/mortgage-calculator"/>
          <HoverCard icon={<FundOutlined/>} title="Investment Calculator" text="Find out how much your investments could be in the future by seeing multiple possible rates of return in a single view." path="/finances/investment-calculator"/>
        </Row>
      </SectionContainer>

      <SectionContainer className="bg-white" verticalPadding={70}>
        <SectionHeader title="My Financial Philosophy"/>
        <Row gutter={[30,30]} className="philosophy-section">
          { renderPhilosphySection(<DollarCircleOutlined/>, "Debt", "The goal is to get debt free (except for your mortgage) as fast as you can and keep it that way. This will free up more of your resources for giving, traveling, and investing. Plus, the feeling of not owing anyone anything is incredible. Dave Ramsey’s snowball effect is a perfect way to stay motivated to reach your goal. Line up your debts from smallest to largest and tackle them one at a time in that order.")}
          { renderPhilosphySection(<CreditCardOutlined/>, "Credit cards", "If you are disciplined enough to only use credit cards for items on your budget, they can be a useful tool. Most cards offer 1.5% or more cash back on all purchases. If you are going spend the money anyways, you might as well get some money back while you are at it. The important thing is that all credit card balances must be paid off at the end of each month. Only use them if you have money in the bank to cover the purchase.")}
          { renderPhilosphySection(<HomeOutlined/>, "Mortgage", "Although paying your house off early is a worthwhile goal, it might make more sense to focus on investing during your early years because of the power of compound interest. Some take this to the extreme and pay their house off over the full 30 years. We decided to split the difference and take out a 30 year mortgage, but pay it like a 15 year note. This gives us flexibility to stop the extra payments and have a lower monthly payment in case of an emergency.")}
          { renderPhilosphySection(<CarOutlined/>, "Vehicles", "Part of being debt free is not having a car payment. Once you have your car payed off, start a car fund where you set aside money every month to pay cash for a future car. This might seem like the same thing as a car payment, but you will feel different emotionally. Having money in the bank is a better feeling than being in debt to someone. If you aren't planning on getting a car for 5+ years, you could even throw this money in a brokerage account and invest in some index funds to make it grow faster.")}
          { renderPhilosphySection(<CalendarOutlined/>, "Paychecks", "One of the best things we have implemented as a family is the concept of living a paycheck ahead. This means having enough money in your checking account at the beginning of each month to pay for all of your upcoming monthly expenses. This allows for maximum flexibility and peace of mind knowing that you don't have to worry about what day of the month your bills are due and when your next paycheck will hit your bank account. It might take some sacrifice on the front end to save up and get this started, but it is well worth it.")}
          { renderPhilosphySection(<FireOutlined/>, "Savings", "You should have 3-6 months worth of expenses as an emergency fund in a savings account. Although some argue that this money would be better off invested, you need liquid cash to use at a moment's notice in case something goes wrong. Having (ideally) 6 months of emergency fund will give you a peace of mind in case you loose your job or have a medical crisis.")}
          { renderPhilosphySection(<LineChartOutlined/>, "Investing", "The goal is to invest at least 15% of your gross income. If your employer offers a 401k with an employer match, this is where you should start. At least contribute enough to where you get the full match. This is free money. After that, look into maxing out your 401k, Roth IRA, and HSA. If you still have money left to invest, opening up a brokerage account and buying index funds is a great option. By having this money outside of a retirement account means that you can use it before retirement age to buy real estate or start a business.")}
          { renderPhilosphySection(<HeartOutlined/>, "Giving", "One of the most rewarding things you can do with money is to give some of it away. The amount you give isn’t as important as the heart behind it, however I do believe that the amount you give tells a lot about where your heart is. Giving generously does something inside of you. It takes the focus off of yourself and allows you to bless other people. I'm not talking about giving to the detriment of your family, but enough to where it's actually a sacrifice. For us, this number was 10% of our income split between our local church, missions, and other ministries.")}
        </Row>
      </SectionContainer>

      <SectionContainer className="bg-gray" verticalPadding={70}>
        <FloatingContainer maxWidth={800}>
          <SectionHeader title="What does the Bible say about money?"/>
          <FadeUpWhileInView className="fs-18 c-text-gray text-center">
            The bible talks a lot about money, probably because God knows it is something we can easily obsess over. If money isn't handled properly, it can lead to anxiety, stress on marriages, and materialism. Since God created us and gave us the ability to make money, I think it's important to hear what He says about the topic.
          </FadeUpWhileInView>
          <FadeUpWhileInView className="text-center mt-30">
            <button className="page-button" onClick={() => navigate("/finances/what-does-the-bible-say")}>Learn More</button>
          </FadeUpWhileInView>
        </FloatingContainer>
      </SectionContainer>
    </div>
  );
}

export default ResourcesPage;
