import React, {useState, useEffect, useRef} from "react";
import NumberFormat from 'react-number-format';
import moment from 'moment';
import { maxBy } from 'lodash';
import { Row, Col, Table, Form, Checkbox} from 'antd';
import { renderCurrencyField, renderDateField, renderPercentField, renderInputField, renderFormLabel } from "../../../components/formFields";
import SectionContainer from '../../../components/sectionContainer'
import FloatingContainer from '../../../components/floatingContainer'
import PageHeader from '../../../components/pageHeader'

const NetWorthProjectorPage = () => {

  const [investments, setInvestments] = useState([{id: 0}])
  const [otherAssets, setOtherAssets] = useState([{id: 0}])
  const [tableData, setTableData] = useState([])
  const [columns, setColumns] = useState([])
  const [includeHomeEquity, setIncludeHomeEquity] = useState(false)
  const [form] = Form.useForm();

  const summaryRef = useRef()

  useEffect(() => {
    window.scrollTo(0, 0)

    // var intitialValues = {
    //   'birthday': moment('1987-06-20'),
    //   'investment_name_0': "Craig's 401K",
    //   'monthly_contribution_0': 1550,
    //   'todays_amount_0': 95000,
    //   'rate_of_return_0': 8,
    //   'investment_name_1': "Morgan's 401K",
    //   'monthly_contribution_1': 836,
    //   'todays_amount_1': 42000,
    //   'rate_of_return_1': 8,
    //   'home_value': 335000,
    //   'mortgage_balance': 251300,
    //   'interest_rate': 2.875,
    //   'monthly_payment': 1112.58,
    //   'extra_principal': 600
    // }

    // form.setFieldsValue(intitialValues)
  }, [])

  const addInvestment = () => {
    var newInvestments = [].concat(investments);
    var lastInvestment = maxBy(newInvestments, 'id');
    newInvestments.push({
      id: lastInvestment.id + 1
    })
    setInvestments(newInvestments)
  }

  const removeInvestment = (id) => {
    var newInvestments = [].concat(investments);
    setInvestments(newInvestments.filter(x => x.id != id))

    var newValues = [];
    newValues[`investment_name_${id}`] = ""
    newValues[`monthly_contribution_${id}`] = ""
    newValues[`todays_amount_${id}`] = ""
    newValues[`rate_of_return_${id}`] = ""

    form.setFieldsValue(newValues)
  }

  const addAsset = () => {
    var newAssets = [].concat(otherAssets);
    var newId = 0;
    if (newAssets.length > 0) {
      var lastAsset = maxBy(newAssets, 'id');
      newId = lastAsset.id + 1
    }
    newAssets.push({
      id: newId
    });
    setOtherAssets(newAssets)
  }

  const removeAsset = (id) => {
    var newAssets = [].concat(otherAssets);
    setOtherAssets(newAssets.filter(x => x.id != id))

    var newValues = [];
    newValues[`asset_name_${id}`] = ""
    newValues[`asset_amount_${id}`] = ""

    form.setFieldsValue(newValues)
  }

  const onSubmit = values => {

    // Get birthday and calculate dates
    var birthday = values[`birthday`]
    var age75 = moment(birthday).add(75, 'years');
    var monthsTil75 = moment(age75).diff(moment(), 'months', false)

    // Create investment array
    var investmentData = [];
    investments.forEach(x => {
      investmentData.push({
        id: x.id,
        balance: values[`todays_amount_${x.id}`],
        contribution: values[`monthly_contribution_${x.id}`],
        rate: values[`rate_of_return_${x.id}`]
      })
    })

    // Create asset array
    var assetData = [];
    otherAssets.forEach(x => {
      assetData.push({
        id: x.id,
        amount: values[`asset_amount_${x.id}`]
      })
    })

    var homeValue = values[`home_value`]
    var beginningMortgageBalance = values[`mortgage_balance`]
    var monthlyPayment = values[`monthly_payment`]
    var interestRate = values[`interest_rate`] / 100
    var extraPrincipal = values[`extra_principal`]

    // Loop through each month
    var data = []
    for (let i = 2; i <= monthsTil75; i++) {

      var rowData = {};
      var rowNetWorth = 0;

      // Calculate date for row
      var date = moment().add(i, 'months');
      var formattedDate = date.format("MMM YYYY");
      // Calculate age for row
      var age = moment(date).diff(moment(birthday), 'years', false)

      // Add date and age to row
      rowData = {
        key: i,
        date: formattedDate,
        age: age
      }

      // Loop through investments
      investmentData.forEach(x => {
        var beginningBalance = x.balance;
        var contribution = x.contribution;
        var interest = (beginningBalance + contribution) * (x.rate / 100 / 12)
        var endingBalance = beginningBalance + contribution + interest
        
        // Add investment balance to row
        rowData[`balance_${x.id}`] = endingBalance
        x["balance"] = endingBalance
        rowNetWorth += endingBalance
      });

      // Calcuation home equity
      var interest = beginningMortgageBalance * (interestRate / 12)
      var principal = monthlyPayment - interest
      var endingBalance = beginningMortgageBalance - extraPrincipal - principal
      var equity = homeValue - endingBalance
      var cappedEquity = equity > homeValue ? homeValue : equity;

      if (includeHomeEquity) {
        rowData[`home_equity`] = cappedEquity
        rowNetWorth += cappedEquity - (endingBalance > 0 ? endingBalance : 0)
      }

      beginningMortgageBalance = endingBalance

      assetData.forEach(x => {
        rowNetWorth += x.amount
      })

      rowData[`net_worth`] = rowNetWorth

      // Create row
      data.push(rowData)
    }

    // Create column headers
    var columnHHeaders = getColumnHeaders(values)

    // Set column headers
    setColumns(columnHHeaders);
    setTableData(data)
    summaryRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  const getColumnHeaders = (values) => {
    // Create date and age headers
    var colHeaders = [
      {
        title: 'Date',
        width: 100,
        dataIndex: 'date',
        key: 'date',
      },
      {
        title: 'Age',
        width: 60,
        dataIndex: 'age',
        key: 'age',
      }
    ];

    investments.forEach(x => {
      var name = values[`investment_name_${x.id}`]

      // Add investment column headers
      colHeaders.push({
        title: name,
        width: 150,
        dataIndex: `balance_${x.id}`,
        key: `balance_${x.id}`,
        render: (value) => <NumberFormat value={value} displayType={'text'} thousandSeparator={true} prefix={'$ '} decimalScale={2} fixedDecimalScale={true}/>
      });

    });

    // Add home equity column header
    if (includeHomeEquity) {
      colHeaders.push({
        title: "Home Equity",
        width: 150,
        dataIndex: `home_equity`,
        key: `home_equity`,
        render: (value) => <NumberFormat value={value} displayType={'text'} thousandSeparator={true} prefix={'$ '} decimalScale={2} fixedDecimalScale={true}/>
      });
    }

    // Add net worth column header
    colHeaders.push({
      title: "Net Worth",
      width: 150,
      dataIndex: `net_worth`,
      key: `net_worth`,
      fixed: 'right',
      render: (value) => <NumberFormat value={value} displayType={'text'} thousandSeparator={true} prefix={'$ '} decimalScale={2} fixedDecimalScale={true}/>
    });

    return colHeaders;
  }

  const renderHomeEquityCheckbox = () => {
    return (
      <div className="mt-20 mb-20">
        <Checkbox
          checked={includeHomeEquity}
          onChange={(e) => setIncludeHomeEquity(e.target.checked)}
        >
          Yes, I have a mortgage
        </Checkbox>
      </div>
    )
  }

  const renderRemoveLink = (id) => {
    if (investments.length > 1) {
      return <div className="remove-link" onClick={() => removeInvestment(id)}>Remove</div>;
    }
    return null;
  }

  const renderInvestments = () => {
    return investments.map((investment, index) => {
      return (
        <div key={index}>
          <div className="investment-counter">Investment #{index + 1}</div>
          <Row gutter={[15,15]} className="investment-box">
            <Col xs={24} md={12}>
              { renderInputField("Name", `investment_name_${investment.id}`, "401k, IRA, etc.", true) }
            </Col>
            <Col xs={24} md={12}>
              { renderCurrencyField("Today's Amount", `todays_amount_${investment.id}`) }
            </Col>
            <Col xs={24} md={12}>
              { renderCurrencyField("Monthly Contribution", `monthly_contribution_${index}`) }
            </Col>
            <Col xs={24} md={12}>
              { renderPercentField("Rate of Return", `rate_of_return_${index}`) }
            </Col>
            <Col xs={24} md={12}>
              { renderRemoveLink(investment.id) }
            </Col>
          </Row>
        </div>
      )
    })
  }

  const renderOtherAssets = () => {
    return otherAssets.map((asset, index) => {
      return (
        <div key={index}>
          <div className="investment-counter">Asset #{index + 1}</div>
          <Row gutter={[15,15]} className="investment-box">
            <Col xs={24} md={12}>
              { renderInputField("Name", `asset_name_${asset.id}`, "Cash, Car, etc.", true) }
            </Col>
            <Col xs={24} md={12}>
              { renderCurrencyField("Amount", `asset_amount_${asset.id}`) }
            </Col>
            <Col xs={24}>
              <div className="remove-link" onClick={() => removeAsset(asset.id)}>Remove</div>
            </Col>
          </Row>
        </div>
      )
    })
  }

  const renderSummary = () => {
    const firstMillionObject = tableData.find(x => x.net_worth > 1000000)
    if (tableData.length > 1 && firstMillionObject !== null) {
      return (
        <SectionContainer className="bg-white" verticalPadding={60}>
          <Row justify="center" id="summary">
            <Col xs={24}>
              <div className="fs-32 fw-800 mt-30 mb-20 text-center">Results</div>
              <div className="text-center fs-18 mb-30">You will reach $1M in net worth at age <b>{firstMillionObject.age}</b>.</div>
            </Col>
          </Row>
          { renderTable() }
        </SectionContainer>
      )
    }
    return null;
  }

  const renderTable = () => {
    if (tableData.length > 1) {
      return (
        <Row justify="center">
          <Col xs={24}>
            <div className="shadow-box mb-30">
              <Table columns={columns} dataSource={tableData} scroll={{ x: 'max-content' }} pagination={false}/>
            </div>
          </Col>
        </Row>
      )
    }
    return null;
  }

  const renderHomeEquitySection = () => {
    if (includeHomeEquity) {
      return (
        <>
          <div className="form-section-desc">Enter the details of your mortgage so that the calculator can automatically add your home equity to your net worth each year.</div>
          <Row gutter={[15,15]} className="investment-box">
            <Col xs={24} md={12}>
              { renderCurrencyField("Home Value", `home_value`) }
            </Col>
            <Col xs={24} md={12}>
              { renderCurrencyField("Mortgage Balance", `mortgage_balance`) }
            </Col>
            <Col xs={24} md={12}>
              { renderCurrencyField("Monthly Payment", `monthly_payment`) }
            </Col>
            <Col xs={24} md={12}>
              { renderCurrencyField("Extra Principal Payment", `extra_principal`) }
            </Col>
            <Col xs={24} md={12}>
              { renderPercentField("Interest Rate", `interest_rate`) }
            </Col>
          </Row>
        </>
      )
    }
    return null;
  }

  return (
    <div>
      <PageHeader title="Net Worth Projector" bottomOffset={90} backTitle="Finances" backPath="/finances"/>

      <SectionContainer className="bg-gray" verticalPadding={0}>
        <FloatingContainer maxWidth={800}>
          <Form form={form} layout="vertical" name="contact" onFinish={onSubmit}>
            <div className="shadow-box p-30 mb-60" style={{ marginTop: -90 }}>
              <div className="fs-32 fw-700 mb-10">Your Age</div>
              { renderFormLabel("Birthday") }
              { renderDateField("", "birthday") }
              <div className="fs-32 fw-700 mb-10">Investments</div>
              <div className="form-section-desc">List all of your investments, including the amount you currently have in each account, your monthly contribution, and your estimated annual rate of return.</div>
              { renderInvestments() }
              <div className="body-link" onClick={addInvestment}>+ Add Investment</div>
              <div className="fs-32 fw-700 mb-20 mt-10">Mortgage</div>
              { renderHomeEquityCheckbox() }
              { renderHomeEquitySection() }
              <div className="fs-32 fw-700 mb-10 mt-30">Assets</div>
              <div className="form-section-desc">List all of your assets other than the investments listed above. This includes cars, cash, etc.</div>
              { renderOtherAssets() }
              <div>
                <div className="body-link" onClick={addAsset}>+ Add Asset</div>
              </div>
              <div className="mt-30 mb-20 text-center">
                <button className="page-button" type="submit">Calculate</button>
              </div>
            </div>
          </Form>
        </FloatingContainer>
      </SectionContainer>
      <div ref={summaryRef}>
        { renderSummary() }
      </div>
    </div>
  );
}

export default NetWorthProjectorPage;
