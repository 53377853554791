
import React from "react";
import { Input, InputNumber, Form, DatePicker, Select, Popconfirm } from 'antd';
import NumberFormat from 'react-number-format';
import { orderBy, isNull, startCase } from 'lodash';

const { Option } = Select;

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  save,
  cancel,
  remove,
  children,
  ...restProps
}) => {

  const renderInput = (name) => {
    if (name == "date") {
      return (
        <DatePicker format="MM/DD/YYYY"/>
      )
    } else if (name == "payment") {
      return (
          <InputNumber
            style={{ width: '100%' }}
            precision={2}
            disabled={record.type === "income"}
            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value.replace(/\$\s?|(,*)/g, '')}/>
      )
    } else if (name == "deposit") {
      return (
          <InputNumber
            style={{ width: '100%' }}
            precision={2}
            disabled={record.type === "expense"}
            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value.replace(/\$\s?|(,*)/g, '')}/>
      )
    } else if (name == "payee") {
      return (
        <Select
          showSearch
          style={{ width: "100%" }}
          placeholder="Select a payee"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          <Option value="Google">Google</Option>
          <Option value="Top Hits USA">Top Hits USA</Option>
          <Option value="Paypal">PayPal</Option>
        </Select>
      )
    } else if (name == "account") {
      return (
        <Select
          showSearch
          style={{ width: "100%" }}
          placeholder="Select an account"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          <Option value="Checking Account">Checking Account</Option>
          <Option value="PayPal">PayPal</Option>
          <Option value="Cash">Cash</Option>
        </Select>
      )
    }
    return null;
  }

  const renderFormItem = (name) => {
    return (
      <Form.Item
          name={name}
          style={{
            margin: 0,
            width: "100%"
          }}
          rules={[
            {
              required: false,
              message: `Required!`,
            },
          ]}
        >
        {renderInput(name)}
      </Form.Item>
    )
  }

  const renderCell = () => {
    if (dataIndex == "date") {
      return (
        <>
          <div className="top-cell-edit">
            { renderFormItem(dataIndex) }
          </div>
          <div className="bottom-cell-edit">
            
          </div>
        </>
      )
    } else if (dataIndex == "type") {
      return (
        <>
          <div className="top-cell-edit">

          </div>
          <div className="bottom-cell-edit">
            { startCase(record.type) }
          </div>
        </>
      )
    } else if (dataIndex == "payee") {
      return (
        <>
          <div className="top-cell-edit">
            { renderFormItem(dataIndex) }
          </div>
          <div className="bottom-cell-edit">
            { renderFormItem("account") }
          </div>
        </>
      )
    } else if (dataIndex == "payment") {
      return (
        <>
          <div className="top-cell-edit">
            { renderFormItem(dataIndex) }
          </div>
          <div className="bottom-cell-edit">
            
          </div>
        </>
      )
    } else if (dataIndex == "deposit") {
      return (
        <>
          <div className="top-cell-edit">
            { renderFormItem(dataIndex) }
          </div>
          <div className="bottom-cell-edit flex-right">
            <Popconfirm title="Are you sure?" onConfirm={() => remove(record.key)}>
              <button className="small-page-button-outline">
                Delete
              </button>
            </Popconfirm>
          </div>
        </>
      )
    } else if (dataIndex == "balance") {
      return (
        <>
          <div className="top-cell-edit flex-right">
            <NumberFormat value={record.balance} displayType={'text'} thousandSeparator={true} prefix={'$ '} decimalScale={2} fixedDecimalScale={true}/>
          </div>
          <div className="bottom-cell-edit flex-right">
            <button onClick={() => cancel()} className="small-page-button-outline mr-10">
              Cancel
            </button>
            <button onClick={() => save(record.key)} className="small-page-button">
              Save
            </button>
          </div>
        </>
      )
    }
  }

  return (
    <td {...restProps}>
      {editing ? (
        renderCell()
      ) : (
        children
      )}
    </td>
  );
};

export default EditableCell;