import React, {useEffect} from "react";
import SectionContainer from '../../../components/sectionContainer'
import FloatingContainer from '../../../components/floatingContainer'
import PageHeader from '../../../components/pageHeader'

const MyTestimonyPage = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
    document.title = "Craig Smith | My Testimony";
  }, [])

  return (
    <div>
      <PageHeader title="My Testimony" backTitle="Faith" backPath="/faith"/>
      <SectionContainer verticalPadding={60} className="bg-white">
        <FloatingContainer maxWidth={800}>
          <div className="fs-18 c-text-gray">
            There has been a common theme throughout my life which involves me trying to seek comfort from the things of this world and then being left anxious and depressed because it doesn’t work. The things that I think will bring happiness never truly satisfy and always leaves me searching for the next high.  As a kid, I came to understand that having a relationship with God brings peace, joy, and contentment in a way that nothing else on earth can provide. Even though I had this foundation, the human nature inside of me always tries to find contentment in other things. It’s been a life-long journey to fully understand that God’s way is better.
            <br/><br/>
            Growing up, my parents were very active in church. In fact, my dad was a pastor, so every time the doors were open, we were there. One day at church, I heard my dad preach a sermon. I had some questions, so I asked him about it later that night. He sat me down and explained how everyone is a sinner, including me.  There was nothing that could make me good enough to reach God’s standard of perfection.  Because of sin, I was separated from God, but God loved me too much to stay separated.  The Bible says that the price for sin is death, however God sent his Son to pay for my sins by dying on a cross.  This is called grace because I don’t deserve this. All I had to do was accept the free gift of eternal life that he offers everyone. Romans 10:9 says, “If you declare with your mouth that Jesus is Lord and believe in your heart that God raised Him from the dead, you will be saved”.  So that is what I did. I prayed and asked the Lord to be Savior.
            <br/><br/>
            Although I became a Christ follower, I was far from perfect. There were sin patterns in my life that started at a young age and followed me into adulthood. I was going to church, and even serving in the church, but I was struggling with hidden sin behind the scenes. I didn’t have anyone to keep me accountable, so I isolated myself. This started to affect relationships with my friends and family, but most importantly, my relationship with the Lord. I seemed to be getting further away from Him. I finally sought help through a program at my church called Celebrate Recovery. This is a ministry that helps people break free from things that are keeping them in bondage. I was in a group of guys who got to know my heart and encouraged me through some tough times. Through that process, I came to know the true meaning of freedom and the importance of surrounding myself with other believers in Christ. God continued to show me even more things I needed to give over to him, and this led to more peace, joy, and confidence in my life. This, along with other cornerstone moments, has allowed me to look back and see God’s faithfulness every step of the way.
            <br/><br/>
            In 2017, God led me to Watermark Community Church, and it was such a blessing. The emphasis they put on being authentic was so refreshing. I’ve been in small groups before, but the community group I found was totally different. We not only studied the Bible, but we also spent time sharing updates about our lives, keeping each other accountable, and encouraging one another to grow. These men helped me process important decisions in my life, including the decision to marry my wife. Without their encouragement and prayers, I don’t know where I would be today. 
            <br/><br/>
            My wife and I now attend Onward Community Church where we are actively involved in a community group with other married couples. These people know us well and help us navigate life. We meet each week to encourage one another to keep pursuing the Lord.
            <br/><br/>
            Just because I am a Christian doesn’t mean that I’m perfect. I am constantly growing. Recently, God has been showing me so much through being married. I quickly realized how selfish I can be with my time and my comfort. I am called to love my wife sacrificially, which can be hard when I want to focus on myself. I have come to understand Christ’s love even more now that I am responsible for loving my wife in the same way. Every day I’m reminded of the fact that I’m in desperate need of God’s grace. I’m so thankful that He doesn’t give up on me.  Because I have experienced his love, I have a heart to share my story with others so that they might experience freedom from guilt and shame as well.
            <br/><br/>
            If you have any questions or would like to hear more about my story, please don't hesitate to contact me!
          </div>
        </FloatingContainer>
      </SectionContainer>
    </div>
  );
}

export default MyTestimonyPage;
