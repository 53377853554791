import axios from 'axios';

import StorageKeys from '../constants/storageKeys';
const API_URL = process.env.REACT_APP_API_URL;

export function post(url, data) {
  return axios.post(`${API_URL}${url}`, data, getOptions())
}

export function get(url) {
  return axios.get(`${API_URL}${url}`, getOptions())
}

export function put(url, data) {
  return axios
    .put(`${API_URL}${url}`, data, getOptions())
    .then(handleResponse)
    .catch(handleError);
}

export function del(url) {
  return axios
    .delete(`${API_URL}${url}`, getOptions())
    .then(handleResponse)
    .catch(handleError);
}

export function postImage(url, data) {
  return axios.post(`${API_URL}${url}`, data, getImageOptions())
}

function handleResponse(response) {
  return response.data;
}

function handleError(error) {
  console.log(error);
  return error;
}

function getOptions() {
  const auth = JSON.parse(localStorage.getItem(StorageKeys.AUTH))
  if (auth.accessToken) {
    return {
      headers: {
        'Authorization': 'Bearer ' + auth.accessToken
      }
    }
  }
  return {};
}

function getImageOptions() {
  const auth = JSON.parse(localStorage.getItem(StorageKeys.AUTH))
  if (auth.accessToken) {
    return {
      headers: {
        'Authorization': 'Bearer ' + auth.accessToken,
        'Content-Type': 'multipart/form-data'
      }
    }
  }
  return {};
}