import React, { useEffect} from "react";
import SectionContainer from '../../../components/sectionContainer'
import FloatingContainer from '../../../components/floatingContainer'
import PageHeader from '../../../components/pageHeader'

const WhatDoesTheBibleSayPage = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
    document.title = "Craig Smith | Finances";
  }, [])

  const renderItemTitle = (text) => {
    return (
      <div className="fs-24 fw-700 mt-60">{ text }</div>
    )
  }

  const renderItemDescription = (text) => {
    return (
      <div className="fs-18 fw-300 mt-10">{ text }</div>
    )
  }

  const renderVerse = (reference, text) => {
    return (
      <div className="bg-gray pv-15 ph-20 mt-20">
        <div className="fs-18 fw-600 mb-10">{ reference }</div>
        <div className="fs-18 fw-300">“{ text }”</div>
      </div>
    )
  }

  const renderSectionHeader = (text) => {
    return (
      <div className="c-blue fs-32 fw-800 mt-60">{ text }</div>
    )
  }

  return (
    <div>
      <PageHeader title="What does the bible say about money?" backTitle="Finances" backPath="/finances"/>

      <SectionContainer className="bg-white" verticalPadding={0}>
        <FloatingContainer maxWidth={1000} className="pb-60">
          { renderSectionHeader("The Heart") }

          { renderItemTitle("The love of money can lead to evil things") }
          { renderItemDescription("Most people take this verse out of context and think that money is evil. The Bible has lots of examples of how money can be a blessing, but it does say that the love of money can turn into an idol. We have to guard our hearts so that we don't let money cause us to wander from our faith.") }
          { renderVerse("1 Timothy 6:10","For the love of money is a root of all kinds of evil. Some people, eager for money, have wandered from the faith and pierced themselves with many griefs.") }

          { renderItemTitle("Money will never satisfy") }
          { renderItemDescription("Chasing money is an endless game. You will never be satisfied and will always want more. We have to come to the point where God is enough for us. He is the only one who can truly satisfy the longing in our hearts.") }
          { renderVerse("Ecclesiastes 5:10","Whoever loves money never has enough; whoever loves wealth is never satisfied with their income. This too is meaningless.") }

          { renderSectionHeader("Giving") }

          { renderItemTitle("Giving should be the first thing you do") }
          { renderItemDescription("When you receive income, one of the ways you can demonstrate your gratitude and trust in the Lord is by giving away a portion of your money before spending it on any needs and wants for yourself. This sets the tone for how you view the money God has given you. If you wait until everything is paid for to see if you have anything left, this does not display any trust that God will provide for you.") }
          { renderVerse("Proverbs 3:9","Honor the Lord with your wealth, with the firstfruits of all your crops.") }

          { renderItemTitle("We should give cheerfully") }
          { renderItemDescription("Giving should be done from the heart. Since God has been so faithful in giving us everything we need, we should be compelled to share what we have with others.") }
          { renderVerse("2 Corinthians 9:7","Each of you should give what you have decided in your heart to give, not reluctantly or under compulsion, for God loves a cheerful giver.") }
         
          { renderItemTitle("Giving should not be done for recognition") }
          { renderItemDescription("It's easy to want credit for doing something nice for someone. We must resist the urge to publicize our giving because God says our reward is in heaven.") }
          { renderVerse("Matthew 6:1-4","Be careful not to practice your righteousness in front of others to be seen by them. If you do, you will have no reward from your Father in heaven. So when you give to the needy, do not announce it with trumpets, as the hypocrites do in the synagogues and on the streets, to be honored by others. Truly I tell you, they have received their reward in full. But when you give to the needy, do not let your left hand know what your right hand is doing, so that your giving may be in secret. Then your Father, who sees what is done in secret, will reward you.") }

          { renderItemTitle("Love is demonstrated through giving") }
          { renderItemDescription("We can talk about loving people all day long, but taking action and sharing our possessions with those in need is when it becomes real. If we have the ability to help someone in need, we should act upon it. The only limit to this is if meeting the immediate need of the person causes him more harm than good. The loving thing to do in this scenario is to give him what he really needs instead.") }
          { renderVerse("1 John 3:17","If anyone has material possessions and sees a brother or sister in need but has no pity on them, how can the love of God be in that person?") }

          { renderItemTitle("Giving isn't always about money") }
          { renderItemDescription("Peter encountered a lame man who asked for money, but Peter didn't have any to give. He realized that what the man really needed was the healing power of Jesus. Sometimes sharing the love of Christ with someone is the best gift you can give them. Giving can come in many different forms such as giving your time, lending an ear, offering wisdom, or extending forgiveness.") }
          { renderVerse("Acts 3:6","Then Peter said, 'Silver or gold I do not have, but what I do have I give you. In the name of Jesus Christ of Nazareth, walk.'") }
          
          { renderSectionHeader("Debt") }

          { renderItemTitle("Borrowing money from someone changes your relationship with them") }
          { renderItemDescription("Those who lend money have authority over those who borrow from them. It's wise to do everything you can to avoid getting into debt. You will experience more freedom if you aren't financially obligated to pay anyone back.") }
          { renderVerse("Proverbs 22:7","The rich rule over the poor, and the borrower is slave to the lender.") }

          { renderSectionHeader("Wealth") }

          { renderItemTitle("Growing wealth takes patience") }
          { renderItemDescription("A small percentage of people can become wealthy overnight by gaining an inheritance or owning a business that takes off, however this is not the norm. For most people, becoming wealthy takes diligence and patience. God also blesses those who don't take advantage of other people.") }
          { renderVerse("Proverbs 13:11","Dishonest money dwindles away, but whoever gathers money little by little makes it grow.") }

          { renderItemTitle("Wealth often comes to those who work hard") }
          { renderItemDescription("God blesses those who work hard for their money. We are created to work and be rewarded for our diligence.") }
          { renderVerse("Proverbs 10:4","Lazy hands make for poverty, but diligent hands bring wealth.") }
        </FloatingContainer>
      </SectionContainer>
    </div>
  );
}

export default WhatDoesTheBibleSayPage;
