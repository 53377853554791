import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';

const RouteChangeTracker = () => {

    let location = useLocation();

    useEffect(() => {
        if (process.env.NODE_ENV !== "development") {
            ReactGA.set({ page: location.pathname });
            ReactGA.pageview(location.pathname);
        }
      }, [location]);

    return <div></div>;
};

export default RouteChangeTracker;