import React, {useState, useEffect} from "react";
import { Row, Col, Popconfirm, Tag } from 'antd';
import { useNavigate, Link } from "react-router-dom";
import moment from 'moment';
import { sortBy  } from 'lodash';
import SectionContainer from '../../../components/sectionContainer'
import FloatingContainer from '../../../components/floatingContainer'
import ButtonShadowBoxWithPicture from '../../../components/buttonShadowBoxWithPicture'
import { ArrowLeftOutlined } from '@ant-design/icons';

import { getTrips, deleteTrip } from '../../../api'

const TripsPage = () => {

  let navigate = useNavigate();

  const [trips, setTrips] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0)
    fetchTrips()
  }, [])

  const fetchTrips = () => {
    getTrips().then(response => {
      setTrips(response.data);
      setLoading(false)
    }).catch((e) => setLoading(false))
  }

  const removeTrip = (trip_id) => {
    deleteTrip(trip_id).then(response => {
      fetchTrips()
    }).catch((e) => setLoading(false))
  }

  const renderTag = (trip) => {
    if (trip.published) {
      return <Tag color="success">Published</Tag>;
    } else {
      return <Tag color="error">Unpublished</Tag>;
    }
  }

  const renderTrip = (trip) => {
    return (
      <Col xs={24} sm={12} md={8} key={trip.trip_key}>
        <ButtonShadowBoxWithPicture photo={trip.trip_image} title={trip.trip_name} text={moment.utc(trip.start_date).format("MMMM YYYY")} onClick={() => navigate(`/admin/travel/trip/${trip.trip_id}`)}/>
        <div className="text-right">
          { renderTag(trip) }
          <Popconfirm title="Are you sure?" onConfirm={() => removeTrip(trip.trip_id)}>
            <button className="small-page-button mt-10">Remove</button>
          </Popconfirm>
        </div>
      </Col>
    )
  }

  const renderTrips = () => {
    return (
      <Row gutter={[20,20]}>
        { trips.map((trip) => renderTrip(trip)) }
      </Row>
    )
  }

  return (
    <div>
      <SectionContainer className="bg-blue-gradient" verticalPadding={40}>
        <div className="page-header--title">Trips</div>
        <div className="page-header--breadcrumb"><ArrowLeftOutlined/> <Link to="/admin/dashboard">Dashboad</Link></div>
      </SectionContainer>
      <SectionContainer className="bg-gray" verticalPadding={60}>
        <FloatingContainer maxWidth={1000}>
          { renderTrips() }
          <div className="text-center mt-30">
            <button className="page-button" onClick={() => navigate(`/admin/travel/trip/new`)}>New Trip</button>
          </div>
        </FloatingContainer>
      </SectionContainer>
    </div>
  );
}

export default TripsPage;
