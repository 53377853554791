import React from 'react';
import { Row, Col} from 'antd';

const ButtonShadowBoxWithPicture = props => {
  const { photo, title, text } = props;
  const minHeight = props.minHeight ?? 0;

  return (
    <div className="button-shadow-box-picture" onClick={props.onClick} style={{ minHeight: minHeight }}>
      <Row md={{ wrap: false}} align="middle">
        <Col xs={24}>
          <div className="button-shadow-box-picture--image">
            <img src={photo} alt={title}/>
          </div>
        </Col>
        <Col xs={24}>
          <div className="pv-15 ph-20">
            <div className="fs-20 fw-700">
              { title}
            </div>
            <div className="">
              { text }
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
};

export default ButtonShadowBoxWithPicture;
