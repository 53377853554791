import React from 'react';
import { motion } from 'framer-motion';

const SectionHeader = props => {
  return (
    <div className="section-header" style={ props.style ?? null }>
      <motion.div 
        className="section-title"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        variants={{
          visible: { opacity: 1, y: 0, transition: { duration: 1 } },
          hidden: { opacity: 0, y: 20, transition: { duration: 0.2 } },
        }}
      >{ props.title }</motion.div>
      <motion.div 
        className="section-divider"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        variants={{
          visible: { opacity: 1, transition: { duration: 1, delay: 0.5 } },
          hidden: { opacity: 0, transition: { duration: 0.2 } },
        }}
      ></motion.div>
      { props.subtitle && (
        <div className="section-subtitle">{ props.subtitle }</div>
      )}
    </div>
  );
};

export default SectionHeader;
