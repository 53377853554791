import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import { Row, Col} from 'antd';
import SectionContainer from '../../../components/sectionContainer'
import FloatingContainer from '../../../components/floatingContainer'
import PageHeader from '../../../components/pageHeader'

const IncomeTaxCalculatorPage = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
      <SectionContainer className="bg-blue-gradient" verticalPadding={90}>
        <div className="page-header">INCOME TAX CALCULATOR</div>
        <div className="page-breadcrumb"><Link to="/">Home</Link> {'>'} <Link to="/finances">Finances</Link> {'> Income Tax Calculator'}</div>
      </SectionContainer>
      
      <SectionContainer verticalPadding={60}>
        <FloatingContainer maxWidth={800}>
          <div className="text-center fs-18 c-text-gray">Coming soon!</div>
        </FloatingContainer>
      </SectionContainer>
    </div>
  );
}

export default IncomeTaxCalculatorPage;
