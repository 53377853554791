import React, {useState, useEffect} from "react";
import { Collapse, Tag, Select } from 'antd';
import { isEmpty } from 'lodash';
import SectionContainer from '../../../components/sectionContainer'
import PageHeader from '../../../components/pageHeader'
import FloatingContainer from '../../../components/floatingContainer'
import LoadingSpinner from '../../../components/loadingSpinner'
import { getVerses } from '../../../api'

const { Panel } = Collapse;

const MemoryVersesPage = () => {

  const tags = ["CG","ED1","ED2","ED3"]
  const tagData = tags.map(x => {
    return {
      text: x,
      value: x
    }
  })

  const [verses, setVerses] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0)
    refreshPage()
  }, [])

  const refreshPage = () => {
    getVerses().then(response => {
      setVerses(response.data);
      setLoading(false)
    }).catch((e) => setLoading(false))
  }

  const renderTags = (verse) => {
    const verseTags = verse.tags ?? "";
    const tagData = verseTags.split(",");
    return tagData.map((tag, i) => <Tag key={i} color="geekblue">{tag}</Tag>)
  }

  const renderVerse = (verse) => {
    const verseTags = verse.tags ?? "";
    const verseTagsArray = verseTags.split(",");
    var tagSelected = false;
    verseTagsArray.forEach(x => {
      if (selectedTags.includes(x)) {
        tagSelected = true;
      }
    });

    if (selectedTags.length > 0 && !tagSelected) {
      return;
    }

    const endingVerse = verse.ending_verse == null || verse.starting_verse == verse.ending_verse ? "" : `-${verse.ending_verse}`
    return (
      <div className="verse-box mb-20" style={{ minHeight: 0 }} key={verse.memory_verse_id}>
        <div className="verse-box--reference">{verse.book_name} {verse.chapter}:{verse.starting_verse}{endingVerse}</div>
        <div className="verse-box--text">{verse.verse_text}</div>
        {/* <div className="ph-30 mb-20">
          {renderTags(verse)}
        </div> */}
        { renderApplication(verse) }
      </div>
    )
  }

  const renderVerses = () => {
    return verses.map((verse) => renderVerse(verse))
  }

  const renderSelectOptions = (options) => {
    if (options) {
      return options.map((option) => (
        <Select.Option value={option.value} key={option.value}>{option.text}</Select.Option>
      ))
    }
    return null;
  }

  const renderApplication = (verse) => {
    if (!isEmpty(verse.application)) {
      return (
        <div className="verse-box--collapse">
          <Collapse ghost>
            <Panel header="Application" key="1">
              { verse.application }
            </Panel>
          </Collapse>
        </div>
      )
    }
    return null;
  }

  const renderContent = () => {
    if (isLoading) {
      return <LoadingSpinner/>
    }

    return (
      <SectionContainer verticalPadding={60}>
        <FloatingContainer maxWidth={800}>
          <div className="mb-20">
            <Select size="large" mode="tags" style={{ width: '100%' }} placeholder="Filter by Tags" onChange={(x) => setSelectedTags(x)}>
              { renderSelectOptions(tagData) }
            </Select>
          </div>
          { renderVerses() }
        </FloatingContainer>
      </SectionContainer>
    )
  }

  return (
    <div>
      <PageHeader title="Memory Verses" backTitle="Faith" backPath="/faith"/>
      { renderContent() }
    </div>
  );
}

export default MemoryVersesPage;
