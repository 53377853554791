import React, {useState, useEffect, useRef } from "react";
import { Editor } from '@tinymce/tinymce-react';
import { useParams, useNavigate, Link } from "react-router-dom";
import moment from 'moment';
import { isUndefined, isNull, isEmpty } from 'lodash';
import { Row, Col, Form, Upload, Popconfirm, notification } from 'antd';
import { LoadingOutlined, PlusOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { renderDateField, renderInputField, renderFormLabel, renderCheckboxField, renderSearchSelectField, renderTextAreaField } from "../../../components/formFields";
import SectionContainer from '../../../components/sectionContainer'
import { updatePost, getPost, uploadPostPhoto, getPostCategories, deletePost } from '../../../api'

const API_URL = process.env.REACT_APP_API_URL;

const BlogPostEditorPage = () => {

  const editorRef = useRef(null);
  const [form] = Form.useForm();
  let navigate = useNavigate();
  let params = useParams();

  const [post, setPost] = useState({});
  const [categories, setCategories] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [isLoadingImage, setLoadingImage] = useState(false);
  const [postImageUrl, setPostImageUrl] = useState();

  useEffect(() => {
    window.scrollTo(0, 0)
    fetchCategories()
  }, [])

  const fetchCategories = () => {
    getPostCategories().then(response => {
      const categoryData = response.data.map((category) => {
        return {
          value: category.post_category_key,
          text: category.post_category_name
        }
      })
      setCategories(categoryData);
    })
  }

  const fetchPost = () => {
    getPost(params.post_id).then(response => {
      const data = response.data
      if (isEmpty(data)) {
        navigate("/admin/blog/posts")
      } else {
        setPost(data);
        setLoading(false)
        const values = {
          post_key: data.post_key,
          post_category_key: data.post_category_key,
          post_name: data.post_name,
          description: data.description,
          post_date: isNull(data.post_date) ? "" : moment.utc(data.post_date),
          published: data.published ?? false,
          featured: data.featured ?? false
        }
        form.setFieldsValue(values)
        editorRef.current.setContent(data.content)
        setPostImageUrl(data.post_image)
      }
    }).catch((e) => setLoading(false))
  }

  const onSubmit = values => {

    var newValues = {
      ...values,
      post_date: moment(values.post_date).format("YYYY-MM-DD"),
      content: editorRef.current ? editorRef.current.getContent() : null,
      post_image: postImageUrl ?? "",
      published: !isUndefined(values.published) && !isNull(values.published) ? values.published : false,
      featured: !isUndefined(values.featured) && !isNull(values.featured) ? values.featured : false
    }

    updatePost(post.post_id, newValues).then(response => {
      notification.success({ message: "Saved successfully!"})
    }).catch((e) => setLoading(false))
  }

  function contentImageUploadHandler(blobInfo, success, failure, progress) {
    var xhr, formData;
  
    xhr = new XMLHttpRequest();
    xhr.withCredentials = false;
    xhr.open('POST', `${API_URL}/blog/posts/photo?post_id=${params.post_id}`);
  
    xhr.upload.onprogress = function (e) {
      progress(e.loaded / e.total * 100);
    };
  
    xhr.onload = function() {
      var json;
  
      if (xhr.status === 403) {
        failure('HTTP Error: ' + xhr.status, { remove: true });
        return;
      }
  
      if (xhr.status < 200 || xhr.status >= 300) {
        failure('HTTP Error: ' + xhr.status);
        return;
      }
  
      json = JSON.parse(xhr.responseText);
  
      if (!json || typeof json.location != 'string') {
        failure('Invalid JSON: ' + xhr.responseText);
        return;
      }
  
      success(json.location);
    };
  
    xhr.onerror = function () {
      failure('Image upload failed due to a XHR Transport error. Code: ' + xhr.status);
    };
  
    formData = new FormData();
    formData.append('file', blobInfo.blob(), blobInfo.filename());
  
    xhr.send(formData);
  };

  function uploadHandler(data) {
    setLoadingImage(true)

    var formData = new FormData();
    formData.append('file', data.file);

    uploadPostPhoto(params.post_id, formData).then(response => {
      setPostImageUrl(response.data.location)
      setLoadingImage(false)
    }).catch(e => {
      setLoadingImage(false)
    })
  };

  const uploadButton = (
    <div>
      {isLoadingImage ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const renderUploadMainPhoto = () => {
    return (
      <Upload
        name="trip-image"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        customRequest={uploadHandler}
      >
        {postImageUrl ? <img src={postImageUrl} alt="Post Image" style={{ width: '100%' }} /> : uploadButton}
      </Upload>
    )
  }

  return (
    <div>
      <SectionContainer className="bg-blue-gradient" verticalPadding={40}>
        <div className="page-header--title">{ post.post_name ?? "New Post" }</div>
        <div className="page-header--breadcrumb"><ArrowLeftOutlined/> <Link to="/admin/blog/posts">Blog Posts</Link></div>
      </SectionContainer>
      <SectionContainer className="bg-gray" verticalPadding={60}>
        <div className="shadow-box p-30">
          <Form form={form} layout="vertical" name="travel" onFinish={onSubmit}>
            <Row gutter={[30,0]}>
              <Col xs={24} md={12}>
                { renderInputField("Post Name", "post_name", "", true)}
              </Col>
              <Col xs={24} md={12}>
                { renderInputField("Post Key", "post_key", "", true)}
              </Col>
              <Col xs={12}>
                { renderSearchSelectField("Category", "post_category_key", "", categories, true)}
              </Col>
              <Col xs={12}>
                { renderDateField("Post Date", "post_date", true)}
              </Col>
              <Col xs={24}>
                { renderTextAreaField("Description", "description", 4, false)}
              </Col>
              <Col xs={12}>
                { renderFormLabel("Post Photo (1000 x 600px)") }
                { renderUploadMainPhoto() }
              </Col>
              <Col xs={12}>
                { renderCheckboxField("Published", "published")}
                { renderCheckboxField("Featured", "featured")}
              </Col>
            </Row>
            <Editor
              onInit={(evt, editor) => { 
                editorRef.current = editor
                if (params.post_id !== "new") {
                  fetchPost(params.post_id)
                }
              }}
              initialValue=""
              init={{
                height: 800,
                menubar: 'file edit view insert format tools table',
                fontsize_formats: '10px 12px 14px 16px 18px 20px 24px 30px 32px 36px 40px 50px 60px',
                file_picker_types: 'image',
                file_picker_callback: function (cb, value, meta) {
                  var input = document.createElement('input');
                  input.setAttribute('type', 'file');
                  input.setAttribute('accept', 'image/*');
                  input.onchange = function () {
                    var file = this.files[0];
              
                    var reader = new FileReader();
                    reader.onload = function () {
                      var id = 'blobid' + (new Date()).getTime();
                      var blobCache =  editorRef.current.editorUpload.blobCache;
                      var base64 = reader.result.split(',')[1];
                      var blobInfo = blobCache.create(id, file, base64);
                      blobCache.add(blobInfo);
              
                      /* call the callback and populate the Title field with the file name */
                      cb(blobInfo.blobUri(), { title: file.name });
                    };
                    reader.readAsDataURL(file);
                  };
              
                  input.click();
                },
                automatic_uploads: true,
                images_upload_handler: contentImageUploadHandler,
                plugins: [
                  'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons'
                ],
                toolbar: 'formatselect fontselect fontsizeselect | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen preview save print | insertfile image link anchor codesample',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }'
              }}
            />
            <div className="mt-30 text-right">
              <button className="page-button" type="submit">Save</button>
            </div>
          </Form>
        </div>
        <div className="text-right mt-20">
          <button className="small-page-button" onClick={() => navigate(`/blog/${post.post_category_key}/${post.post_key}`)}>Preview</button>
          <Popconfirm title="Are you sure?" onConfirm={() => deletePost(post.post_id).then(() => navigate("/admin/blog/posts"))}>
            <button className="small-page-button ml-10">Remove</button>
          </Popconfirm>
          <button className="small-page-button-outline ml-10" onClick={() => navigate("/admin/blog/posts")}>Close</button>
        </div>
      </SectionContainer>
    </div>
  );
}

export default BlogPostEditorPage;
