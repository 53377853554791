import React, {useState, useEffect} from "react";
import { Row, Col, Input, Form } from 'antd';
import moment from "moment";
import {isNull} from 'lodash';
import {arrayMoveImmutable} from 'array-move';
import {SortableContainer, sortableElement, sortableHandle} from 'react-sortable-hoc';
import { HolderOutlined, MenuOutlined, CodeOutlined, DollarCircleOutlined, FundOutlined, BankOutlined, SafetyOutlined } from '@ant-design/icons';
import { renderTextAreaField, renderDateField, renderPercentField, renderInputField, renderFormLabel } from "../../components/formFields";
import SectionContainer from '../../components/sectionContainer'
import FloatingContainer from '../../components/floatingContainer'

const TimelinePage = () => {

  const [timelines, setTimelines] = useState([]);
  const [editKey, setEditKey] = useState(null);
  const [currentTimelineKey, setCurrentTimelineKey] = useState(null);
  const [startTime, setStartTime] = useState(moment().set('hour', 18).set('minute', 0));
  const [form] = Form.useForm();

  useEffect(() => {
    // window.scrollTo(0, 0)
    document.title = "Craig Smith | Timeline Editor";
  }, [])

  const getUniqueId = () => {
    return (new Date()).getTime().toString(36) + Math.random().toString(36).slice(2);
  }

  const addTimeline = () => {
    var newTimelines = [...timelines];
    var timelineKey = getUniqueId();
    newTimelines.push({
      key: timelineKey,
      name: "New Timeline",
      startTime: "",
      items: []
    })
    setTimelines(newTimelines);
  }

  const addEvent = (timelineKey) => {
    var newTimelines = [...timelines];
    var itemKey = getUniqueId();
    newTimelines.forEach(x => {
      if (x.key === timelineKey) {
        x.items.push({
          key: itemKey,
          minutes: "",
          time: "",
          name: "",
          notes: "",
          music: ""
        })
      }
    })
    setTimelines(newTimelines);
    setEditKey(itemKey)
    form.setFieldsValue({
      minutes: "",
      name: "",
      notes: "",
      music: ""
    });
  }

  const deleteEvent = (timelineKey, itemKey) => {
    var newTimelines = [...timelines];
    newTimelines.forEach(timeline => {
      if (timeline.key === timelineKey) {
        const newItems = [...timeline.items].filter(x => x.key != itemKey);
        timeline.items = updateTimes(newItems);
      }
    })
    setTimelines(newTimelines);
    setEditKey(null)
  }

  const onSortEnd = (oldIndex, newIndex, timelineKey) => {
    if (oldIndex !== newIndex) { 
      setTimelines([...timelines].map(timeline => {
        if (timeline.key === timelineKey) {
          const newItems = arrayMoveImmutable([].concat(timeline.items), oldIndex, newIndex);
          return {
            ...timeline,
            items: updateTimes(newItems)
          }
        } else {
          return timeline;
        }
      }))
    }
  };

  const onSubmit = (values, itemKey, timelineKey) => {
    setTimelines([...timelines].map(timeline => {
      if (timeline.key === timelineKey) {
        const newItems = [].concat(timeline.items).map(item => {
          if (item.key === itemKey) {
            return {
              ...item,
              ...values
            }
          } else {
            return item;
          }
        });

        return {
          ...timeline,
          items: updateTimes(newItems)
        }
      } else {
        return timeline;
      }
    }));
    setEditKey(null)
  }

  const onTimelineNameSubmit = (values, timelineKey) => {
    setTimelines([...timelines].map(timeline => {
      if (timeline.key === timelineKey) {
        return {
          ...timeline,
          name: values.name
        }
      } else {
        return timeline;
      }
    }));
    setEditKey(null)
  }

  const startEditing = (item) => {
    setEditKey(item.key);

    form.setFieldsValue({
      minutes: "",
      name: "",
      notes: "",
      music: "",
      ...item
    });
  }

  const startTimelineNameEditing = (timeline) => {
    setEditKey(timeline.key);
    console.log(timeline)

    form.setFieldsValue({
      name: timeline.name
    });
  }

  const updateTimes = (items, startingTime) => {

    var currentTime = moment().set('hour', 18).set('minute', 0);

    const newItems = [...items].map(item => {
      const newTime = currentTime.format("h:mm A");
      currentTime.add(item.minutes, 'minutes')
      return {
        ...item,
        time: newTime
      }
    });

    return newItems;
  }

  const renderItemRowForm = (item, timelineKey) => {
    return (
      <Form form={form} layout="vertical" name="itemForm" onFinish={(values) => onSubmit(values, item.key, timelineKey)}>
        <div className="bb-gray p-10">
          <Row className="pagebreak mb-10" gutter={[15,15]}>
            <Col flex="100px">
              <div className="fs-14 fw-600">
                { renderInputField("","minutes", "Mins") }
              </div>
            </Col>
            <Col flex="auto">
              { renderInputField("","name", "Event Name") }
            </Col>
            <Col flex="300px">
              { renderInputField("","music", "Music") } 
            </Col>
          </Row>
          <Row className="pagebreak mb-10" gutter={[15,15]}>
            <Col flex="100px">
         
            </Col>
            <Col flex="auto">
              { renderInputField("","notes", "Notes") }
            </Col>
          </Row>
          <Row className="pagebreak" gutter={[15,15]}>
            <Col flex="100px">
         
            </Col>
            <Col flex="auto" className="text-right">  
              <button className="small-page-button-outline" onClick={() => deleteEvent(timelineKey, item.key)}>Remove</button>
              <button className="small-page-button-outline ml-10" onClick={() => setEditKey(null)}>Cancel</button>
              <button className="small-page-button ml-10" type="submit">Save</button>
            </Col>
          </Row>
        </div>
      </Form>
    )
  }

  const renderItemRow = (item, timelineKey) => {
    if (editKey === item.key) {
      return renderItemRowForm(item, timelineKey);
    }
    return (
      <Row className="bb-gray p-10 pagebreak" gutter={[15,15]} onClick={() => startEditing(item)}>
        <Col flex="50px">
          <DragHandle />
        </Col>
        <Col flex="100px">
          <div className="fs-14 fw-600">{item.time}</div>
        </Col>
        <Col flex="auto">
          <div className="fs-14 fw-600">{ item.name }</div>
          <div className="fs-14 c-text-gray ml-10">{item.notes}</div>
        </Col>
        <Col flex="300px">
          <div className="fs-14">{ item.music }</div>
        </Col>
      </Row>
    )
  }

  const renderTimelineName = (timeline) => {
    if (editKey === timeline.key) {
      return (
        <Form form={form} layout="vertical" name="timelineNameForm" onFinish={(values) => onTimelineNameSubmit(values, timeline.key)}>
          <Row className="" gutter={[15,15]}>
            <Col flex="auto">
              <div className="mt-30 mb-10">
                { renderInputField("", "name", "Timeline Name")}
              </div>
            </Col>
            <Col flex="200px">
              <div className="mt-30 mb-10">
                <button className="small-page-button-outline" onClick={() => setEditKey(null)}>Cancel</button>
                <button className="small-page-button ml-10" type="submit">Save</button>
              </div>
            </Col>
          </Row>
        </Form>
      )
    } else {
      return (
        <div className="fs-20 fw-600 mt-30 mb-10" onClick={() => startTimelineNameEditing(timeline)}>{timeline.name}</div>
      )
    }
  }

  const renderTimeline = (timeline, index) => {
    return (
      <div key={index}>
        { renderTimelineName(timeline) }
        <Row className="bb-gray bg-gray bt-gray p-10" gutter={[15,15]}>
          <Col flex="50px">

          </Col>
          <Col flex="100px">
            <div className="fs-16 fw-600">Time</div>
          </Col>
          <Col flex="auto">
            <div className="fs-16 fw-600">Event</div>
          </Col>
          <Col flex="300px">
            <div className="fs-16 fw-600">Music</div>
          </Col>
        </Row>
        <SortableList items={timeline.items} timelineKey={timeline.key} onSortEnd={({ oldIndex, newIndex }) => onSortEnd(oldIndex, newIndex, timeline.key)} helperClass="sortable-row-dragging" useDragHandle/>
        <div className="text-right">
          <button className="small-page-button" onClick={() => addEvent(timeline.key)}>Add Event</button>
        </div>
      </div>
    )
  }

  const renderTimelines = () => {
    return timelines.map((x,i) => renderTimeline(x,i))
  }

  const DragHandle = sortableHandle(() => <span className="fs-16 c-text-gray"><MenuOutlined/></span>);

  const SortableItem = sortableElement(({value, timelineKey}) => (
    <li className="schedule-row">
      {renderItemRow(value, timelineKey)}
    </li>
  ));

  const SortableList = SortableContainer(({items, timelineKey}) => {
    return (
      <ul className="sortable-row-container">
        {items.map((value, index) => (
          <SortableItem key={`item-${value.key}`} index={index} value={value} timelineKey={timelineKey} disabled={!isNull(editKey)}/>
        ))}
      </ul>
    );
  });

  return (
    <div className="timeline-editor-page">
      <SectionContainer verticalPadding={30}>
        <FloatingContainer maxWidth={800}>
          <div className="fs-24 fw-600 text-center">Simmons + Smith Wedding</div>
          <div className="fs-14 text-center c-text-gray">Friday, May 3, 2019</div>
          <Row className="mt-30" gutter={[15,15]}>
            <Col sm={12}>
              <div className="fs-20 fw-600 mb-10">Venue</div>
              <div className="fs-16">The Milestone Mansion</div>
              <div className="fs-16">123 Main St.</div>
              <div className="fs-16">Aubrey, TX</div>
            </Col>
            <Col sm={12}>
              <div className="fs-20 fw-600 mb-10">Services Provided</div>
              <div className="fs-16">DJ / MC Services</div>
              <div className="fs-16">Dance Floor Lighting</div>
            </Col>
          </Row>
          { renderTimelines() }
          <div className="text-center mt-20">
            <button className="page-button mt-30" onClick={addTimeline}>Add Timeline</button>
          </div>
        </FloatingContainer>
      </SectionContainer>
    </div>
  );
}

export default TimelinePage;
