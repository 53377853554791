import React from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { Menu } from 'antd';
import { isFunction, startsWith } from 'lodash';
import { CloseOutlined } from '@ant-design/icons';

const MobileNavMenu = (props) => {
  const { onClose } = props;

  let location = useLocation();
  let navigate = useNavigate();

  const onMenuClick = (key) => {
    isFunction(onClose) && onClose();
    navigate(key);
  }

  const isSelected = (path) => {
    if (location.pathname != "/" && path == "/") {
      return "";
    }
    return startsWith(location.pathname, path) ? "item-selected" : "";
  }

  const isSubMenuSelected = (path) => {
    if (location.pathname != "/" && path == "/") {
      return "";
    }
    return startsWith(location.pathname, path) ? "item-selected" : "";
  }

  return (
    <div className="site-nav-mobile">
      <div className="site-nav-mobile--close" onClick={onClose}>
        <CloseOutlined/>
      </div>
      <Menu mode={'inline'} onClick={e => onMenuClick(e.key)}>
        <Menu.Item key={"/"} className={isSubMenuSelected("/")} style={{paddingLeft: 16}}>HOME</Menu.Item>
        <Menu.Item key={"/about"} className={isSubMenuSelected("/about")} style={{paddingLeft: 16}}>ABOUT</Menu.Item>
        <Menu.SubMenu key={"/resources"} title={"PASSIONS"} className={isSubMenuSelected("/resources")}>
          <Menu.Item key={"/finances"} className={isSelected("/finances")} style={{paddingLeft: 16}}>FINANCES</Menu.Item>
          <Menu.Item key={"/travel"} className={isSelected("/travel")} style={{paddingLeft: 16}}>TRAVEL</Menu.Item>
          <Menu.Item key={"/faith"} className={isSelected("/faith")} style={{paddingLeft: 16}}>FAITH</Menu.Item>
          <Menu.Item key={"/woodworking"} className={isSelected("/woodworking")} style={{paddingLeft: 16}}>WOODWORKING</Menu.Item>
        </Menu.SubMenu>
        <Menu.SubMenu key={"/blog"} title={"BLOG"} className={isSubMenuSelected("/blog")}>
          <Menu.Item key={"/blog/travel"} className={isSelected("/blog/travel")} style={{paddingLeft: 16}}>TRAVEL</Menu.Item>
          <Menu.Item key={"/blog/woodworking"} className={isSelected("/blog/woodworking")} style={{paddingLeft: 16}}>WOODWORKING</Menu.Item>
        </Menu.SubMenu>
        {/* <Menu.Item key={"/contact"} className={isSubMenuSelected("/contact")}>CONTACT</Menu.Item> */}
      </Menu>
    </div>
  );
}

export default MobileNavMenu;
