import React, {useContext, useEffect} from "react";
import LoadingSpinner from '../../components/loadingSpinner'
import AppContext from '../../app/context';

const LogoutPage = () => {

  const { _, setAuth } = useContext(AppContext);

  useEffect(() => {
    window.scrollTo(0, 0)
    setAuth({})
  }, [])

  return (
    <div>
      <LoadingSpinner/>
    </div>
  );
}

export default LogoutPage;
