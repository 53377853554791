import React, { useEffect, useState } from "react";
import moment from 'moment';
import NumberFormat from 'react-number-format';
import { Row, Col, InputNumber, DatePicker, Table, Radio } from 'antd';
import SectionContainer from '../../../components/sectionContainer'
import FloatingContainer from '../../../components/floatingContainer'
import PageHeader from '../../../components/pageHeader'
import FadeDownWhileInView from '../../../components/fadeDownWhileInView'


const InvestmentCalculatorPage = () => {

  const [startingBalance, setStartingBalance] = useState()
  const [monthlyContribution, setMonthlyContribution] = useState()
  const [husbandBirthday, setHusbandBirthday] = useState()
  const [wifeBirthday, setWifeBirthday] = useState(moment("1993-01-07"))
  const [timeBreakdown, setTimeBreakdown] = useState(1)
  const [tableData, setTableData] = useState([])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const widthStyle = (value) => {
    return {
      width: '100%',
      maxWidth: value + 'px'
    }
  }

  const calculateRetirement = () => {

    const husbandAgeSeventy = moment(husbandBirthday).add(70, 'years');
    const monthsFromNow = moment(husbandAgeSeventy).diff(moment(), 'months');

    var data = [];
    var sixPercentBeginningBalance = startingBalance;
    var eightPercentBeginningBalance = startingBalance;
    var tenPercentBeginningBalance = startingBalance;

    for (var i = 1; i < monthsFromNow + 2; i++) {

      const givenMonth = moment().add(i, 'months');

      const husbandAge = moment(givenMonth).diff(husbandBirthday, 'years');
      const wifeAge = moment(givenMonth).diff(wifeBirthday, 'years');

      var sixPercentBalanceWithContribution = sixPercentBeginningBalance + monthlyContribution;
      var sixPercentBalance = sixPercentBalanceWithContribution + (sixPercentBalanceWithContribution * (0.06/12));
      sixPercentBeginningBalance = sixPercentBalance

      var eightPercentBalanceWithContribution = eightPercentBeginningBalance + monthlyContribution;
      var eightPercentBalance = eightPercentBalanceWithContribution + (eightPercentBalanceWithContribution * (0.08/12));
      eightPercentBeginningBalance = eightPercentBalance

      var tenPercentBalanceWithContribution = tenPercentBeginningBalance + monthlyContribution;
      var tenPercentBalance = tenPercentBalanceWithContribution + (tenPercentBalanceWithContribution * (0.10/12));
      tenPercentBeginningBalance = tenPercentBalance

      const todaysMonth = moment().format("M")
      const givenMonthMonth = givenMonth.format("M")

      if (timeBreakdown == 2 || (todaysMonth == givenMonthMonth)) {
        const dataObject = {
          key: i,
          date: givenMonth.format("MMM YYYY"),
          husbandAge: husbandAge,
          wifeAge: wifeAge,
          sixPercentBalance: sixPercentBalance,
          eightPercentBalance: eightPercentBalance,
          tenPercentBalance: tenPercentBalance
        }
        data.push(dataObject)
      }
    }

    setTableData(data)
  }

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      fixed: 'left'
    },
    {
      title: 'Age',
      dataIndex: 'husbandAge',
      key: 'husbandAge',
    },
    {
      title: '6%',
      dataIndex: 'sixPercentBalance',
      key: 'sixPercentBalance',
      render: (number) => <NumberFormat value={number} displayType={'text'} thousandSeparator={true} prefix={'$ '} decimalScale={2} fixedDecimalScale={true}/>
    },
    {
      title: '8%',
      dataIndex: 'eightPercentBalance',
      key: 'eightPercentBalance',
      render: (number) => <NumberFormat value={number} displayType={'text'} thousandSeparator={true} prefix={'$ '} decimalScale={2} fixedDecimalScale={true}/>
    },
    {
      title: '10%',
      dataIndex: 'tenPercentBalance',
      key: 'tenPercentBalance',
      render: (number) => <NumberFormat value={number} displayType={'text'} thousandSeparator={true} prefix={'$ '} decimalScale={2} fixedDecimalScale={true}/>
    }
  ];

  return (
    <div>
      <PageHeader title="Investment Calculator" bottomOffset={90} backTitle="Finances" backPath="/finances"/>

      <SectionContainer className="bg-gray" verticalPadding={0}>
        <div className="page-header--box">
          <FloatingContainer maxWidth={850}>
            <FadeDownWhileInView delay={0.5} className="page-header--box-title">Instructions</FadeDownWhileInView>
            <FadeDownWhileInView delay={1} className="page-header--box-text">Enter a starting balance and monthly contribution for one of your investments, and this calculator will tell you what the potential outcomes will be for a 6%, 8%, and 10% rate of return.</FadeDownWhileInView>
          </FloatingContainer>
        </div>
      </SectionContainer>

      <SectionContainer className="bg-gray" verticalPadding={60}>
        <Row gutter={[16,16]} className="mb-30">
          <Col xs={24} md={8}>
            <div className="shadow-box p-30">
              <div className="form-label">Birthday</div>
              <DatePicker size="large" format="MM/DD/YYYY" onChange={date => setHusbandBirthday(date)} defaultValue={husbandBirthday}/>
              <div className="form-label mt-20">Starting Balance</div>
              <InputNumber 
                size="large"
                style={widthStyle(200)}
                precision={2}
                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                onChange={number => setStartingBalance(number)}
              />
              <div className="form-label mt-20">Monthly Contribution</div>
              <InputNumber 
                size="large"
                style={widthStyle(200)}
                precision={2}
                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                onChange={number => setMonthlyContribution(number)}
              />
              <div className="form-label mt-20">Breakdown</div>
              <Radio.Group onChange={e => setTimeBreakdown(e.target.value)} value={timeBreakdown}>
                <Radio value={1}>Years</Radio>
                <Radio value={2}>Months</Radio>
              </Radio.Group>
              <div className="mt-30">
                <button className="medium-button" onClick={calculateRetirement}>Calculate</button>
              </div>
            </div>
          </Col>
          <Col xs={24} md={16}>
            <div className="shadow-box">
              <Table dataSource={tableData} columns={columns} pagination={false} scroll={{ x: 'max-content' }}/>
            </div>
          </Col>
        </Row>
      </SectionContainer>
    </div>
  );
}

export default InvestmentCalculatorPage;
