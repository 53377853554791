import React, {useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col} from 'antd';
import { LineChartOutlined, CarOutlined, ReadOutlined, BlockOutlined } from '@ant-design/icons';
import homePhoto from '../../images/home.jpg';
import aboutTraveler from '../../images/about/traveler.jpg';
import aboutWoodworker from '../../images/about/woodworker.jpg';
import aboutHusband from '../../images/about/husband.jpg';
import aboutWeddingDJ from '../../images/about/weddingdj.jpg';
import aboutFather from '../../images/about/father.jpg';
import aboutSoftwareEngineer from '../../images/about/softwareengineer.jpg';
import aboutFollowerOfJesus from '../../images/about/follower-of-jesus.jpg';
import hawaiiPhoto from '../../images/hawaii-1.jpg';
import officeDesk from '../../images/woodworking/office-desk.jpeg'
import SectionContainer from '../../components/sectionContainer'
import FloatingContainer from '../../components/floatingContainer'
import HoverCard from '../../components/hoverCard'
import SectionHeader from '../../components/sectionHeader'
import { motion } from 'framer-motion'

const HomePage = () => {

  let navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0)
    document.title = "Craig Smith | Software Engineer";
  }, [])

  const renderAboutMe = (image, title, text, path) => {
    return (
      <Col xs={24} md={12} lg={8}>
        <motion.div 
          className="cursor-default text-center p-20" style={{ height: "100%"}}
          initial="hidden"
          whileInView="visible"
          whileHover="hover"
          viewport={{ once: true }}
          variants={{
            visible: { opacity: 1, y: 0, transition: { duration: 1, delay: 0.1 } },
            hidden: { opacity: 0, y: 50, transition: { duration: 0.2 } },
            hover: { scale: 1.05, transtion: { duration: 0.2 } }
          }}
        >
          <img src={image} style={{ width: 200, borderRadius: '50%' }}/>
          <div className="fs-24 fw-700 mt-30">{ title }</div>
          <div className="fs-16 fw-400 mt-10">{ text }</div>
        </motion.div>
      </Col>
    )
  }

  return (
    <div>
      <SectionContainer className="bg-blue-gradient" verticalPadding={0}>
        <div className="home-hero-container">
          <Row justify="center" className="home-hero" gutter={[{ xs: 0, sm: 0, md: 60, lg: 60, xl: 80 },{ xs: 40, sm: 40, md: 24, lg: 32 }]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} className="home-hero-box">
              <div>
                <motion.div 
                  className="home-above-title"
                  initial="hidden"
                  animate="visible"
                  transition={ { duration: 1 } }
                  variants={{
                    visible: { opacity: 1, x: 0 },
                    hidden: { opacity: 0, x: -20 }
                  }}
                >HELLO, I'M</motion.div>
                <motion.div 
                  className="home-title"
                  initial="hidden"
                  animate="visible"
                  transition={ { duration: 1, delay: 0.5 } }
                  variants={{
                    visible: { opacity: 1, x: 0 },
                    hidden: { opacity: 0, x: -20 }
                  }}
                >CRAIG SMITH</motion.div>
                <motion.div 
                  className="home-subtitle"
                  initial="hidden"
                  animate="visible"
                  transition={ { duration: 1, delay: 1 } }
                  variants={{
                    visible: { opacity: 1 },
                    hidden: { opacity: 0 }
                  }}
                >I’m a software engineer based out of Dallas, TX. I love to travel, talk about finances, and build things in my spare time. I created this website as a way to share content about all of the things I’m passionate about.</motion.div>
                <motion.button 
                  className="page-button mt-30" 
                  onClick={() => navigate("/about")}
                  initial="hidden"
                  animate="visible"
                  whileHover="hover"
                  variants={{
                    visible: { scale: 1, opacity: 1, y: 0, transition: { duration: 1, delay: 1.5 } },
                    hidden: { scale: 1, opacity: 0, y: 20 },
                    hover: { scale: 1.03, transition: { duration: 0.2 } }
                  }}
                >Learn More</motion.button>
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <div className="">
                <motion.img 
                  src={homePhoto} alt="Craig Smith" style={{width: '100%', borderRadius: 8}}
                  initial="hidden"
                  animate="visible"
                  transition={ { duration: 1, delay: 1.5 } }
                  variants={{
                    visible: { opacity: 1, scale: 1 },
                    hidden: { opacity: 0, scale: 0.8 }
                  }}
                />
              </div>
            </Col>
          </Row>
        </div>
      </SectionContainer>

      <SectionContainer className="bg-gray" verticalPadding={70}>
        <SectionHeader title="My Passions" style={{ marginTop: 150}}/>
        <Row gutter={[30,30]}>
          <HoverCard colNum={4} icon={<LineChartOutlined/>} title="Finances" text="Learn about my financial philosophy and try out some calculators that will help you understand your money better." path="/finances"/>
          <HoverCard colNum={4} icon={<ReadOutlined/>} title="Faith" text="Find out more about how God changed my life and what tools I use to grow in my relationship with Him." path="/faith"/>
          <HoverCard colNum={4} icon={<CarOutlined/>} title="Travel" text="Follow our journey as my wife and I share tips about the places we have traveled." path="/travel"/>
          <HoverCard colNum={4} icon={<BlockOutlined/>} title="Woodworking" text="Join me as I explore the world of woodworking and learn how to build things." path="/woodworking"/>
        </Row>
      </SectionContainer>

      <SectionContainer className="bg-white" verticalPadding={70}>
        <SectionHeader title="About Me" />
        <Row gutter={[30,30]} align={"center"}>
          { renderAboutMe(aboutHusband, "Husband", "I met Morgan in 2016, and we got married 3 years later. I thank the Lord every day that I get to share life with my best friend.", "/finances") }
          { renderAboutMe(aboutFather, "Father", "I am the proud father of Emma Grace who was born in March of 2022. My world will never be the same.", "/travel") }
          { renderAboutMe(aboutFollowerOfJesus, "Follower of Jesus", "My identity is found in Jesus Christ, and Him alone. Although I am not perfect, I am thankful that He has saved me from my sins and has given me eternal life.", "/faith") }
          { renderAboutMe(aboutSoftwareEngineer, "Software Engineer", "I am an Architect at Credera, a technology consulting firm that works with clients to solve their toughest problems.", "/woodworking") }
          { renderAboutMe(aboutWeddingDJ, "Wedding DJ", "I started TriStar DJ in 2009 while attending Baylor University. Since then, I have focused my efforts on providing entertainment for weddings.", "/woodworking") }
          { renderAboutMe(aboutWoodworker, "Woodworker", "After moving into our first house, I wanted to build myself a custom desk. This led to me investing in power tools and finding a whole new passion.", "/woodworking") }
        </Row>
      </SectionContainer>

        {/* <SectionContainer className="bg-gray page-section" verticalPadding={70}>
          <FloatingContainer maxWidth={1200}>
            <Row gutter={[30,30]} justify="middle">
              <Col xs={24} md={12}>
                <img src={officeDesk} alt="Craig Smith" className="page-section--image"/>
              </Col>
              <Col xs={24} md={12}>
                <div className="page-section-content">
                  <div className="page-section-content--title">Check out my woodworking projects</div>
                  <div className="fs-18 c-text-gray fw-400">A few years ago, I started collecting power tools and working on projects around the house. Little did I know that woodworking would quickly turned into a passion.</div>
                  <button className="page-button mt-20" onClick={() => navigate("/woodworking")}>View More</button>
                </div>
              </Col>
            </Row>
          </FloatingContainer>
        </SectionContainer>
        <SectionContainer className="bg-white page-section" verticalPadding={70}>
          <FloatingContainer maxWidth={1200}>
            <Row gutter={[30,30]} justify="middle">
              <Col xs={24} md={12}>
                <div className="page-section-content">
                  <div className="page-section-content--title">Explore the world</div>
                  <div className="fs-18 c-text-gray fw-400">Learn about my financial philosophy and try out some calculators that will help you understand your money better.</div>
                  <button className="page-button mt-20" onClick={() => navigate("/woodworking")}>See More</button>
                </div>
              </Col>
              <Col xs={24} md={12}>
                <img src={hawaiiPhoto} alt="Maui, HI" className="page-section--image"/>
              </Col>
            </Row>
          </FloatingContainer>
        </SectionContainer> */}
    </div>
  );
}

export default HomePage;
