import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { CopyBlock, dracula } from "react-code-blocks";
import { Row, Col} from 'antd';
import GitCodeBlocks from '../../codeblocks/git'
import './gitDocumentationPage.style.scss';

const GitDocumentationPage = () => {

  let navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const renderCodeBlock = (text, showLineNumbers = false) => {
    return (
      <div class="mb-30">
        <CopyBlock
          text={text}
          language={"bash"}
          showLineNumbers={showLineNumbers}
          theme={dracula}
          wrapLines
          codeBlock
        />
      </div>
    )
  }

  return (
    <div>
      <Row justify="center" className="gray-row">
        <Col xs={22} md={18} lg={16} xl={6}>
          <div className="page-subtitle">Finances</div>
          <div className="page-title">What does the Bible say about money?</div>
          <div className="page-text">Take a deeper dive into the world of finance through the lense of God's Word.</div>
        </Col>
        <Col xs={22} md={18} lg={16} xl={6}>
          
        </Col>
      </Row>

      <Row justify="center" className="white-row">
        <Col xs={22} md={18} lg={16} xl={6}>
          <div className="page-subtitle">Woodworking</div>
          <div className="page-title">Shop Cabinets</div>
          <div className="page-text">Take a deeper dive into the world of finance through the lense of God's Word.</div>
        </Col>
        <Col xs={22} md={18} lg={16} xl={6}>
          
        </Col>
      </Row>

      <Row justify="center" className="gray-row">
        <Col xs={22} md={18} lg={16} xl={6}>
          <div className="page-subtitle">Travel</div>
          <div className="page-title">Maui, HI</div>
          <div className="page-text">We took our first trip to Hawaii... and it was awesome.</div>
          <button className="page-button mt-30">Read More</button>
        </Col>
        <Col xs={22} md={18} lg={16} xl={6}>
          
        </Col>
      </Row>

      <Row justify="center" className="">
        <Col xs={22} md={18} lg={16} xl={12}>
          <div className="page-card">
            <div className="page-title">Maui, HI</div>
            <div className="page-text">We took our first trip to Hawaii... and it was awesome.</div>
            <button className="page-button mt-30">Read More</button>
          </div>
          <div className="page-card">
            <div className="page-title">Cancun, MX</div>
            <div className="page-text">We took our first trip to Hawaii... and it was awesome.</div>
            <button className="page-button mt-30">Read More</button>
          </div>
        </Col>
      </Row>

      <Row justify="center">
        <Col xs={22} md={18} lg={16} xl={12}>
          <div className="page-header">Git Documentation</div>
          <h2>Commit to an empty repository</h2>
          {renderCodeBlock(GitCodeBlocks.commitToEmptyRepository)}
          <h2>Revert all unstaged files</h2>
          {renderCodeBlock(GitCodeBlocks.revertUnstaged)}
          <h2>Abort a merge</h2>
          {renderCodeBlock(GitCodeBlocks.abortMerge)}
          <h2>Ammend last commit message</h2>
          {renderCodeBlock(GitCodeBlocks.ammendLastCommitMessage)}
        </Col>
      </Row>
    </div>
  );
}

export default GitDocumentationPage;
