import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import { Row, Col, Collapse, Tag, Select, Button } from 'antd';
import { isEmpty, isNull } from 'lodash';
import SectionContainer from '../../../components/sectionContainer'
import FloatingContainer from '../../../components/floatingContainer'
import LoadingSpinner from '../../../components/loadingSpinner'
import { CloseOutlined, AppstoreOutlined, MenuOutlined } from '@ant-design/icons';
import { getVerses } from '../../../api'


const FlashcardPage = () => {

  const [verse, setVerse] = useState("This is the message we have heard from him and declare to you: God is light; in him there is no darkness at all.");
  const [partialVerse, setPartialVerse] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [maxIndex, setMaxIndex] = useState(0);
  // const [selectedTags, setSelectedTags] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0)
    const verseArray = verse.split(" ");
    setMaxIndex(verseArray.length)
    // refreshPage()
  }, [])

  // const refreshPage = () => {
  //   getVerses().then(response => {
  //     setVerses(response.data);
  //     setLoading(false)
  //   }).catch((e) => setLoading(false))
  // }

  const getVerseText = () => {
    const verseArray = verse.split(" ");
    const partialArray = verseArray.slice(0,currentIndex);
    return partialArray.join(' ')
  }

  const incrementIndex = () => {
    if (currentIndex < maxIndex) {
      setCurrentIndex(currentIndex + 1);
    }
  }

  const renderContent = () => {
    if (isLoading) {
      return <LoadingSpinner/>
    }

    return (
      <div>
        <SectionContainer verticalPadding={60}>
          <FloatingContainer maxWidth={400}>
            <div className="fs-18 text-center" style={{height: 200}}>
              {getVerseText()}
            </div>
          </FloatingContainer>
        </SectionContainer>
        <SectionContainer verticalPadding={0}>
          <FloatingContainer maxWidth={400} className="text-center">
            <button className="page-button" onClick={() => setCurrentIndex(0)}>Reset</button>
            <button className="page-button" onClick={incrementIndex}>Next</button>
          </FloatingContainer>
        </SectionContainer>
      </div>
    )
  }

  return (
    <div>
      <SectionContainer className="bg-white" verticalPadding={15} className="bb-gray">
        <Row justify="middle">
          <Col flex="24px"></Col>
          <Col flex="auto" className="text-center">
            <div className="fs-18 fw-700">1 John 1:5</div>
          </Col>
          <Col flex="24px">
            <div className="fs-24 c-text-gray">
              <CloseOutlined/>
            </div>
          </Col>
        </Row>
      </SectionContainer>
      { renderContent() }
    </div>
  );
}

export default FlashcardPage;
